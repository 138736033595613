/* eslint-disable react/prop-types */

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, {useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import JsxParser from 'react-jsx-parser';
import {Link} from 'react-router-dom';

const Body = () => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  const [configurationList, setConfigurationList] = useState([]);
  const [currentProdutoList, setCurrentProdutoList] = useState([]);
  const [currentPdList, setCurrentPdList] = useState([]);
  const [currentMMList, setCurrentMMList] = useState([]);

  useEffect(() => {
    setConfigurationList([
      {
        _id: 1,
        bopen: false,
        question: 'Como se cadastrar na plataforma',
        anwser:
          'Se você chegou até aqui sua loja já está cadastrada! Basta verificar se todos as informações do estabelecimento estão preenchidas corretamente para poder começar a vender através da plataforma Irê it.',
      },
      {
        _id: 2,
        bopen: false,
        question: 'Como configurar o perfil da loja?',
        anwser:
          'Maximi-ze a aba lateral do site e verifique quais abas estão com um icone amarelo de atenção. Após não tiver mais nenhum em amarelo, você poderá a vender pela Irê it',
      },
      {
        _id: 3,
        bopen: false,
        question:
          'Quais informações são necessárias para começar a usar o app?',
        anwser:
          '<p style={{fontWeight: "bold"}}> - Catálogo com pelo menos 1 item disponível </p> <p style={{fontWeight: "bold"}}> - Informações do estabelcimento </p> <p style={{fontWeight: "bold"}}> - Endereço </p> <p style={{fontWeight: "bold"}}> - Tempo de espera </p> <p style={{fontWeight: "bold"}}> - Pelo menos 1 forma de pagamento disponível </p> <p style={{fontWeight: "bold"}}> - Área de atendimento da loja </p> <p style={{fontWeight: "bold"}}> - Horário de funcionamento </p> <p style={{fontWeight: "bold"}}> - Informaões bancárias </p> ',
      },
    ]);
    setCurrentProdutoList([
      {
        _id: 1,
        bopen: false,
        question: 'Como adicionar ou remover produtos do catálogo?',
        anwser:
          '<p style={{fontWeight: "bold"}}> 1° - Na aba lateral vá em catálogo </p> Todo item produto precisa está dentro de uma categoria, caso você não possua. Crie uma clicando em "Adicionar uma categoria" <p style={{fontWeight: "bold"}}> 2° - Clique no botão de expandir a categoria </p> <p style={{fontWeight: "bold"}}> 3° - Clique em adicionar item </p> <p style={{fontWeight: "bold"}}> 4° - Preencha o formulário do item </p> <p style={{fontWeight: "bold"}}> 5° - Disponibilize ele para seus clientes  </p>',
      },
      {
        _id: 2,
        bopen: false,
        question:
          'Posso editar informações de produtos, como preço e descrição?',
        anwser:
          'Na Irê it você pode tudo! Inclusive editar seus produtos. Para poder editar seus itens, faça o seguinte: <p style={{fontWeight: "bold"}}> 1° - Na aba lateral vá em catálogo </p> <p style={{fontWeight: "bold"}}> 2° - Clique no botão de expandir a categoria </p> <p style={{fontWeight: "bold"}}> 3° - Clique em editar no item que você deseja </p> <p style={{fontWeight: "bold"}}> 4° - Preecha o formulário </p> <p style={{fontWeight: "bold"}}> 5° - Salve as alterações </p>',
      },
      {
        _id: 3,
        bopen: false,
        question: 'Como criar itens com desconto?',
        anwser:
          'A Irê it oferece várias maneiras de dar desconto para os clientes do seu estabelecimento. Uma delas é através do item com desconto. Para oferecer o desconto em um item específico, faça o seguinte: <p style={{fontWeight: "bold"}}> 1° - Na aba lateral vá em catálogo </p> <p style={{fontWeight: "bold"}}> 2° - Clique no botão de expandir a categoria </p> <p style={{fontWeight: "bold"}}> 3° - Clique em editar no item que você deseja </p> <p style={{fontWeight: "bold"}}> 4° - Preecha o formulário </p> <p style={{fontWeight: "bold"}}> 5° - Na ultima aba do formulário você verá a aba "Promoção" </p> <p style={{fontWeight: "bold"}}> 5° - Acione a promoção, lembre-se que o valor do item da promoção deve ser menor que o valor atual do item. </p>',
      },
      {
        _id: 4,
        bopen: false,
        question: 'Como criar a promoão de frete grátis?',
        anwser:
          'A Irê it oferece várias maneiras de dar desconto para os clientes do seu estabelecimento. Uma delas é através do frete grátis. Para oferecer o desconto, faça o seguinte: <p style={{fontWeight: "bold"}}> 1° - Na aba lateral vá em promoções </p> <p style={{fontWeight: "bold"}}> 2° - Adicione o valor mínimo do pedido para desbloquear a promoção  </p> <p style={{fontWeight: "bold"}}> 3° - Defina o raio de entrega </p> <p style={{fontWeight: "bold"}}> 4° - Acione a promoção  </p> <p style={{fontWeight: "bold"}}> 5° - Salve a alteração </p> <p style={{fontWeight: "bold"}}> 6° - Pronto! Promoção de frete grátis ativada. </p>',
      },
      {
        _id: 5,
        bopen: false,
        question: 'Como criar campanhas promocionais?',
        anwser:
          'A Irê it oferece várias maneiras de dar desconto para os clientes do seu estabelecimento. Uma delas é através das campanhas promocionais. Para oferecer uma campanha para seus clientes, faça o seguinte: <p style={{fontWeight: "bold"}}> 1° - Na aba lateral vá em promoções </p> <p style={{fontWeight: "bold"}}> 2° - Escolha o tipo de campanha que você gostaria de ativar </p> <p style={{fontWeight: "bold"}}> 3° - Clique em detalhes da campanha </p> <p style={{fontWeight: "bold"}}> 4° - Acione a promoção  </p> <p style={{fontWeight: "bold"}}> 5° - Salve a alteração </p> <p style={{fontWeight: "bold"}}> 6° - Pronto! A campanha está ativada. </p>',
      },
    ]);
    setCurrentPdList([
      {
        _id: 1,
        bopen: false,
        question: 'Como receber e gerenciar pedidos através do app?',
        anwser:
          'A Irê it possui uma maneira muito simples e fácil de geraciar seus pedidos. Para isso: <p style={{fontWeight: "bold"}}> 1° - Na aba lateral vá em gestor </p> <p style={{fontWeight: "bold"}}> 2° - Verifique se o status da sua loja está aberto </p> <p style={{fontWeight: "bold"}}> 3° - Aguarde receber o pedido </p> <p style={{fontWeight: "bold"}}> 4° - Para aceitar o pedido, clique no pedido, veja o detalhe do pedido e na parte inferior, você poderá aceitar o recusar o pedido </p> <p style={{fontWeight: "bold"}}> 5° - Após aceitar, faça o mesmo processo para os próximos passos </p> ',
      },
      {
        _id: 2,
        bopen: false,
        question: 'Quais métodos de pagamento são suportados?',
        anwser:
          'A Irê it possui diversas formas de pagamento para seus clientes. Escolha quais a sua loja irá ofertar: <p style={{fontWeight: "bold"}}> 1° - Na aba lateral vá em formas de pagamento </p> <p style={{fontWeight: "bold"}}> 2° - Clique no icone para expandir a forma de pagamento </p> <p style={{fontWeight: "bold"}}> 3° - Selecione as formas de pagamento que você deseja oferecer </p> <p style={{fontWeight: "bold"}}> 4° - Salve suas alterações </p>',
      },
      {
        _id: 3,
        bopen: false,
        question: 'Como lidar com cancelamentos ou reembolsos de pedidos?',
        anwser:
          'Para garantir que os cancelamentos seja justo. Em primeira instância APENAS A LOJA PODE REALIZAR O CANCELAMENTO DO PEDIDO. Caso o usuário reporte um problema a equipe de suporte da Irê it entra em ação. <p style={{fontWeight: "bold"}}> 1° - Se a loja cancelar o pedido que tenha forma de pagamento via pix, o valor é extornado assim que o pedido é cancelado </p> <p style={{fontWeight: "bold"}}> 2° - Se o pedido possuir pagamento pelo app, assim que cancelado o valor do pedido é extornado </p> <p style={{fontWeight: "bold"}}> 3° - Se o pagamento for presencial a loja precisa apenas cancelar o pedido. </p> <p style={{fontWeight: "bold"}}> 4° - Lembre-se, não é possível fazer a troca do metodo de pagamento após a solicitação do pedido. </p>',
      },
      {
        _id: 4,
        bopen: false,
        question: 'O que é a entrega Irê it?',
        anwser:
          'A entrega Irê it é uma funcionalidade oferecida pela Irê it para seus estabelecimentos parceiros. Como funciona a entrega Irê it <p style={{fontWeight: "bold"}}> 1° - Habilite a entrega Irê it na aba lateral </p> <p style={{fontWeight: "bold"}}> 2° - Na hora que o cliente estiver montando o pedido, ele poderá escolher a forma de entrega Irê it</p> <p style={{fontWeight: "bold"}}> 3° - Assim que você aceitar o pedido, a Irê it começa a buscar automaticamente um entregador para o pedido </p> <p style={{fontWeight: "bold"}}> 4° - Assim que o entregador chegar a sua loja, ele irá informar o código do pedido </p> <p style={{fontWeight: "bold"}}> 5° - Abra o seu gestor de pedidos e você irá ver o código do pedido </p> <p style={{fontWeight: "bold"}}> 6° - Assim que o entregador finalizar a entrega. Você será informado e o pedido será finalizado </p>',
      },
      {
        _id: 5,
        bopen: false,
        question:
          'Como saber se a entrega Irê it está disponível em minha região?',
        anwser:
          'A entrega Irê it é uma funcionalidade oferecida pela Irê it para seus estabelecimentos parceiros. Para saber se ela está disponível em sua região: <p style={{fontWeight: "bold"}}> 1° - Clique em área de atendimento </p> <p style={{fontWeight: "bold"}}> 2° - Selecione Entrega Irê it</p> <p style={{fontWeight: "bold"}}> 3° - Assim que clicar você poderá ver a área entrega mais próxima da sua região. </p> <p style={{fontWeight: "bold"}}> 4° - Caso sua loja esteja dentro da área, você poderá utilizar as entregas Irê it </p> <p style={{fontWeight: "bold"}}> 5° - A Irê it define os valores da entrega Irê it </p> <p style={{fontWeight: "bold"}}> 6° - Caso o preço da sua entrega própria estiver mais barata que a entrega Irê it e o estabelecimento alterar o tipo de entrega, o estabelecimento irá arcar com a diferença da entrega. </p>',
      },
    ]);
    setCurrentMMList([
      {
        _id: 1,
        bopen: false,
        question: 'Como acessar relatórios de vendas e desempenho da loja?',
        anwser:
          'A Irê it possuí uma forma inteligente e fácil de você entender suas vendas e o perfil de seus clientes. Para isso: <p style={{fontWeight: "bold"}}> 1° - Clique em análise de dados na aba lateral </p> <p style={{fontWeight: "bold"}}> 2° - Escolha quais dados gostaria de analisar </p> <p style={{fontWeight: "bold"}}> 3° - Defina o período de busca </p> <p style={{fontWeight: "bold"}}> 4° - Clique em buscar </p> <p style={{fontWeight: "bold"}}> 5° - Análise seus dados </p>',
      },
      {
        _id: 2,
        bopen: false,
        question: 'Verifique seus dados no último mês com o dashboard Irê it',
        anwser:
          'A Irê it possuí uma forma inteligente e fácil de você entender suas vendas e o perfil de seus clientes no último mês. Para isso: <p style={{fontWeight: "bold"}}> 1° - Clique em Ínicio na aba lateral </p> <p style={{fontWeight: "bold"}}> 2° - Analise os dados do mês </p>',
      },
      {
        _id: 3,
        bopen: false,
        question: 'Análise seus pedidos através do seus histórico',
        anwser:
          'A Irê it possuí uma forma inteligente e fácil de você entender suas vendas. Para isso, utilize o histórico da Irê it: <p style={{fontWeight: "bold"}}> 1° - Clique em Histórico na aba lateral </p> <p style={{fontWeight: "bold"}}> 2° - Defina o filtro dos seus pedidos </p> <p style={{fontWeight: "bold"}}> 3° - Analise seus dados</p>',
      },
    ]);
  }, []);

  const handleClick = (i, id, open, list, setList) => {
    let list2 = list.map(item => {
      if (item._id === id) {
        return {...item, bopen: open};
      } else {
        return item;
      }
    });
    setList(list2);
  };

  const Listquestion = ({list, setList}) => {
    if (list.length > 0) {
      let mylist = list.map((item, index) => {
        return (
          <div
            key={item._id}
            style={{
              width: '100%',
              borderRadius: 30,
              marginTop: 25,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                width: '100%',
                backgroundColor: '#ffffff',
              }}
            >
              <ListItem
                style={{
                  backgroundColor: '#f8f8f8',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText
                  style={{
                    width: isVerySmall || isSmall ? '90%' : '70%',
                    textOverflow: 'ellipsis',
                    color: '#393939',
                  }}
                  primaryTypographyProps={{fontWeight: 'bold'}}
                  primary={item.question}
                />
                {list.find(o => o._id === item._id).bopen ? (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandLess />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !list.find(o => o._id === item._id).bopen,
                        list,
                        setList,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </ListItem>
              <Collapse
                style={{background: '#ffffff'}}
                in={list.find(o => o._id === item._id).bopen}
                unmountOnExit
              >
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    padding: 10,
                  }}
                >
                  <JsxParser components={{Link}} jsx={item.anwser} />
                </div>
              </Collapse>
            </Box>
          </div>
        );
      });
      return mylist;
    } else {
      return null;
    }
  };

  return (
    <div style={{overflowX: 'hidden'}}>
      {isVerySmall || isSmall ? (
        <div>
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 20,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Cadastro e configurações
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={configurationList}
                setList={setConfigurationList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Catálogo e promoções
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={currentProdutoList}
                setList={setCurrentProdutoList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Pedidos e Pagamentos
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentPdList} setList={setCurrentPdList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Análise de dados
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentMMList} setList={setCurrentMMList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Ainda precisa de ajuda?
            </p>
            <Divider style={{marginTop: 10}} />
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: 15,
                fontSize: 26,
                color: '#393939',
                marginBottom: 10,
              }}
            >
              Será um prazer te ajudar! A Irê it possui diversos canais para dar
              suporte para cada serviço ofertado.
            </p>
            <Link
              style={{
                color: '#25D366',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 18,
                textAlign: 'center',
              }}
              to="/users"
            >
              Whatapp: (73) 3191-3951
            </Link>
            <Divider style={{marginTop: 20}} />
            <p
              style={{
                width: '90%',
                textAlign: 'left',
                marginTop: 20,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para os estabelecimentos:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                marginBottom: 80,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:lojas@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              lojas@ireit.com.br
            </Button>
          </div>
        </div>
      ) : (
        <div style={{backgroundColor: '#ffffff', width: '100%'}}>
          <div
            style={{
              width: 'auto',
              marginLeft: 50,
              marginRight: 50,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Cadastro e configurações
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={configurationList}
                setList={setConfigurationList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Catálogo e promoções
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion
                list={currentProdutoList}
                setList={setCurrentProdutoList}
              />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Pedidos e Pagamentos
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentPdList} setList={setCurrentPdList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Análise de dados
            </p>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <Listquestion list={currentMMList} setList={setCurrentMMList} />
            </List>
            <p
              style={{
                width: '100%',
                textAlign: 'left',
                marginTop: 30,
                fontWeight: 'bold',
                fontSize: 36,
                marginBottom: 0,
                color: '#ff6600',
              }}
            >
              Ainda precisa de ajuda?
            </p>
            <Divider />
            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 10,
                fontSize: 26,
                color: '#393939',
                marginBottom: 10,
              }}
            >
              Será um prazer te ajudar! A Irê it possui diversos canais para dar
              suporte para cada serviço ofertado.
            </p>
            <Link
              style={{
                color: '#25D366',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 20,
              }}
              to="/users"
            >
              Whatapp: (73) 3191-3951
            </Link>
            <Divider style={{marginTop: 20}} />
            <p
              style={{
                width: '50%',
                textAlign: 'left',
                marginTop: 20,
                fontSize: 20,
                marginBottom: 0,
                color: '#393939',
              }}
            >
              Email para os estabelecimentos:
            </p>
            <Button
              style={{
                height: 30,
                marginTop: 0,
                marginBottom: 80,
                textTransform: 'none',
                fontWeight: '100',
                fontStyle: 'normal',
                fontSize: 16,
                justifyContent: 'flex-start',
              }}
              sx={{
                color: '#ff6600',
                ':hover': {
                  color: '#06448e',
                },
              }}
              onClick={() =>
                window.open(
                  'mailto:lojas@ireit.com.br?subject=Preciso de ajuda&body=Como%20podemos%20te%20ajudar?',
                )
              }
            >
              lojas@ireit.com.br
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Body;
