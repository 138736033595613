/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import BlockIcon from '@material-ui/icons/Block';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AlertRequest from '../../components/AlertRequest';
import Backdrop from '@material-ui/core/Backdrop';
import {makeStyles} from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogAv = ({
  docId,
  bopen,
  handleClose,
  handleNotiStack,
  DATA,
  setDATA,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [censComment, setCensComment] = useState('');
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = useState(false);
  const [toggleBackdrop, setToggleBackdrop] = useState(false);
  const [titleBackdrop, setTitleBackdrop] = useState('');
  const [descriptionBackdrop, setDescriptionBackdrop] = useState('');
  const [codeBackdrop, setCodeBackdrop] = useState(0);
  const classes = useStyles();
  const handleBackDrop = (title, description, code, open) => {
    setToggleBackdrop(open);
    setTitleBackdrop(title);
    setDescriptionBackdrop(description);
    setCodeBackdrop(code);
  };
  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function close() {
    setValue(0);
    handleClose();
  }

  function onSave() {
    setTimeout(() => {
      handleClose();
    }, 100);
  }

  function sendCensura() {
    if (censComment == '') {
      handleNotiStack('Descreva o motivo da censura', 'warning');
    } else {
      setRequest(true);
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/pedidos/request/censura/' +
            DATA._id +
            '?reason=' +
            censComment,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setDATA(data);
                  setTimeout(() => {
                    handleNotiStack(
                      'Solicitação de censura enviada',
                      'success',
                    );
                    setRequest(false);
                  }, 1000);
                })
                .catch(error => {
                  setRequest(false);
                  setDATA([]);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setRequest(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setRequest(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setRequest(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setRequest(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setRequest(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setRequest(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }
  }

  function sendResposta() {
    if (comment == '') {
      handleNotiStack('Descreva o motivo da censura', 'warning');
    } else {
      setLoading(true);
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/pedidos/update/awnser/av/' +
            DATA._id +
            '?rspt=' +
            comment,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setDATA(data);
                  setTimeout(() => {
                    if (data.ntrsobs == '') {
                      handleBackDrop(
                        'Não foi possível salvar sua resposta',
                        'A Irê It utiliza inteligência artificial para analisar as respostas. Por favor, tente novamente mais tarde ou certifique-se de que sua resposta está alinhada com a política de avaliação da plataforma.',
                        400,
                        true,
                      );
                    } else {
                      handleNotiStack(
                        'Resposta postada com sucesso!',
                        'success',
                      );
                    }

                    setLoading(false);
                  }, 500);
                })
                .catch(error => {
                  handleBackDrop(
                    'Não foi possível salvar sua resposta',
                    'A Irê It utiliza inteligência artificial para analisar as respostas. Por favor, tente novamente mais tarde ou certifique-se de que sua resposta está alinhada com a política de avaliação da plataforma.',
                    400,
                    true,
                  );
                  setLoading(false);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoading(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoading(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setLoading(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setLoading(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setLoading(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setLoading(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }
  }

  const handleClickOpen2 = () => {
    setOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setComment(DATA.ntrsobs);
    }, 500);
  }, [DATA]);

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <Backdrop
          className={classes.backdrop}
          open={toggleBackdrop}
          onClick={() => setToggleBackdrop(false)}
        >
          <Card style={{maxWidth: '70%'}}>
            <div style={{paddingLeft: 20, paddingRight: 20}}>
              <div
                style={{
                  background: '#FFDDDD',
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <p style={{color: '#ff0000'}}>Erro: {codeBackdrop}</p>
              </div>
              <div>
                <p style={{fontWeight: 'bold'}}>
                  Erro {codeBackdrop} - {titleBackdrop}
                </p>
              </div>
              <div
                style={{
                  background: '#F3F3F3',
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 1,
                  paddingBottom: 1,
                  marginTop: 0,
                }}
              >
                <p style={{fontSize: 14, marginTop: 5}}>
                  {descriptionBackdrop}
                </p>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                  marginBottom: 10,
                  width: '100%',
                  display: 'flex',
                }}
              >
                <Button style={{background: '#06448e', color: '#FFFFFF'}}>
                  Entendi.
                </Button>
              </div>
            </div>
          </Card>
        </Backdrop>
        <div
          style={{
            backgroundColor: '#FFffff',
            width: isVerySmall ? 'auto' : 300,
            height: 'auto',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        ></div>
        <div
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 10,
            marginRight: 10,
            maxWidth: 300,
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              color: '#595959',
              marginLeft: 10,
              marginRight: 10,
              fontSize: 20,
            }}
          >
            Detalhes da avaliação:
          </p>
          {DATA.sttav == 1 && DATA.censuquest ? (
            <div
              style={{
                display: 'flex',
                background: '#FFDDDD',
                alignItems: 'center',
              }}
            >
              <QueryBuilderIcon
                style={{fontSize: 16, marginLeft: 10, color: '#ff0000'}}
              />
              <p style={{marginLeft: 5, fontWeight: 'bold', color: '#FF0000'}}>
                Censura negada{' '}
              </p>
            </div>
          ) : DATA.censurado || DATA.sttav == 2 ? (
            <div
              style={{
                display: 'flex',
                background: '#F4F4F4',
                alignItems: 'center',
              }}
            >
              <BlockIcon style={{fontSize: 16, marginLeft: 10}} />
              <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                Avaliação censurada{' '}
              </p>
            </div>
          ) : DATA.censuquest ? (
            <div
              style={{
                display: 'flex',
                background: '#ffffcc',
                alignItems: 'center',
              }}
            >
              <QueryBuilderIcon
                style={{fontSize: 16, marginLeft: 10, color: '#ff6600'}}
              />
              <p style={{marginLeft: 5, fontWeight: 'bold', color: '#FF6600'}}>
                Censura em análise{' '}
              </p>
            </div>
          ) : (
            <div>
              <TextField
                variant="filled"
                multiline={true}
                style={{width: '100%', marginTop: 10}}
                inputProps={{
                  style: {fontSize: 14},
                  maxLength: 100,
                }}
                InputLabelProps={{style: {fontSize: 14}}}
                id="nome"
                label="Descreva o motivo da censura..."
                defaultValue={censComment}
                value={censComment}
                onChange={event => {
                  setCensComment(event.target.value);
                }}
              />
              <Button
                size={'small'}
                onClick={() => sendCensura()}
                color="secondary"
              >
                Solicitar censura
              </Button>
            </div>
          )}

          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>N° do pedido: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.npd}
              </p>
            </div>
            <Divider variant="middle" />
          </div>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Data: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.fday}
              </p>
            </div>
            <Divider variant="middle" />
          </div>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Nota: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.nota}
              </p>
            </div>
            <Divider variant="middle" />
          </div>

          {DATA.chcknt === '' ? null : (
            <div>
              <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
                <p>Destaque: </p>
                <p
                  style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}
                >
                  {DATA.chcknt}
                </p>
              </div>
              <Divider variant="middle" />
            </div>
          )}
          {DATA.ntobs === '' ? null : (
            <div>
              <div style={{marginLeft: 10, marginRight: 10}}>
                <p style={{marginBottom: 0}}>Comentário: </p>
                <p
                  style={{
                    marginLeft: 3,
                    fontWeight: 'bold',
                    color: '#393939',
                    marginTop: 0,
                  }}
                >
                  {DATA.ntobs}
                </p>
              </div>
              {DATA.sttav == 0 ||
              (DATA.sttav === 1 &&
                Date.now() - DATA.tmend <= 7 * 24 * 60 * 60 * 1000) ? (
                <div
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginLeft: 0,
                    marginBottom: 10,
                    marginTop: 0,
                    paddingTop: 0,
                  }}
                >
                  <TextField
                    variant="filled"
                    multiline={true}
                    style={{width: '100%', marginTop: 10}}
                    inputProps={{
                      style: {fontSize: 14},
                      maxLength: 100,
                    }}
                    InputLabelProps={{style: {fontSize: 14}}}
                    id="nome"
                    label="Resposta"
                    defaultValue={comment}
                    value={comment}
                    onChange={event => {
                      setComment(event.target.value);
                    }}
                    disabled={DATA.ntrsobs != ''}
                    helperText={
                      DATA.ntrsobs !== '' ? 'A resposta já foi postada' : ''
                    }
                  />
                  <Button
                    size={'small'}
                    onClick={() => sendResposta()}
                    color="primary"
                  >
                    Enviar resposta
                  </Button>
                </div>
              ) : DATA.sttav == 2 ? (
                <div style={{marginRight: 0, marginLeft: 0}}>
                  <Divider variant="middle" />
                  <div style={{marginLeft: 10, marginRight: 10}}>
                    <p>Motivo da revogação: </p>
                    <p
                      style={{
                        marginLeft: 3,
                        fontWeight: 'bold',
                        color: '#393939',
                      }}
                    >
                      {DATA.censrson}
                    </p>
                    <p style={{fontSize: 12}}>
                      O pedido foi revogado. Isso significa que ele não
                      aparecerá nas avaliações da loja, e tambem não será
                      considerado na nota da loja.
                    </p>
                  </div>
                </div>
              ) : (
                <div style={{marginRight: 0, marginLeft: 0}}>
                  <Divider variant="middle" />
                  <div style={{marginLeft: 10, marginRight: 10}}>
                    <p>Resposta: </p>
                    <p
                      style={{
                        marginLeft: 3,
                        fontWeight: 'bold',
                        color: '#393939',
                      }}
                    >
                      {DATA.ntrsobs}
                    </p>
                    <p style={{fontSize: 14}}>
                      O periodo de responder as notificações é de 7 dias. Após a
                      postagem não é possível responder as avaliações. Além
                      disso, caso o pedido seja revogado, também não será
                      possível responder{' '}
                    </p>
                  </div>
                </div>
              )}

              <Divider variant="middle" />
            </div>
          )}
        </div>
        {DATA.sttav == 0 ? (
          <DialogActions style={{position: 'relative', bottom: 0}}>
            {isVerySmall ? (
              <Button size={'small'} onClick={() => close()}>
                Cancelar
              </Button>
            ) : (
              <Button onClick={() => close()}>Cancelar</Button>
            )}
            {isSmall ? (
              <Button size={'small'} onClick={() => onSave()} color="primary">
                Salvar
              </Button>
            ) : (
              <Button onClick={() => onSave()} color="primary">
                Salvar
              </Button>
            )}
          </DialogActions>
        ) : (
          <DialogActions style={{position: 'relative', bottom: 0}}>
            {isSmall ? (
              <Button size={'small'} onClick={() => close()} color="primary">
                Ok
              </Button>
            ) : (
              <Button onClick={() => close()} color="primary">
                Ok
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={handleClose2} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <AlertRequest
        bopen={request}
        handleClose={() => setRequest(false)}
        txt={'Solicitando censura'}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
      <AlertRequest
        bopen={isLoading}
        handleClose={() => setRequest(false)}
        txt={'Enviando resposta...'}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default DialogAv;
