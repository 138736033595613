/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useLayoutEffect} from 'react';
import {Loading} from 'react-loading-dot';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {FaBars} from 'react-icons/fa';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Divider from '@material-ui/core/Divider';
import {format} from '../../configuration/format';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import {useMediaQuery} from 'react-responsive';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CustomizedDialogs from './dialogitem';
import {useDispatch} from 'react-redux';
import * as ActionItem from '../../store/actions/setItem';
import * as ActionCat from '../../store/actions/setCat';
import AddItemDialogs from '../AdicionarItem/dialogitem';
import DialogCat from './dialogcat';
import DialogEditCat from './dialogcatedit';
import DialogReoderCats from './dialogeditcats';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from 'react-redux';
import AlertSending from '../../components/AlertSeding';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function Catalogo({
  handleToggleSidebar,
  handleNotiStack,
  handleBackDrop,
  updateCatalogo,
}) {
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isPause, setPause] = useState(true);
  const dispatch = useDispatch();

  const [openItem, setOpenItem] = React.useState(false);
  const [openAddItem, setOpenAddItem] = React.useState(false);
  const [openAddCat, setOpenAddCat] = React.useState(false);
  const [openEditCat, setOpenEditCat] = React.useState(false);
  const [openReoderCats, setOpenReoderCats] = React.useState(false);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const [isSending, setIsSending] = useState(false);
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const catalogo = useSelector(state => state.setcatalogo.catalogo);
  const [DATA, setDATA] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [isClone, setIsClone] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDATA(catalogo);
      dispatch(ActionItem.setFClear(true, true, true, true));
      setisLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (catalogo.length > 0) {
      catalogo.map((item, index) => {
        currentList.push({id: item._id, bopen: false});
      });
    }
  }, []);

  const handleClick = (i, id, open) => {
    let list2 = currentList.map((item, index) => {
      if (item.id === id) {
        return {...item, bopen: open};
      } else {
        return item;
      }
    });
    setCurrentList(list2);
  };

  const stopCat = (i, isAvaliable) => {
    setIsSending(true);
    let catid = catalogo[i]._id;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/produtos/status/' +
          catid.toString() +
          '?isAv=' +
          isAvaliable,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setIsSending(false);
                if (!isAvaliable) {
                  handleNotiStack('Categoria pausada', 'warning');
                } else {
                  handleNotiStack('Categoria disponível', 'success');
                }
                updateCatalogo();
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  const stopItem = (i, ci, isAvaliable) => {
    setIsSending(true);
    let itemid = catalogo[i].data[ci]._id;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/produtos/itens/status/itens/' +
          itemid.toString() +
          '?isAv=' +
          isAvaliable,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setIsSending(false);
                if (!isAvaliable) {
                  handleNotiStack('Item pausado', 'warning');
                } else {
                  handleNotiStack('Item disponível', 'success');
                }
                updateCatalogo();
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });

    /*
    let list = DATA[i].data.map((item, index) => {
      if (index === ci) {
        return {...item, bavailable: isAvaliable};
      } else {
        return item;
      }
    });

    let updated = DATA.map((item, index) => {
      if (index === i) {
        return {...item, data: list};
      } else {
        return item;
      }
    });

    if (!isAvaliable) {
      handleNotiStack('Item pausado', 'warning');
    } else {
      handleNotiStack('Item disponível', 'success');
    }

    setDATA(updated);
    */
  };

  const Listdata = ({list, ind}) => {
    let data = list.map((item, index) => {
      return (
        <div key={index}>
          <ListItem className={classes}>
            <ListItemText
              disableTypography={true}
              style={{
                fontSize: 14,
                width: '30%',
              }}
              primary={item.nome}
            />
            {item.bpromo ? (
              <>
                <ListItemText
                  disableTypography={true}
                  style={{
                    marginLeft: 10,
                    overflowWrap: 'break-word',
                    fontSize: 12,
                    color: '#39B40D',
                    textAlign: 'left',
                    width: '10%',
                  }}
                  primary={'R$ ' + format(item.vlrpromo)}
                />
              </>
            ) : (
              <ListItemText
                disableTypography={true}
                style={{
                  marginLeft: 10,
                  overflowWrap: 'break-word',
                  fontSize: 12,
                  textAlign: 'left',
                  width: '10%',
                }}
                primary={'R$ ' + format(item.vlr)}
              />
            )}
            {isSmall ? (
              <IconButton
                onClick={() => clonarItem(item)}
                color="primary"
                aria-label="upload picture"
                component="span"
                size={'small'}
                style={{paddingLeft: 15}}
              >
                <FileCopyIcon />
              </IconButton>
            ) : (
              <Button
                style={{marginRight: 10}}
                onClick={() => clonarItem(item)}
                size={'small'}
                variant="outlined"
                color="primary"
              >
                Duplicar
              </Button>
            )}
            {isSmall ? (
              <IconButton
                onClick={() => editItem(item)}
                color="primary"
                aria-label="upload picture"
                component="span"
                size={'small'}
                style={{paddingLeft: 10}}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Button
                style={{marginRight: 10}}
                onClick={() => editItem(item)}
                size={'small'}
                variant="outlined"
                color="primary"
              >
                Editar
              </Button>
            )}
            {isSmall ? (
              item.bavailable ? (
                <IconButton
                  onClick={() => stopItem(ind, index, false)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <StopIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => stopItem(ind, index, true)}
                  color="secondary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <PlayArrowIcon fontSize="small" />
                </IconButton>
              )
            ) : item.bavailable ? (
              <Button
                onClick={() => stopItem(ind, index, false)}
                size={'small'}
                variant="outlined"
                color="primary"
              >
                <StopIcon fontSize="small" />
                Pausar
              </Button>
            ) : (
              <Button
                onClick={() => stopItem(ind, index, true)}
                size={'small'}
                variant="outlined"
                color="secondary"
              >
                <PlayArrowIcon fontSize="small" />
                Pausado
              </Button>
            )}
          </ListItem>
          <Divider variant="middle" />
        </div>
      );
    });

    return data;
  };

  const Listcat = () => {
    if (DATA.length === 0) {
      return <div style={{width: 200, height: 200}}></div>;
    } else {
      let list = DATA.map((item, index) => {
        return (
          <div key={index}>
            <ListItem
              style={{
                backgroundColor: '#f4f4f4',
                marginTop: 5,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ListItemText
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '70%',
                  textOverflow: 'ellipsis',
                }}
                primary={item.title}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {isSmall ? (
                  <IconButton
                    onClick={() => EditarCat(item)}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <Button
                    style={{marginRight: 10}}
                    onClick={() => EditarCat(item)}
                    size={'small'}
                    variant="outlined"
                    color="primary"
                  >
                    Editar
                  </Button>
                )}

                {isSmall ? (
                  item.bavailable ? (
                    <IconButton
                      onClick={() => stopCat(index, false)}
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      size={'small'}
                    >
                      <StopIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => stopCat(index, true)}
                      color="secondary"
                      aria-label="upload picture"
                      component="span"
                      size={'small'}
                    >
                      <PlayArrowIcon fontSize="small" />
                    </IconButton>
                  )
                ) : item.bavailable ? (
                  <Button
                    onClick={() => stopCat(index, false)}
                    size={'small'}
                    variant="outlined"
                    color="primary"
                  >
                    <StopIcon fontSize="small" />
                    Pausar
                  </Button>
                ) : (
                  <Button
                    onClick={() => stopCat(index, true)}
                    size={'small'}
                    variant="outlined"
                    color="secondary"
                  >
                    <PlayArrowIcon fontSize="small" />
                    Pausado
                  </Button>
                )}
                {currentList.find(o => o.id === item._id).bopen ||
                item.data.length == 0 ? (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !currentList.find(o => o.id === item._id).bopen,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandLess />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      handleClick(
                        index,
                        item._id,
                        !currentList.find(o => o.id === item._id).bopen,
                      )
                    }
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </div>
            </ListItem>
            <Collapse
              in={
                currentList.find(o => o.id === item._id).bopen ||
                item.data.length == 0
              }
              unmountOnExit
            >
              <List component="div" disablePadding>
                <Listdata list={item.data} ind={index} />
              </List>
              <Chip
                style={{marginTop: 10, marginBottom: 10}}
                variant="outlined"
                size="small"
                color={'primary'}
                icon={<AddIcon />}
                label={`Adicionar item em  ${item.title}`}
                onClick={() => AddNewItem(item)}
              />
            </Collapse>
          </div>
        );
      });
      return list;
    }
  };

  function onPause(boolean) {
    setPause(boolean);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const ColorButton = withStyles(theme => ({
    root: {
      boxShadow: 2,
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: '#06448e',
      borderColor: '#06448e',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        backgroundColor: '#06448e',
        borderColor: '#06448e',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#06448e',
        borderColor: '#06448e',
      },
    },
  }))(Button);

  useEffect(() => {
    let list = DATA.map((item, index) => {
      return {...item, title: item.title};
    });
    dispatch(ActionCat.setcats(list));
  }, []);

  useEffect(() => {
    setDATA(catalogo);
  }, [catalogo]);

  function editItem(item) {
    dispatch(ActionCat.setcurrentcat(item.idcat, item.cat, []));
    dispatch(
      ActionItem.setitem(
        item._id,
        item.idstore,
        item.nome,
        item.descricao,
        item.cat,
        item.idcat,
        item.pdv,
        item.bqtd,
        item.qtd,
        item.cqtd,
        item.vlr,
        item.bimg,
        item.img,
        item.vlrpromo,
        item.bpromo,
        item.bmonted,
        item.bpeso,
        item.intpeso,
        item.bavailable,
        item.itens,
        item.cpt,
        item.obs,
        item.bodom,
        item.boseg,
        item.boter,
        item.boqua,
        item.boqui,
        item.bosex,
        item.bosab,
        item.index,
      ),
    );
    setTimeout(() => {
      setOpenItem(true);
    }, 500);
  }

  function clonarItem(item) {
    setIsClone(true);
    dispatch(ActionCat.setcurrentcat(item.idcat, item.cat, []));
    dispatch(
      ActionItem.setitem(
        item._id,
        item.idstore,
        item.nome,
        item.descricao,
        item.cat,
        item.idcat,
        item.pdv,
        item.bqtd,
        item.qtd,
        item.cqtd,
        item.vlr,
        item.bimg,
        item.img,
        item.vlrpromo,
        item.bpromo,
        item.bmonted,
        item.bpeso,
        item.intpeso,
        item.bavailable,
        item.itens,
        item.cpt,
        item.obs,
        item.bodom,
        item.boseg,
        item.boter,
        item.boqua,
        item.boqui,
        item.bosex,
        item.bosab,
        item.index,
      ),
    );
    setTimeout(() => {
      setOpenAddItem(true);
    }, 500);
  }
  function AddNewItem(item) {
    setIsClone(false);
    dispatch(ActionCat.setcurrentcat(item._id, item.title, item.data));
    setTimeout(() => {
      setOpenAddItem(true);
    }, 500);
  }

  function AddNewCat() {
    setOpenAddCat(true);
  }

  function EditarCat(item) {
    dispatch(ActionCat.setcurrentcat(item._id, item.title, item.data));
    setTimeout(() => {
      setOpenEditCat(true);
    }, 500);
  }

  return (
    <div style={{backgroundColor: '#FFFFFF', marginLeft: 10, marginRight: 10}}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div>
            {loading ? (
              <Loading size={30} />
            ) : (
              <div style={{paddingBottom: 50}}>
                {isVisible ? (
                  <Fab
                    onClick={() => scrollToTop()}
                    style={{
                      position: 'fixed',
                      bottom: 20,
                      right: 20,
                      zIndex: 2,
                    }}
                    size={'small'}
                    color="primary"
                    aria-label="add"
                  >
                    <ArrowUpwardIcon />
                  </Fab>
                ) : null}
                <div
                  style={{marginTop: 10}}
                  className="btn-toggle"
                  onClick={() => handleToggleSidebar(true)}
                >
                  <FaBars />
                </div>
                <header>
                  <h1
                    style={{marginLeft: 0, marginBottom: 5, color: '#393939'}}
                  >
                    Catálogo
                  </h1>
                  <p style={{marginTop: 0, color: '#797979'}}>
                    Aqui você adiciona e atualiza os produtos que irão aparecer
                    no app
                  </p>
                </header>
                <div
                  style={{
                    flexDirection: isSmall ? 'column' : 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItens: 'center',
                    marginTop: -10,
                  }}
                >
                  <ColorButton
                    onClick={() => AddNewCat()}
                    size={'small'}
                    style={{color: '#ffffff', marginTop: 10, maxWidth: 200}}
                  >
                    Adicionar categoria
                  </ColorButton>
                  <Button
                    onClick={() => setOpenReoderCats(true)}
                    variant="contained"
                    size={'small'}
                    maxWidth={250}
                    style={{
                      background: '#ffffff',
                      maxWidth: 200,
                      marginTop: 10,
                    }}
                  >
                    Reordenar
                  </Button>
                </div>
                <Container
                  style={{
                    marginTop: 30,
                    padding: 0,
                    paddingLeft: -0,
                    paddingRight: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    width: '100%',
                  }}
                >
                  <List component="nav" aria-labelledby="nested-list-subheader">
                    <Listcat />
                  </List>
                </Container>
                <CustomizedDialogs
                  handleBackDrop={handleBackDrop}
                  updateCatalogo={updateCatalogo}
                  handleNotiStack={handleNotiStack}
                  bopen={openItem}
                  handleClose={() => setOpenItem(false)}
                  style={{
                    top: 0,
                    position: 'absolute',
                    zIndex: 3,
                  }}
                />
                <AddItemDialogs
                  bopen={openAddItem}
                  updateCatalogo={updateCatalogo}
                  handleClose={() => setOpenAddItem(false)}
                  handleNotiStack={handleNotiStack}
                  handleBackDrop={handleBackDrop}
                  isClone={isClone}
                  style={{
                    top: 0,
                    position: 'absolute',
                    zIndex: 3,
                  }}
                />
                <DialogCat
                  currentList={currentList}
                  updateCatalogo={updateCatalogo}
                  handleBackDrop={handleBackDrop}
                  handleNotiStack={handleNotiStack}
                  bopen={openAddCat}
                  handleClose={() => setOpenAddCat(false)}
                  style={{
                    top: 0,
                    position: 'absolute',
                    zIndex: 3,
                  }}
                />
                <DialogEditCat
                  handleNotiStack={handleNotiStack}
                  updateCatalogo={updateCatalogo}
                  bopen={openEditCat}
                  handleClose={() => setOpenEditCat(false)}
                  handleBackDrop={handleBackDrop}
                  currentList={currentList}
                  style={{
                    top: 0,
                    position: 'absolute',
                    zIndex: 3,
                  }}
                />
                <DialogReoderCats
                  handleNotiStack={handleNotiStack}
                  updateCatalogo={updateCatalogo}
                  handleBackDrop={handleBackDrop}
                  bopen={openReoderCats}
                  handleClose={() => setOpenReoderCats(false)}
                  style={{
                    top: 0,
                    position: 'absolute',
                    zIndex: 3,
                  }}
                />
              </div>
            )}
          </div>
          <AlertSending
            bopen={isSending}
            handleClose={() => setIsSending(false)}
            handleNotiStack
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 3,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Catalogo;
