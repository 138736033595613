/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import AlertSending from '../../components/AlertSeding';
import {useSelector} from 'react-redux';
import {getAuth} from 'firebase/auth';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogCat = ({
  bopen,
  handleClose,
  handleNotiStack,
  handleBackDrop,
  updateCatalogo,
  currentList,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [open, setOpen] = React.useState(false);
  const [Ctitle, setCTitle] = useState('');
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  function close() {
    setValue(0);
    handleClose();
  }

  const handleClickOpen2 = () => {
    setOpen(true);
  };

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    setIsSending(true);
    if (Ctitle !== '' || Ctitle !== undefined) {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/produtos/insert',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              title: Ctitle,
              idstore: idstore,
              data: [],
            }),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setTimeout(() => {
                    setCTitle('');
                    setValue(0);
                    handleClose();
                    setIsSending(false);
                    handleNotiStack('Categoria criada com sucesso', 'success');
                  }, 1000);
                  currentList.push({id: data._id, bopen: false});
                  updateCatalogo();
                })
                .catch(error => {
                  console.error(error);
                });
            } else if (response.status === 503) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    } else {
      handleNotiStack('Preencha o campo nome', 'error');
    }
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <p1 style={{marginTop: 10, marginLeft: 10, fontWeight: 500}}>
          Adicionar categoria
        </p1>
        <div
          style={{
            backgroundColor: '#FFffff',
            width: isVerySmall ? 'auto' : 300,
            height: 100,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <TextField
            style={{width: '100%', marginTop: 10}}
            inputProps={{
              style: {fontSize: 14},
              maxLength: 70,
            }}
            InputLabelProps={{style: {fontSize: 14}}}
            id="cate"
            label="Categoria"
            variant={'outlined'}
            defaultValue={Ctitle}
            value={Ctitle}
            onChange={event => {
              setCTitle(event.target.value);
            }}
          />
        </div>
        <Divider variant="middle" />
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => close()}>Cancelar</Button>
          )}
          {isSmall ? (
            <Button size={'small'} onClick={() => onSave()} color="primary">
              Adicionar
            </Button>
          ) : (
            <Button onClick={() => onSave()} color="primary">
              Adicionar
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={handleClose2} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogCat;
