/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import {Bar} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import {Box} from '@material-ui/core';
import {format} from '../../configuration/format';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import {useSelector} from 'react-redux';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Button from '@material-ui/core/Button';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// eslint-disable-next-line react/prop-types
const Vendas = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(true);
  const classes = useStyles();
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const [selectedInitialDate, setSelectedInitialDate] = React.useState(
    new Date(),
  );
  const handleInitialDateChange = date => {
    setSelectedInitialDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };

  const olddatatest = {
    lstup: '28/08/2021',
    vds: 10,
    vlrvds: 300,
    pmv: 'Sanduiche 30 cm',
    navd: 20,
    mavd: 4.5,

    tmd: 0.8,
    cld: 0.1,
    atrd: 0.01,
    vdlb: [
      '01/01',
      '02/01',
      '03/01',
      '04/01',
      '05/01',
      '06/01',
      '07/01',
      '08/01',
      '09/01',
      '10/01',
      '11/01',
      '12/01',
      '13/01',
      '14/01',
    ],
    vd: [12, 19, 3, 5, 2, 3, 8, 12, 19, 3, 5, 2, 3, 8],
    dtsm: [
      {
        day: 'Segundas',
        vendas: 30,
        vlrvendas: 250,
        time: 0.4,
        cncl: 0.4,
        atrs: 0.02,
        ntme: 4.5,
      },
      {
        day: 'Terças',
        vendas: 30,
        vlrvendas: 250,
        time: 0.4,
        cncl: 0.4,
        atrs: 0.02,
        ntme: 4.5,
      },
      {
        day: 'Quartas',
        vendas: 30,
        vlrvendas: 250,
        time: 0.4,
        cncl: 0.4,
        atrs: 0.02,
        ntme: 4.5,
      },
      {
        day: 'Quintas',
        vendas: 30,
        vlrvendas: 250,
        time: 0.4,
        cncl: 0.4,
        atrs: 0.02,
        ntme: 4.5,
      },
    ],
    dtfds: [
      {
        day: 'Sextas',
        vendas: 30,
        vlrvendas: 250,
        time: 0.4,
        cncl: 0.4,
        atrs: 0.02,
        ntme: 4.5,
      },
      {
        day: 'Sabados',
        vendas: 30,
        vlrvendas: 250,
        time: 0.4,
        cncl: 0.4,
        atrs: 0.02,
        ntme: 4.5,
      },
      {
        day: 'Domingos',
        vendas: 30,
        vlrvendas: 250,
        time: 0.4,
        cncl: 0.4,
        atrs: 0.02,
        ntme: 4.5,
      },
    ],
  };

  const [DATA, setDATA] = useState({});

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Vendas',
        data: [],
        backgroundColor: ['rgba(8, 64, 142, 1)'],
        borderColor: ['rgba(8, 64, 153, 1)'],
        borderWidth: 2,
      },
    ],
  });

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const [value, setValue] = useState();
  function handlyvalue(value) {
    setValue(value);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setIsSearching(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setData({
      labels: DATA.vdlb,
      datasets: [
        {
          label: 'Vendas',
          data: DATA.vd,
          backgroundColor: ['rgba(8, 64, 142, 1)'],
          borderColor: ['rgba(8, 64, 153, 1)'],
          borderWidth: 2,
        },
      ],
    });
  }, [DATA]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const ListWeek = () => {
    let list = [];
    if (DATA.dtsm != undefined) {
      list = DATA.dtsm.map((item, index) => {
        return (
          <div
            key={{index}}
            style={{
              width: isVerySmall ? '100%' : 210,
              marginLeft: 0,
              marginRight: 10,
              marginTop: 20,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              <p style={{fontWeight: 'bold'}}>{item.day}</p>
              <p style={{}}>Vendas</p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  color: '#393939',
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              >
                {item.vendas}
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  color: '#393939',
                  marginTop: 0,
                  paddingTop: 0,
                }}
              >
                R$ {format(item.vlrvendas)}
              </p>
              <div
                style={{
                  backgroundColor: '#f4f4f4',
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 0,
                  }}
                >
                  <p>Tempo online</p>
                  {item.time == 0 || isNaN(item.time) || DATA.tmd == -1 ? (
                    <p>0%</p>
                  ) : (
                    <p>{(item.time * 100).toFixed(2)}%</p>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Cancelamentos</p>
                  <p>{(item.cncl * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Atrasos</p>
                  <p>{(item.atrs * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Nota média</p>
                  <p>{item.ntme.toFixed(2)}</p>
                </div>
              </div>
            </Box>
          </div>
        );
      });
    }

    return list;
  };

  const ListWeekend = () => {
    let list = [];
    if (DATA.dtfds != undefined) {
      list = DATA.dtfds.map((item, index) => {
        return (
          <div
            key={{index}}
            style={{
              width: isVerySmall ? '100%' : 270,
              marginLeft: 0,
              marginRight: 10,
              marginTop: 20,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              <p style={{fontWeight: 'bold'}}>{item.day}</p>
              <p style={{}}>Vendas</p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  color: '#393939',
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              >
                {item.vendas}
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  color: '#393939',
                  marginTop: 0,
                  paddingTop: 0,
                }}
              >
                R$ {format(item.vlrvendas)}
              </p>
              <div
                style={{
                  backgroundColor: '#f4f4f4',
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 0,
                  }}
                >
                  <p>Tempo online</p>
                  {item.time == 0 || isNaN(item.time) || item.time == -1 ? (
                    <p>0%</p>
                  ) : (
                    <p>{(item.time * 100).toFixed(2)}%</p>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Cancelamentos</p>
                  <p>{(item.cncl * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Atrasos</p>
                  <p>{(item.atrs * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Nota média</p>
                  <p>{item.ntme.toFixed(2)}</p>
                </div>
              </div>
            </Box>
          </div>
        );
      });
    }

    return list;
  };

  function onSearching() {
    if (selectedInitialDate <= selectedEndDate) {
      setIsSearching(true);
      auth.currentUser.getIdToken().then(token => {
        const pedidosrequest = new Request(
          'https://kops.apiireit.com/gateway/pedidos/resume/sales/date?estid=' +
            idstore +
            '&startdate=' +
            convertDate(selectedInitialDate) +
            '&enddate=' +
            convertDate(selectedEndDate),
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(pedidosrequest)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setTimeout(() => {
                    setDATA(data);
                    setTimeout(() => {
                      setIsSearching(false);
                      if (data.vds == 0) {
                        handleNotiStack('Nenhum resultado encontrado');
                      }
                    }, 1000);
                  }, 1000);
                })
                .catch(error => {
                  console.error(error);
                });
            } else if (response.status === 503) {
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              setIsSearching(false);
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
              setIsSearching(false);
            } else if (response.status === 401) {
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
              setIsSearching(false);
            } else if (response.status === 400) {
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              setIsSearching(false);
            }
          })
          .catch(error => {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            setIsSearching(false);
            console.error(error);
          });
      });
    } else {
      handleNotiStack(
        'A data inicial não pode ser maior que a final,',
        'warning',
      );
    }
  }

  function convertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>

          {isVerySmall || isSmall ? (
            <div style={{flexDirection: 'column', paddingLeft: 10}}>
              <h1 style={{marginLeft: 0, marginBottom: 5, color: '#393939'}}>
                Vendas
              </h1>
              <div
                style={{
                  marginTop: 5,
                  width: '90%',
                  marginLeft: 0,
                }}
              >
                <div style={{display: 'flex'}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-dialog1"
                      label="Buscar dados de"
                      value={selectedInitialDate}
                      onChange={handleInitialDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{marginLeft: 0, marginTop: 0}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      style={{marginLeft: 0}}
                      margin="normal"
                      id="date-picker-dialog2"
                      label="até"
                      format="dd/MM/yyyy"
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      minDate={selectedInitialDate}
                      minDateMessage={'Deve ser depois da data inicial'}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => onSearching()}
                  style={{marginLeft: 0, marginTop: 15, marginBottom: 10}}
                  variant="contained"
                  size={'medium'}
                  color="primary"
                >
                  Buscar
                </Button>
              </div>

              {isSearching ? (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: height / 2,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : !isEmpty(DATA) ? (
                <div>
                  <div
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 25,
                          color: '#494949',
                          marginLeft: 0,
                          marginBottom: -10,
                        }}
                      >
                        Desempenho
                      </p>
                      <p
                        style={{
                          fontSize: 14,
                          color: '#494949',
                          marginBottom: -20,
                          marginLeft: 0,
                          borderWidth: 1,
                          borderColor: '#ff6600',
                          borderRadius: 3,
                        }}
                      >
                        Ultima atualização: {DATA.lstup}
                      </p>
                    </div>

                    <Box
                      boxShadow={3}
                      style={{
                        paddingTop: 5,
                        marginTop: 30,
                        marginLeft: 0,
                        marginRight: 10,
                        paddingBottom: 30,
                        alignItems: 'center',
                        borderRadius: 3,
                      }}
                    >
                      <div style={{width: '100%'}}>
                        <Box
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                            marginTop: 10,
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Vendas</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              {DATA.vds}
                            </p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              R$ {format(DATA.vlrvds)}
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Produto mais vendido</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 10,
                                paddingBottom: 0,
                              }}
                            >
                              - {DATA.pmv}
                            </p>
                          </div>
                        </Box>
                      </div>

                      <div style={{width: '100%'}}>
                        <Box
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                            marginTop: 10,
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Média das avaliações</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              {DATA.mavd.toFixed(2)}
                            </p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              {DATA.navd} pedidos
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Tempo online</p>
                            {DATA.tmd == 0 ||
                            isNaN(DATA.tmd) ||
                            DATA.tmd == -1 ? (
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 20,
                                  color: '#393939',
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                0%
                              </p>
                            ) : (
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 20,
                                  color: '#393939',
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                {(DATA.tmd * 100).toFixed(2)}%
                              </p>
                            )}
                            <p
                              style={{
                                width: 150,
                                fontSize: 12,
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              Os melhores restaurantes ficam acima de 75%
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Cancelamentos</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              {(DATA.cld * 100).toFixed(2)}%
                            </p>
                            <p
                              style={{
                                width: 150,
                                fontSize: 12,
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              Os melhores restaurantes ficam abaixo de 1,8%
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Atrasos</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              {(DATA.atrd * 100).toFixed(2)}%
                            </p>
                            <p
                              style={{
                                width: 150,
                                fontSize: 12,
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 10,
                              }}
                            >
                              Os melhores restaurantes ficam abaixo de 10%
                            </p>
                          </div>
                        </Box>
                      </div>
                      <div style={{width: '100%'}}>
                        <p
                          style={{
                            fontSize: 25,
                            color: '#494949',
                            marginBottom: 15,
                            marginLeft: 20,
                          }}
                        >
                          Gráfico de vendas
                        </p>
                      </div>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          boxShadow={3}
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                          }}
                        >
                          <Line
                            height={isVerySmall ? 270 : 110}
                            style={{
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                            data={data}
                            options={options}
                          />
                        </Box>
                      </div>
                    </Box>
                  </div>
                  <div style={{marginLeft: 0, marginRight: 0}}>
                    <div
                      style={{
                        width: '100%',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 25,
                          color: '#494949',
                          marginBottom: -13,
                          marginLeft: 0,
                        }}
                      >
                        Finais de semana
                      </p>
                    </div>
                    <div
                      style={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        marginBottom: 10,
                      }}
                    >
                      <ListWeekend />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 25,
                          color: '#494949',
                          marginBottom: -13,
                          marginLeft: 0,
                        }}
                      >
                        Dias da semana
                      </p>
                    </div>
                    <div
                      style={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        marginBottom: 70,
                      }}
                    >
                      <ListWeek />
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{marginLeft: 10, marginRight: 10}}>
                  <p style={{fontWeight: 'bold'}}>
                    Defina a data e busque seus pedidos.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <h1 style={{marginLeft: 10, marginBottom: 5, color: '#393939'}}>
                Vendas
              </h1>
              <div
                style={{
                  marginTop: 5,
                  width: '90%',
                  marginLeft: 10,
                  display: 'flex',
                }}
              >
                <div style={{display: 'flex'}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-dialog1"
                      label="Buscar dados de"
                      value={selectedInitialDate}
                      onChange={handleInitialDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{marginLeft: 10, marginTop: 0}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      style={{marginLeft: 0}}
                      margin="normal"
                      id="date-picker-dialog2"
                      label="até"
                      format="dd/MM/yyyy"
                      minDate={selectedInitialDate}
                      minDateMessage={'Deve ser depois da data inicial'}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => onSearching()}
                  style={{marginLeft: 10, marginTop: 15, marginBottom: 10}}
                  variant="contained"
                  size={'medium'}
                  color="primary"
                >
                  Buscar
                </Button>
              </div>

              {isSearching ? (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: height,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : !isEmpty(DATA) ? (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 25,
                          color: '#494949',
                          marginBottom: -20,
                          marginLeft: 10,
                        }}
                      >
                        Desempenho
                      </p>
                      <p
                        style={{
                          fontSize: 14,
                          color: '#494949',
                          marginBottom: -17,
                          marginLeft: 7,
                          borderWidth: 1,
                          borderColor: '#ff6600',
                          borderRadius: 3,
                        }}
                      >
                        Ultima atualização: {DATA.lstup}
                      </p>
                    </div>

                    <Box
                      boxShadow={3}
                      style={{
                        width: '98%',
                        marginTop: 30,
                        marginLeft: 10,
                        marginRight: 10,
                        paddingBottom: 30,
                        alignItems: 'center',
                        borderRadius: 3,
                      }}
                    >
                      <div style={{width: '100%'}}>
                        <div style={{width: '100%'}}>
                          <Box
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              borderRadius: 10,
                              marginTop: 10,
                            }}
                            boxShadow={3}
                          >
                            <div
                              style={{
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 5,
                                paddingBottom: 5,
                              }}
                            >
                              <p style={{}}>Vendas</p>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 20,
                                  color: '#393939',
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                {DATA.vds}
                              </p>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  color: '#393939',
                                  marginTop: 0,
                                  paddingTop: 0,
                                }}
                              >
                                R$ {format(DATA.vlrvds)}
                              </p>
                            </div>
                            <Divider variant="middle" />
                            <div
                              style={{
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 5,
                                paddingBottom: 5,
                              }}
                            >
                              <p style={{}}>Produto mais vendido</p>
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 20,
                                  color: '#393939',
                                  marginBottom: 10,
                                  paddingBottom: 0,
                                }}
                              >
                                - {DATA.pmv}
                              </p>
                            </div>
                          </Box>
                        </div>

                        <Box
                          style={{
                            marginTop: 10,
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                            display: 'flex',
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Média das avaliações</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              {DATA.mavd.toFixed(2)}
                            </p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              {DATA.navd} pedidos
                            </p>
                          </div>
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Tempo online</p>
                            {DATA.tmd == 0 ||
                            isNaN(DATA.tmd) ||
                            DATA.tmd == -1 ? (
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 20,
                                  color: '#393939',
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                0%
                              </p>
                            ) : (
                              <p
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 20,
                                  color: '#393939',
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                {(DATA.tmd * 100).toFixed(2)}%
                              </p>
                            )}
                            <p
                              style={{
                                width: 150,
                                fontSize: 12,
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              Os melhores restaurantes ficam acima de 75%
                            </p>
                          </div>
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Cancelamentos</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              {(DATA.cld * 100).toFixed(2)}%
                            </p>
                            <p
                              style={{
                                width: 150,
                                fontSize: 12,
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              Os melhores restaurantes ficam abaixo de 1,8%
                            </p>
                          </div>
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <p style={{}}>Atrasos</p>
                            <p
                              style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: '#393939',
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              {(DATA.atrd * 100).toFixed(2)}%
                            </p>
                            <p
                              style={{
                                width: 150,
                                fontSize: 12,
                                color: '#393939',
                                marginTop: 0,
                                paddingTop: 0,
                              }}
                            >
                              Os melhores restaurantes ficam abaixo de 10%
                            </p>
                          </div>
                        </Box>
                      </div>
                      <div style={{width: '100%'}}>
                        <p
                          style={{
                            fontSize: 25,
                            color: '#494949',
                            marginBottom: 15,
                            marginLeft: 20,
                          }}
                        >
                          Gráfico de vendas
                        </p>
                      </div>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          boxShadow={3}
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                          }}
                        >
                          <Line
                            height={80}
                            style={{
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                            data={data}
                            options={options}
                          />
                        </Box>
                      </div>
                    </Box>
                  </div>
                  <div style={{marginLeft: 20, marginRight: 20}}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 25,
                          color: '#494949',
                          marginBottom: -13,
                          marginLeft: 0,
                        }}
                      >
                        Finais de semana
                      </p>
                    </div>
                    <div
                      style={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        marginBottom: 10,
                      }}
                    >
                      <ListWeekend />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 25,
                          color: '#494949',
                          marginBottom: -13,
                          marginLeft: 0,
                        }}
                      >
                        Dias da semana
                      </p>
                    </div>
                    <div
                      style={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        marginBottom: 30,
                      }}
                    >
                      <ListWeek />
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{marginLeft: 10, marginRight: 10}}>
                  <p style={{fontWeight: 'bold'}}>
                    Defina a data e busque seus pedidos.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Vendas;
