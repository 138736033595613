/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FullWidthTab from './TabsItem';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogContentText from '@material-ui/core/DialogContentText';
import AlertSending from '../../components/AlertSeding';
import {useDispatch, useSelector} from 'react-redux';
import * as ActionItem from '../../store/actions/setItem';
import {getAuth} from 'firebase/auth';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
export default function CustomizedDialogs({
  bopen,
  handleClose,
  handleNotiStack,
  handleBackDrop,
  updateCatalogo,
}) {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width: 367.98px)'});
  const [open, setOpen] = React.useState(false);
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();
  //detalhes
  const [cNome, setCnome] = useState('');
  const [cDesc, setcDesc] = useState('');
  const [cPdv, setcPdv] = useState('');
  const [cat, setCat] = React.useState('');
  const [cvlr, setCvlr] = useState();
  const [ccpt, setCcpt] = useState();
  const [isAvaliable, setAvailable] = useState(false);
  const [files, setFiles] = useState([]);
  const [cbpeso, setCbpeso] = useState(false);
  const [cIntPeso, setCintPeso] = useState(0);
  const [bimgReal, setbimgReal] = useState(false);

  //disponibilidade
  const [bdom, setbdom] = useState(true);
  const [bseg, setbseg] = useState(true);
  const [bter, setbter] = useState(true);
  const [bqua, setbqua] = useState(true);
  const [bqui, setbqui] = useState(true);
  const [bsex, setbsex] = useState(true);
  const [bsab, setbsab] = useState(true);

  //promo
  const [bpromo, setbpromo] = useState(false);
  const [cvlrpromo, setCvlrpromo] = useState();

  const [ITENS, setITENS] = useState([]);
  const iditem = useSelector(state => state.setitem.id);
  const idstore = useSelector(state => state.setitem.idstore);
  const cats = useSelector(state => state.setcatalogo.catalogo);
  const cate = useSelector(state => state.setitem.idcat);
  const index = useSelector(state => state.setitem.index);

  const aratdmii = useSelector(state => state.setrestaurante.aratdmii);
  const aratdm = useSelector(state => state.setrestaurante.aratdm);
  const handleClickOpen2 = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function close() {
    dispatch(ActionItem.setFClear(true, true, true, true));
    setValue(0);
    handleClose();
  }

  function deleteItem() {
    setIsSending(true);
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/produtos/itens/delete/itens/' +
          iditem +
          '?idstore=' +
          idstore,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            deleteImagem();
            handleBackDrop('', '', 200, false);
            setTimeout(() => {
              handleClose2();
              handleClose();
              setIsSending(false);
              handleNotiStack('Item deletado com sucesso', 'success');
            }, 1000);
            updateCatalogo();
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleClose2();
          handleClose();
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function deleteImagem() {
    setIsSending(true);
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/s3/delete' +
          '?userid=' +
          idstore +
          '&svc=store' +
          '&docid=' +
          iditem,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            setTimeout(() => {
              handleClose2();
              handleClose();
              setIsSending(false);
              handleNotiStack('Imagem do item deletada com sucesso', 'success');
            }, 1000);
            updateCatalogo();
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível deletar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível deletar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível deletar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível deletar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível deletar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível deletar as informações', 'error');
          }, 1000);
          handleClose2();
          handleClose();
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function saveImage() {
    setIsSending(true);
    if (files.length !== 0) {
      if (files[0].path !== undefined) {
        var metadata = new FormData();
        metadata.append('file', files[0]);
        auth.currentUser.getIdToken().then(token => {
          const imgrequest = new Request(
            'https://kops.apiireit.com/gateway/s3/upload' +
              '?docid=' +
              iditem +
              '&userid=' +
              idstore +
              '&svc=store&index=0',
            {
              method: 'POST',
              headers: {
                'My-Custom-Header': 'Custom-Header-Value',
                Authorization: `Bearer ${token}`,
              },
              mode: 'cors',
              body: metadata,
            },
          );

          fetch(imgrequest)
            .then(response => {
              if (response.status === 200) {
                handleBackDrop('', '', 200, false);
                setTimeout(() => {
                  setFiles([]);
                }, 1000);
              } else if (response.status === 503) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Serviço indisponível no momento',
                  'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                  503,
                  true,
                );
                throw new Error('Something went wrong on API server!');
              } else if (response.status === 404) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Serviço não encontrado.',
                  'Por favor, entre em contato com o nosso suporte e relate o problema.',
                  404,
                  true,
                );
              } else if (response.status === 401) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Você não possui autorização ou suas credenciais expirarão.',
                  'Por favor, atualize a baixa ou refaça o processo dee login.',
                  401,
                  true,
                );
              } else if (response.status === 400) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Requisição inválida.',
                  'Confira se todos os campos estão preenchidos corretamente.',
                  400,
                  true,
                );
              } else if (response.status === 500) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Nosso servidor está passando por irregularidades.',
                  'Estamos trabalhado para resolver o mais rápido possível.',
                  500,
                  true,
                );
              }
            })
            .catch(error => {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack('Não foi possível salvar a imagem', 'error');
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              console.error(error);
            });
        });
      }
    }
  }

  function onSave() {
    if (cNome == '') {
      handleNotiStack('Adicione o nome do produto', 'warning');
    } else if (cDesc == '') {
      handleNotiStack('Adicione a descrição do produto', 'warning');
    } else if (cvlr == 0) {
      handleNotiStack('Adicione o valor do produto', 'warning');
    } else {
      let newimg = '';
      if (files.length !== 0) {
        newimg = 'https://ireifiles.s3.amazonaws.com/' + idstore + '/store/';
      }
      saveImage();
      setIsSending(true);
      auth.currentUser.getIdToken().then(token => {
        const putrequest = new Request(
          'https://kops.apiireit.com/gateway/produtos/itens/update/itens/' +
            iditem,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              _id: iditem,
              idstore: idstore,
              nome: cNome,
              pdv: cPdv,
              descricao: cDesc,
              cat: cat.title,
              idcat: cat._id,
              bqtd: false,
              qtd: 0,
              cqtd: 0,
              vlr: cvlr,
              img: newimg,
              bimg: bimgReal,
              vlrpromo: cvlrpromo,
              bpromo: bpromo,
              bmonted: ITENS.length > 0,
              bpeso: cbpeso,
              intpeso: cIntPeso,
              bavailable: isAvaliable,
              itens: ITENS,
              bodom: bdom,
              boseg: bseg,
              boter: bter,
              boqua: bqua,
              boqui: bqui,
              bosex: bsex,
              bosab: bsab,
              cpt: ccpt,
              index: index,
              aratdm: aratdm,
              aratdmii: aratdmii,
            }),
          },
        );

        fetch(putrequest)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  console.debug(data);
                  setTimeout(() => {
                    dispatch(ActionItem.setFClear(true, true, true, true));
                    setValue(0);
                    handleClose();
                    setIsSending(false);
                    handleNotiStack('Item atualizado com sucesso', 'success');
                  }, 1000);
                  updateCatalogo();
                })
                .catch(error => {
                  console.error(error);
                });
            } else if (response.status === 503) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      let obj = cats.find(o => o._id === cate);
      setCat(obj);
    }, 1000);
  }, []);

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <FullWidthTab
          value={value}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
          cNome={cNome}
          setCnome={setCnome}
          cDesc={cDesc}
          setcDesc={setcDesc}
          cPdv={cPdv}
          setcPdv={setcPdv}
          cat={cat}
          setCat={setCat}
          cvlr={cvlr}
          setCvlr={setCvlr}
          ccpt={ccpt}
          setCcpt={setCcpt}
          isAvaliable={isAvaliable}
          setAvailable={setAvailable}
          files={files}
          setFiles={setFiles}
          bdom={bdom}
          setbdom={setbdom}
          bseg={bseg}
          setbseg={setbseg}
          bter={bter}
          setbter={setbter}
          bqua={bqua}
          setbqua={setbqua}
          bqui={bqui}
          setbqui={setbqui}
          bsex={bsex}
          setbsex={setbsex}
          bsab={bsab}
          setbsab={setbsab}
          bpromo={bpromo}
          setbpromo={setbpromo}
          cvlrpromo={cvlrpromo}
          setCvlrpromo={setCvlrpromo}
          ITENS={ITENS}
          setITENS={setITENS}
          cbpeso={cbpeso}
          setCbpeso={setCbpeso}
          cIntPeso={cIntPeso}
          setCintPeso={setCintPeso}
          bimgReal={bimgReal}
          setbimgReal={setbimgReal}
        />
        <Divider variant="middle" />

        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <div
              style={{
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={handleClickOpen2}
                aria-label="delete"
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ) : isSmall ? (
            <div
              style={{
                display: 'flex',
              }}
            >
              <Button
                onClick={handleClickOpen2}
                style={{marginTop: 0}}
                size={'small'}
                color="secondary"
                variant="outlined"
              >
                Excluir item
              </Button>
            </div>
          ) : (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                marginBottom: 10,
                left: 15,
              }}
            >
              <Button
                onClick={handleClickOpen2}
                style={{marginTop: 0}}
                size={'small'}
                color="secondary"
                variant="outlined"
              >
                Excluir item
              </Button>
            </div>
          )}
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => close()}>Cancelar</Button>
          )}
          {isSmall ? (
            value === 3 ? (
              <Button size={'small'} onClick={() => onSave()} color="primary">
                Salvar
              </Button>
            ) : (
              <Button
                size={'small'}
                onClick={() => setValue(value + 1)}
                color="primary"
              >
                Próximo
              </Button>
            )
          ) : value === 3 ? (
            <Button onClick={() => onSave()} color="primary">
              Salvar
            </Button>
          ) : (
            <Button onClick={() => setValue(value + 1)} color="primary">
              Próximo
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={() => deleteItem()} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
