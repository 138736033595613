export const dateformatinvert = item => {
  try {
    const date = new Date(item);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

    const formatted = `${day}/${month}/${year} - ${hour}:${minutes}:${seconds}`;

    return formatted;
  } catch (e) {
    console.error(e);
  }
};
