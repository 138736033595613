/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import visa from '../../assets/Visa.svg';
import pix from '../../assets/Pix.svg';
import ame from '../../assets/Ame.svg';
import picpay from '../../assets/Picpay.svg';
import mastercard from '../../assets/Mastercard.svg';
import mercadopago from '../../assets/Mercadopago.svg';
import paypal from '../../assets/Paypal.svg';
import dinheiro from '../../assets/Dinheiro.svg';
import elo from '../../assets/Elo.svg';
import hipercard from '../../assets/Hipercard.svg';
import dinner from '../../assets/Dinner.svg';
import discover from '../../assets/Discover.svg';
import tricard from '../../assets/Tricard.svg';
import banrisul from '../../assets/Banrisul.svg';
import banese from '../../assets/Banese.svg';
import brasilcard from '../../assets/Brasilcard.svg';
import alelo from '../../assets/Alelo.svg';
import sodexo from '../../assets/Sodexo.svg';
import ben from '../../assets/Ben.svg';
import vr from '../../assets/VR.svg';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertSending from '../../components/AlertSeding';
import {useSelector} from 'react-redux';
import * as ActionStore from '../../store/actions/setRestaurante';
import {useDispatch} from 'react-redux';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#06448E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

// eslint-disable-next-line react/prop-types
const Frm = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAppOpen, setAppOpen] = useState(false);
  const [isVirOpen, setVirOpen] = useState(false);
  const [isPreOpen, setPreOpen] = useState(false);
  const [isValeOpen, setValeOpen] = useState(false);
  const docid = useSelector(state => state.setrestaurante.id);
  const estado = useSelector(state => state.setrestaurante);
  const sbapp = useSelector(state => state.setrestaurante.bapp);
  const sbvir = useSelector(state => state.setrestaurante.bvir);
  const sbpre = useSelector(state => state.setrestaurante.bpre);
  const sbvref = useSelector(state => state.setrestaurante.bvref);
  const sbbd = useSelector(state => state.setrestaurante.bd);
  const sbpix = useSelector(state => state.setrestaurante.bpix);
  const sbame = useSelector(state => state.setrestaurante.bame);
  const sbpicpay = useSelector(state => state.setrestaurante.bpicpay);
  const sbmp = useSelector(state => state.setrestaurante.bmp);
  const sbpaypal = useSelector(state => state.setrestaurante.bpaypal);
  const sbvc = useSelector(state => state.setrestaurante.bvc);
  const sbmcc = useSelector(state => state.setrestaurante.bmcc);
  const sbeloc = useSelector(state => state.setrestaurante.beloc);
  const sbhipc = useSelector(state => state.setrestaurante.bhipc);
  const sbdinc = useSelector(state => state.setrestaurante.bdinc);
  const sbalec = useSelector(state => state.setrestaurante.balec);
  const sbsord = useSelector(state => state.setrestaurante.bsodd);
  const sbvrc = useSelector(state => state.setrestaurante.bvrc);
  const sbbenc = useSelector(state => state.setrestaurante.bbenc);
  const sbdisc = useSelector(state => state.setrestaurante.bdisc);
  const sbtric = useSelector(state => state.setrestaurante.btric);
  const sbbnsc = useSelector(state => state.setrestaurante.bbnsc);
  const sbbrsc = useSelector(state => state.setrestaurante.bbrsc);
  const sbbrc = useSelector(state => state.setrestaurante.bbrc);
  const sbvd = useSelector(state => state.setrestaurante.bvd);
  const sbmcd = useSelector(state => state.setrestaurante.bmcd);
  const sbelod = useSelector(state => state.setrestaurante.belod);
  const sbhipd = useSelector(state => state.setrestaurante.bhipd);
  const sbdind = useSelector(state => state.setrestaurante.bdind);
  const sbaled = useSelector(state => state.setrestaurante.baled);
  const sbsodc = useSelector(state => state.setrestaurante.bsodc);
  const sbvrd = useSelector(state => state.setrestaurante.bvrd);
  const sbbend = useSelector(state => state.setrestaurante.bbend);
  const sbdisd = useSelector(state => state.setrestaurante.bdisd);
  const sbtrid = useSelector(state => state.setrestaurante.btrid);
  const sbbnsd = useSelector(state => state.setrestaurante.bbnsd);
  const sbbrsd = useSelector(state => state.setrestaurante.bbrsd);
  const sbbrd = useSelector(state => state.setrestaurante.bbrd);

  const [state, setState] = React.useState({
    bapp: sbapp,
    bvir: sbvir,
    bpre: sbpre,
    bvref: sbvref,
    bd: sbbd,
    bpix: sbpix,
    bame: sbame,
    bpicpay: sbpicpay,
    bmp: sbmp,
    bpaypal: sbpaypal,
    bvc: sbvc,
    bmcc: sbmcc,
    beloc: sbeloc,
    bhipc: sbhipc,
    bdinc: sbdinc,
    balec: sbalec,
    bsord: sbsord,
    bvrc: sbvrc,
    bbenc: sbbenc,
    bdisc: sbdisc,
    btric: sbtric,
    bbnsc: sbbnsc,
    bbrsc: sbbrsc,
    bbrc: sbbrc,
    bvd: sbvd,
    bmcd: sbmcd,
    belod: sbelod,
    bhipd: sbhipd,
    bdind: sbdind,
    baled: sbaled,
    bsodc: sbsodc,
    bvrd: sbvrd,
    bbend: sbbend,
    bdisd: sbdisd,
    btrid: sbtrid,
    bbnsd: sbbnsd,
    bbrsd: sbbrsd,
    bbrd: sbbrd,
  });

  const handleChange = event => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  const [listvir, setListvir] = useState([
    {
      id: 0,
      title: 'Pix',
      name: 'bpix',
      img: pix,
      bcheck: sbpix,
      isAv: true,
    },
    /*
    {
      id: 1,
      title: 'Ame',
      name: 'bame',
      img: ame,
      bcheck: false,
      isAv: true,
    },
    {
      id: 0,
      title: 'PicPay',
      name: 'bpicpay',
      img: picpay,
      bcheck: false,
      isAv: true,
    },
    {
      id: 0,
      title: 'Mercado pago',
      name: 'bmp',
      img: mercadopago,
      bcheck: false,
      isAv: true,
    },
    {
      id: 0,
      title: 'PayPal',
      name: 'bpaypal',
      img: paypal,
      bcheck: false,
      isAv: true,
    },*/
  ]);

  const [listdeb, setListdeb] = useState([
    {
      id: 0,
      title: 'Visa débito',
      name: 'bvd',
      img: visa,
      bcheck: sbvd,
      isAv: true,
    },
    {
      id: 1,
      title: 'MasterCard débito',
      name: 'bmcd',
      img: mastercard,
      bcheck: sbmcd,
      isAv: true,
    },
    {
      id: 1,
      title: 'Elo débito',
      name: 'belod',
      img: elo,
      bcheck: sbelod,
      isAv: true,
    },
    {
      id: 1,
      title: 'Hipercard débito',
      name: 'bhipd',
      img: hipercard,
      bcheck: sbhipd,
      isAv: true,
    },
    {
      id: 1,
      title: 'Dinner débito',
      name: 'bdind',
      img: dinner,
      bcheck: sbdind,
      isAv: true,
    },
    {
      id: 1,
      title: 'Discover débito',
      name: 'bdisd',
      img: discover,
      bcheck: sbdisd,
      isAv: true,
    },
    {
      id: 1,
      title: 'Tricard débito',
      name: 'btrid',
      img: tricard,
      bcheck: sbtrid,
      isAv: true,
    },
    {
      id: 1,
      title: 'Banrisul débito',
      name: 'bbrsd',
      img: banrisul,
      bcheck: sbbrsd,
      isAv: true,
    },
    {
      id: 1,
      title: 'Banese débito',
      name: 'bbnsd',
      img: banese,
      bcheck: sbbnsd,
      isAv: true,
    },
    {
      id: 1,
      title: 'BrasilCard débito',
      name: 'bbrd',
      img: brasilcard,
      bcheck: sbbrd,
      isAv: true,
    },
  ]);

  const [listcred, setListcred] = useState([
    {
      id: 0,
      title: 'Visa crédito',
      name: 'bvc',
      img: visa,
      bcheck: sbvc,
      isAv: true,
    },
    {
      id: 1,
      title: 'MasterCard crédito',
      name: 'bmcc',
      img: mastercard,
      bcheck: sbmcc,
      isAv: true,
    },
    {
      id: 1,
      title: 'Elo crédito',
      name: 'beloc',
      img: elo,
      bcheck: sbeloc,
      isAv: true,
    },
    {
      id: 1,
      title: 'Hipercard crédito',
      name: 'bhipc',
      img: hipercard,
      bcheck: sbhipc,
      isAv: true,
    },
    {
      id: 1,
      title: 'Dinner crédito',
      name: 'bdinc',
      img: dinner,
      bcheck: sbdinc,
      isAv: true,
    },
    {
      id: 1,
      title: 'Discover crédito',
      name: 'bdisc',
      img: discover,
      bcheck: sbdisc,
      isAv: true,
    },
    {
      id: 1,
      title: 'Tricard crédito',
      name: 'btric',
      img: tricard,
      bcheck: sbtric,
      isAv: true,
    },
    {
      id: 1,
      title: 'Banrisul crédito',
      name: 'bbrsc',
      img: banrisul,
      bcheck: sbbrsc,
      isAv: true,
    },
    {
      id: 1,
      title: 'Banese crédito',
      name: 'bbnsc',
      img: banese,
      bcheck: sbbnsc,
      isAv: true,
    },
    {
      id: 1,
      title: 'BrasilCard crédito',
      name: 'bbrc',
      img: brasilcard,
      bcheck: sbbrc,
      isAv: true,
    },
  ]);

  const [listvale, setListvale] = useState([
    {
      id: 0,
      title: 'Alelo',
      name: 'balec',
      img: alelo,
      bcheck: sbalec,
      isAv: true,
    },
    {
      id: 1,
      title: 'Sodexo',
      name: 'bsodc',
      img: sodexo,
      bcheck: sbsodc,
      isAv: true,
    },
    {
      id: 0,
      title: 'Vr refeição',
      name: 'bvrc',
      img: vr,
      bcheck: sbvrc,
      isAv: true,
    },
    {
      id: 0,
      title: 'Ben',
      name: 'bbenc',
      img: ben,
      bcheck: sbbenc,
      isAv: true,
    },
  ]);

  function updateStatus(ind, value, list, sec, nome) {
    setState({...state, [nome]: value});
    let data = list.map((item, index) => {
      if (ind === index) {
        return {...item, bcheck: value};
      } else {
        return item;
      }
    });

    if (sec === 'virtual') {
      setListvir(data);
    } else if (sec === 'debito') {
      setListdeb(data);
    } else if (sec === 'credito') {
      setListcred(data);
    } else if (sec === 'vale') {
      setListvale(data);
    }
  }

  const Rendervir = ({listvi, sec}) => {
    let data = listvi.map((item, index) => {
      return (
        <ListItem
          style={{display: 'inline', content: ''}}
          key={index}
          className={classes}
        >
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              flexWrap: 'wrap',
              marginRight: 20,
            }}
          >
            <Box
              style={{
                marginLeft: 10,
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 0,
                borderRadius: 5,
                flexDirection: 'row',
              }}
              boxShadow={3}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <GreenCheckbox
                  onChange={() =>
                    updateStatus(index, !item.bcheck, listvi, sec, item.name)
                  }
                  checked={item.bcheck}
                  name={item.name}
                />
                <div
                  style={{
                    width: 43.01,
                    height: 27.01,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: 'auto',
                      height: '100%',
                      flexWrap: 'nowrap',
                      resizeMode: 'contain',
                    }}
                    src={item.img}
                    alt="Logo"
                  />
                </div>
                <p style={{marginLeft: 10}}>{item.title}</p>
              </div>
            </Box>
          </div>
        </ListItem>
      );
    });
    return data;
  };

  const {
    bapp,
    bvir,
    bpre,
    bvref,
    bd,
    bame,
    bpicpay,
    bmp,
    bpaypal,
    bdind,
    bvc,
    bmcc,
    beloc,
    bhipc,
    bdinc,
    balec,
    bsord,
    bvrc,
    bbenc,
    bdisc,
    btric,
    bbnsc,
    bbrsc,
    bbrc,
    bvd,
    bmcd,
    belod,
    bhipd,
    dind,
    baled,
    bsodd,
    bvrd,
    bbend,
    bdisd,
    btrid,
    bbnsd,
    bbrsd,
    bbrd,
  } = state;

  useEffect(() => {
    setState({
      bapp: sbapp,
      bvir: sbvir,
      bpre: sbpre,
      bvref: sbvref,
      bd: sbbd,
      bpix: sbpix,
      bame: sbame,
      bpicpay: sbpicpay,
      bmp: sbmp,
      bpaypal: sbpaypal,
      bvc: sbvc,
      bmcc: sbmcc,
      beloc: sbeloc,
      bhipc: sbhipc,
      bdinc: sbdinc,
      balec: sbalec,
      bsord: sbsord,
      bvrc: sbvrc,
      bbenc: sbbenc,
      bdisc: sbdisc,
      btric: sbtric,
      bbnsc: sbbnsc,
      bbrsc: sbbrsc,
      bbrc: sbbrc,
      bvd: sbvd,
      bmcd: sbmcd,
      belod: sbelod,
      bhipd: sbhipd,
      bdind: sbdind,
      baled: sbaled,
      bsodc: sbsodc,
      bvrd: sbvrd,
      bbend: sbbend,
      bdisd: sbdisd,
      btrid: sbtrid,
      bbnsd: sbbnsd,
      bbrsd: sbbrsd,
      bbrd: sbbrd,
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [estado]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    setIsSending(true);
    if (!state.bapp && !state.bpre && !state.bvir && !state.bvref) {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack(
          'Selecione pelo menos uma categoria de pagamento',
          'error',
        );
      }, 2000);
    } else {
      let chckvir = [];
      if (state.bvir) {
        chckvir = listvir.map((item, index) => {
          if (item.bcheck) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        chckvir = [true];
      }

      let chckcred = [];
      if (state.bpre) {
        chckcred = listcred.map((item, index) => {
          if (item.bcheck) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        chckcred = [true];
      }

      let chckdeb = [];
      if (state.bpre) {
        chckdeb = listdeb.map((item, index) => {
          if (item.bcheck) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        chckdeb = [true];
      }

      let checker = arr => arr.every(v => v === true);
      let checkerfalse = arr => arr.every(v => v === false);

      let allcheckedpre = false;
      if (
        checkerfalse(chckcred) &&
        checkerfalse(chckdeb) &&
        state.bd === false
      ) {
        allcheckedpre = false;
      } else {
        allcheckedpre = true;
      }

      let chckvref = [];
      if (state.bvref) {
        chckvref = listvale.map((item, index) => {
          if (item.bcheck) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        chckvref = [true];
      }

      if (checkerfalse(chckvir)) {
        setTimeout(() => {
          setIsSending(false);
          handleNotiStack(
            'Selecione pelo menos uma forma de pagamento virtual',
            'error',
          );
        }, 2000);
      } else if (!allcheckedpre) {
        setTimeout(() => {
          setIsSending(false);
          handleNotiStack(
            'Selecione pelo menos uma forma de pagamento presencial',
            'error',
          );
        }, 2000);
      } else if (checkerfalse(chckvref)) {
        setTimeout(() => {
          setIsSending(false);
          handleNotiStack(
            'Selecione pelo menos uma forma de pagamento com vale refeição',
            'error',
          );
        }, 2000);
      } else {
        auth.currentUser.getIdToken().then(token => {
          const request = new Request(
            'https://kops.apiireit.com/gateway/store/update/payment/' +
              docid +
              '?bapp=' +
              state.bapp +
              '&bpix=' +
              state.bpix +
              '&bame=' +
              state.bame +
              '&bpicpay=' +
              state.bpicpay +
              '&bmp=' +
              state.bmp +
              '&bpaypal=' +
              state.bpaypal +
              '&bpre=' +
              state.bpre +
              '&bvir=' +
              state.bvir +
              '&bvref=' +
              state.bvref +
              '&bd=' +
              state.bd +
              '&bvd=' +
              state.bvd +
              '&bmcd=' +
              state.bmcd +
              '&belod=' +
              state.belod +
              '&bhipd=' +
              state.bhipd +
              '&bdind=' +
              state.bdind +
              '&baled=' +
              state.baled +
              '&bsood=' +
              state.bsord +
              '&bvrd=' +
              state.bvrd +
              '&bbend=' +
              state.bbend +
              '&bdisd=' +
              state.bdisd +
              '&btrid=' +
              state.btrid +
              '&bbnsd=' +
              state.bbnsd +
              '&bbrsd=' +
              state.bbrsd +
              '&bbrd=' +
              state.bbrd +
              '&bvc=' +
              state.bvc +
              '&bmcc=' +
              state.bmcc +
              '&beloc=' +
              state.beloc +
              '&bhipc=' +
              state.bhipc +
              '&bdinc=' +
              state.bdinc +
              '&balec=' +
              state.balec +
              '&bsodc=' +
              state.bsodc +
              '&bvrc=' +
              state.bvrc +
              '&bbenc=' +
              state.bbenc +
              '&bdisc=' +
              state.bdisc +
              '&bvc=' +
              state.bvc +
              '&bmcc=' +
              state.bmcc +
              '&beloc=' +
              state.beloc +
              '&bhipc=' +
              state.bhipc,
            {
              method: 'PUT',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({}),
            },
          );

          fetch(request)
            .then(response => {
              if (response.status === 200) {
                handleBackDrop('', '', 200, false);
                response
                  .json()
                  .then(data => {
                    setTimeout(() => {
                      setIsSending(false);
                      handleNotiStack(
                        'Informações atualizadas com sucesso',
                        'success',
                      );
                    }, 1000);
                    dispatch(
                      ActionStore.setrestaurante(
                        data._id,
                        data.nome,
                        data.dsc,
                        data.nota,
                        data.nNotas,
                        data.cat,
                        data.entrg,
                        data.rtrda,
                        data.agdnt,
                        data.espra,
                        data.dsct,
                        data.frete,
                        data.nAv,
                        data.pdmin,
                        data.entgrts,
                        data.location,
                        data.img,
                        data.stt,
                        data.catprice,
                        data.iient,
                        data.chck,
                        data.merc,
                        data.cpm,
                        data.promoent,
                        data.bapp,
                        data.bpix,
                        data.bame,
                        data.bpicpay,
                        data.bmp,
                        data.bpaypal,
                        data.bpre,
                        data.bvir,
                        data.bvref,
                        data.bd,
                        data.bvd,
                        data.bmcd,
                        data.belod,
                        data.bhipd,
                        data.bdind,
                        data.baled,
                        data.bsood,
                        data.bvrd,
                        data.bbend,
                        data.bdisd,
                        data.btrid,
                        data.bbnsd,
                        data.bbrsd,
                        data.bbrd,
                        data.bvc,
                        data.bmcc,
                        data.beloc,
                        data.bhipc,
                        data.bdinc,
                        data.balec,
                        data.bsodc,
                        data.bvrc,
                        data.bbenc,
                        data.bdisc,
                        data.btric,
                        data.bbnsc,
                        data.bbrsc,
                        data.bbrc,
                        data.end,
                        data.num,
                        data.bairro,
                        data.cid,
                        data.est,
                        data.cnpj,
                        data.bodom,
                        data.hdom,
                        data.boseg,
                        data.hseg,
                        data.boter,
                        data.hter,
                        data.boqua,
                        data.hqua,
                        data.boqui,
                        data.hqui,
                        data.bosex,
                        data.hsex,
                        data.bosab,
                        data.hsab,
                        data.aratdm,
                        data.raio,
                        data.bdstc,
                        data.essm,
                        data.esfds,
                        data.inalta,
                        data.npd,
                        data.znid,
                        data.zntx,
                        data.iitx,
                        data.iietx,
                        data.freetx,
                        data.freetill,
                        data.lastSeenAt,
                        data.presence,
                        data.openchat,
                        data.agd,
                        data.fagdays,
                        data.dtsm,
                        data.dtfds,
                        data.ctto,
                        data.aratdmii,
                        data.mxprmetrg,
                        data.bbank,
                        data.btcnta,
                        data.bagcia,
                        data.bcnta,
                        data.bdig,
                        data.cpix,
                        data.npix,
                        data.nimg,
                        data.comp,
                        data.cep,
                        data.ctrt,
                        data.hrCtrt,
                      ),
                    );
                  })
                  .catch(error => {
                    console.error(error);
                  });
              } else if (response.status === 503) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Serviço indisponível no momento',
                  'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                  503,
                  true,
                );
                throw new Error('Something went wrong on API server!');
              } else if (response.status === 404) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Serviço não encontrado.',
                  'Por favor, entre em contato com o nosso suporte e relate o problema.',
                  404,
                  true,
                );
              } else if (response.status === 401) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Você não possui autorização ou suas credenciais expirarão.',
                  'Por favor, atualize a baixa ou refaça o processo dee login.',
                  401,
                  true,
                );
              } else if (response.status === 400) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Requisição inválida.',
                  'Confira se todos os campos estão preenchidos corretamente.',
                  400,
                  true,
                );
              } else if (response.status === 500) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Nosso servidor está passando por irregularidades.',
                  'Estamos trabalhado para resolver o mais rápido possível.',
                  500,
                  true,
                );
              }
            })
            .catch(error => {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              console.error(error);
            });
        });
      }
    }
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          <h1
            style={{
              fontSize: isVerySmall ? 20 : 30,
              marginLeft: 10,
              marginBottom: 5,
              color: '#393939',
            }}
          >
            Formas de pagamento
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginRight: 20,
              marginTop: 10,
            }}
          >
            <div style={{marginLeft: 10, marginTop: 0}}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    onChange={handleChange}
                    checked={state.bapp}
                    name="bapp"
                  />
                }
                style={{color: '#393939'}}
                label={
                  <p
                    style={{
                      fontSize: 18,
                      color: '#393939',
                      fontWeight: 'bold',
                    }}
                  >
                    Pagamentos pelo app
                  </p>
                }
              />
            </div>
            {isAppOpen ? (
              <IconButton
                onClick={() => setAppOpen(false)}
                color="primary"
                aria-label="upload picture"
                component="span"
                size={'small'}
              >
                <ExpandLess />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setAppOpen(true)}
                color="primary"
                aria-label="upload picture"
                component="span"
                size={'small'}
              >
                <ExpandMore />
              </IconButton>
            )}
          </div>

          <Collapse in={isAppOpen} unmountOnExit>
            <Box
              style={{
                marginLeft: 10,
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 0,
                display: 'inline-block',
                borderRadius: 5,
                flexDirection: 'row',
              }}
              boxShadow={3}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div
                  style={{
                    width: 43.01,
                    height: 27.01,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: 'auto',
                      height: '100%',
                      flexWrap: 'nowrap',
                      resizeMode: 'contain',
                    }}
                    src={visa}
                    alt="Logo"
                  />
                </div>
                <p style={{marginLeft: 10}}>Visa</p>
              </div>
            </Box>
            <Box
              style={{
                marginLeft: 10,
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 0,
                display: 'inline-block',
                borderRadius: 5,
                flexDirection: 'row',
              }}
              boxShadow={3}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div
                  style={{
                    width: 43.01,
                    height: 27.01,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: 'auto',
                      height: '100%',
                      flexWrap: 'nowrap',
                      resizeMode: 'contain',
                    }}
                    src={mastercard}
                    alt="Logo"
                  />
                </div>
                <p style={{marginLeft: 10}}>MasterCard</p>
              </div>
            </Box>
            <p
              style={{
                marginLeft: 10,
                marginRight: 10,
                fontSize: 12,
                color: '#999999',
              }}
            >
              É cobrada uma taxa de 3,99% + R$ 0,40 por transação. Recebimento
              acontece até 30 dias úteis.
            </p>
          </Collapse>
          <Divider style={{marginTop: 10}} variant="middle" />
          <div
            style={{
              marginLeft: 10,
              marginTop: 0,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
                marginRight: 20,
              }}
            >
              <FormControlLabel
                control={
                  <GreenCheckbox
                    onChange={handleChange}
                    checked={state.bvir}
                    name="bvir"
                  />
                }
                style={{color: '#393939'}}
                label={
                  <p
                    style={{
                      fontSize: 18,
                      color: '#393939',
                      fontWeight: 'bold',
                    }}
                  >
                    Pagamentos virtuais
                  </p>
                }
              />
              {isVirOpen ? (
                <IconButton
                  onClick={() => setVirOpen(false)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <ExpandLess />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setVirOpen(true)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <ExpandMore />
                </IconButton>
              )}
            </div>
            <Collapse in={isVirOpen} unmountOnExit>
              <div
                style={{
                  display: 'inline',
                  content: '',
                  flexDirection: 'row',
                  width: width * 0.75,
                  marginBottom: 10,
                }}
              >
                <List style={{marginTop: -20}} component="div" disablePadding>
                  <Rendervir sec={'virtual'} listvi={listvir} />
                </List>
              </div>
            </Collapse>
            <Divider variant="middle" />
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
                marginRight: 20,
              }}
            >
              <FormControlLabel
                control={
                  <GreenCheckbox
                    onChange={handleChange}
                    checked={state.bpre}
                    name="bpre"
                  />
                }
                style={{color: '#393939'}}
                label={
                  <p
                    style={{
                      fontSize: 18,
                      color: '#393939',
                      fontWeight: 'bold',
                    }}
                  >
                    Pagamentos presenciais
                  </p>
                }
              />
              {isPreOpen ? (
                <IconButton
                  onClick={() => setPreOpen(false)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <ExpandLess />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setPreOpen(true)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <ExpandMore />
                </IconButton>
              )}
            </div>
            <Collapse in={isPreOpen} unmountOnExit>
              <div
                style={{
                  display: 'inline',
                  content: '',
                  flexDirection: 'row',
                  width: width * 0.75,
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    flex: 1,
                    flexWrap: 'wrap',
                    marginRight: 20,
                  }}
                >
                  <Box
                    style={{
                      marginLeft: 10,
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 0,
                      borderRadius: 5,
                      flexDirection: 'row',
                    }}
                    boxShadow={3}
                  >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <GreenCheckbox
                        onChange={handleChange}
                        checked={state.bd}
                        name={'bd'}
                      />
                      <div
                        style={{
                          width: 43.01,
                          height: 27.01,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          style={{
                            width: 'auto',
                            height: '100%',
                            flexWrap: 'nowrap',
                            resizeMode: 'contain',
                          }}
                          src={dinheiro}
                          alt="Logo"
                        />
                      </div>
                      <p style={{marginLeft: 10}}>{'Dinheiro'}</p>
                    </div>
                  </Box>
                </div>
                <p style={{color: '#393939', fontWeight: 'bold'}}>
                  Cartão de débito (maquininha)
                </p>
                <List style={{marginTop: -20}} component="div" disablePadding>
                  <Rendervir sec={'debito'} listvi={listdeb} />
                </List>
                <p style={{color: '#393939', fontWeight: 'bold'}}>
                  Cartão de crédito (maquininha)
                </p>
                <List style={{marginTop: -20}} component="div" disablePadding>
                  <Rendervir sec={'credito'} listvi={listcred} />
                </List>
              </div>
            </Collapse>
            <Divider variant="middle" />
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
                marginRight: 20,
              }}
            >
              <FormControlLabel
                control={
                  <GreenCheckbox
                    onChange={handleChange}
                    checked={state.bvref}
                    name="bvref"
                  />
                }
                style={{color: '#393939'}}
                label={
                  <p
                    style={{
                      fontSize: 18,
                      color: '#393939',
                      fontWeight: 'bold',
                    }}
                  >
                    Vale refeição
                  </p>
                }
              />
              {isValeOpen ? (
                <IconButton
                  onClick={() => setValeOpen(false)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <ExpandLess />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setValeOpen(true)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                >
                  <ExpandMore />
                </IconButton>
              )}
            </div>
            <Collapse in={isValeOpen} unmountOnExit>
              <div
                style={{
                  display: 'inline',
                  content: '',
                  flexDirection: 'row',
                  width: width * 0.75,
                  marginBottom: 10,
                }}
              >
                <List style={{marginTop: -20}} component="div" disablePadding>
                  <Rendervir sec={'vale'} listvi={listvale} />
                </List>
              </div>
            </Collapse>
          </div>
          <Button
            onClick={() => onSave()}
            style={{marginLeft: 10, marginTop: 10, marginBottom: 40}}
            variant="contained"
            size={'medium'}
            color="primary"
          >
            Salvar
          </Button>
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Frm;
