import {combineReducers} from 'redux';
import setstatescreen from './setStateScreen';
import setrestaurante from './setRestaurante';
import setitem from './setItem';
import setcats from './setCat';
import setcatalogo from './setCatalogo';
import setcounters from './setCounters';
import setuser from './setUser';
import setresume from './setResumeInicio';

const appReducer = combineReducers({
  setstatescreen,
  setrestaurante,
  setitem,
  setcats,
  setcatalogo,
  setcounters,
  setuser,
  setresume,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
