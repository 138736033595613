/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import {format} from '../../configuration/format';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DetailsFat from './detailsFat';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentTabs from './PaymentTabs';
import {getAuth} from 'firebase/auth';
import {useSelector} from 'react-redux';
import {dayformat} from '../../configuration/dayformat';
import RepasseTabs from './RepasseTabs';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const DialogFat = ({
  docId,
  bopen,
  handleClose,
  rowSelected,
  fatSelected,
  handleNotiStack,
  handleBackDrop,
  getFaturaMes,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [paymentOpen, setPaymentOpen] = useState(false);
  const handleClose2 = () => {
    setValue(0);
  };
  const [value, setValue] = React.useState(0);
  const [indexTab, setIndexTab] = React.useState(0);
  const [indexRepasseTab, setIndexRepasseTab] = React.useState(0);
  const [paymentDone, setPaymentDone] = useState(false);
  const [frm, setFrm] = React.useState('');
  const handleChangeFrm = event => {
    setFrm(event.target.value);
  };
  const [loadingFrmFat, setLoadingFrmFat] = useState(true);
  const [bPix, setBpix] = useState(false);
  const [bBoleto, setBboleto] = useState(false);
  const [bCard, setBcard] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [nome, setNome] = useState('');

  const [cdg, setCdg] = useState(false);
  const [exppix, setExppix] = useState(false);
  const [expblt, setExpblt] = useState(false);
  const [urlblt, setUrlblt] = useState(false);
  const [downloadBlt, setDownloadBlt] = useState(false);

  const [pixDone, setPixDone] = useState(false);
  const [pixExp, setPixExp] = useState(false);
  const [currentPix, setCurrentPix] = useState({
    status: '',
    pixCopiaECola: '',
    exp: 0,
  });

  const [boletoExp, setBoletoExp] = useState(false);
  const [currentBoleto, setCurrentBoleto] = useState({
    nboleto: '',
    expday: 0,
    urlboleto: '',
  });
  const [isLoadingPagePayment, setLoadingPagePayment] = useState(true);

  // repasse tabs
  // checkRepasse
  const [isCheckedRepasse, setCheckedRepasse] = useState(false);
  const [isRepasseOpen, setRepasseOpen] = useState(false);
  const [loadingCheckRepasse, setLoadingCheckRepasse] = useState(true);
  const [currentRepasse, setCurrentRepasse] = useState({});
  const [haveRepasse, setHaveRepasse] = useState(false);

  // dados bancarios
  const [isCheckedDB, setCheckedDb] = useState(true);
  const [txantcp, setTxantcp] = React.useState('comTaxa');
  const handleChangeTxantcp = event => {
    setTxantcp(event.target.value);
  };

  //confirm
  const [repasseIsSent, setRepasseIsSent] = useState(false);
  const [loadingPageConfirm, setLoadingPageConfirm] = useState(false);
  const [loadingRequestRepasse, setLoadingRequestRepasse] = useState(false);

  const idstore = useSelector(state => state.setrestaurante.idstore);
  const store = useSelector(state => state.setrestaurante);

  const handleChangeIndexTab = (event, newValue) => {
    setIndexTab(newValue);
  };
  function handleChangeIndex(index) {
    setIndexTab(index);
  }

  const handleChangeIndexRepasseTab = (event, newValue) => {
    setIndexRepasseTab(newValue);
  };
  function handleChangeRepasseIndex(index) {
    setIndexRepasseTab(index);
  }

  function openTabsPayment() {
    setIndexTab(0);
    setFrm('');
    setPaymentDone(false);
    setLoadingFrmFat(true);
    setLoadingPagePayment(true);
    setTimeout(() => {
      setPaymentOpen(true);
      getPaymentFat();
    }, 500);
  }

  function openTabsRepasse() {
    setCurrentRepasse({});
    setRepasseIsSent(false);
    setIndexRepasseTab(0);
    setTimeout(() => {
      setRepasseOpen(true);
    }, 500);
  }

  function closeTabsPayment() {
    setPaymentOpen(false);
  }

  function close() {
    setValue(0);
    setLoading(true);
    handleClose();
  }

  function onSave() {
    setTimeout(() => {
      handleClose();
    }, 100);
  }

  function openDialogFat(name) {
    setNome(name);
    setOpenDialog(true);
  }

  function getPaymentFat() {
    setLoadingFrmFat(true);
    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/preco/get/frm/service?type=frmfatstore',
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setBboleto(data.bblt);
                  setBpix(data.bpix);
                  setBcard(data.bcard);
                  setLoadingFrmFat(false);
                })
                .catch(error => {
                  setLoadingFrmFat(false);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingFrmFat(false);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingFrmFat(false);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setLoadingFrmFat(false);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setLoadingFrmFat(false);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setLoadingFrmFat(false);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setLoadingFrmFat(false);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }, 300);
  }

  function checkIfPaymentIsDone() {
    if (frm == 'Pix') {
      if (fatSelected.txidpix == '') {
        generatePix();
      } else {
        getPix();
      }
    } else if (frm == 'Boleto') {
      if (fatSelected.txid == '') {
        generateBoleto(false);
      } else {
        getBoleto();
      }
    }
  }

  function checkIfRequestRepasseIsDone() {
    setLoadingCheckRepasse(true);
    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/faturas/withdraw/store/get?fatid=' +
            fatSelected._id,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  if (data.length > 0) {
                    setHaveRepasse(true);
                    setRepasseIsSent(true);
                    setCurrentRepasse(data[0]);
                  } else {
                    setRepasseIsSent(false);
                    setHaveRepasse(false);
                  }
                  setCheckedRepasse(true);
                  setLoadingCheckRepasse(false);
                })
                .catch(error => {
                  setLoadingCheckRepasse(false);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setLoadingCheckRepasse(false);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }, 300);
  }

  function requestRepasse() {
    setLoadingRequestRepasse(true);
    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        let comTaxa = txantcp == 'comTaxa' ? true : false;
        const request = new Request(
          'https://kops.apiireit.com/gateway/faturas/withdraw/store/request?fatid=' +
            fatSelected._id +
            '&pix=' +
            store.cpix +
            '&banco=' +
            store.bbank +
            '&agencia=' +
            store.bagcia +
            '&conta=' +
            store.bcnta +
            '-' +
            store.bdig +
            '&txatncp=' +
            comTaxa,
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  if (data != null) {
                    setCurrentRepasse(data);
                    setRepasseIsSent(true);
                    getFaturaMes();
                  }
                  setLoadingRequestRepasse(false);
                })
                .catch(error => {
                  setLoadingRequestRepasse(false);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingRequestRepasse(false);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingRequestRepasse(false);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setLoadingRequestRepasse(false);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setLoadingRequestRepasse(false);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setLoadingRequestRepasse(false);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setLoadingRequestRepasse(false);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }, 300);
  }

  function contestRepasse() {
    setLoadingCheckRepasse(true);
    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/faturas/withdraw/store/contest?fatid=' +
            fatSelected._id,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  if (data != null) {
                    setCurrentRepasse(data);
                    setRepasseIsSent(true);
                    getFaturaMes();
                  }
                  setLoadingCheckRepasse(false);
                })
                .catch(error => {
                  setLoadingCheckRepasse(false);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setLoadingCheckRepasse(false);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setLoadingCheckRepasse(false);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }, 300);
  }

  function generatePix() {
    setLoadingPagePayment(true);
    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/pix/criarcobranca?type=fatstores' +
            '&vlr=' +
            (fatSelected.total * -1).toFixed(2) +
            '&nome=' +
            store.nome +
            '&cpf=' +
            store.cnpj +
            '&svcid=' +
            fatSelected._id +
            '&details=' +
            'Fatura de ' +
            dayformat(fatSelected.sdate) +
            ' - ' +
            dayformat(fatSelected.edate) +
            '&userid=' +
            idstore +
            '&isCpf=' +
            false,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  if (data.status == 'VENCIDO') {
                    setPixDone(false);
                    setPixExp(true);
                    setCurrentPix(data);
                    setCdg(data.pixCopiaECola);
                    setLoadingPagePayment(false);
                  } else if (data.status == 'ATIVA') {
                    setPixDone(false);
                    setPixExp(false);
                    setCurrentPix(data);
                    setCdg(data.pixCopiaECola);
                    setLoadingPagePayment(false);
                  } else {
                    setPixDone(true);
                    setPixExp(false);
                    setCurrentPix(data);
                    setCdg(data.pixCopiaECola);
                    setLoadingPagePayment(false);
                  }
                })
                .catch(error => {
                  setLoadingPagePayment(false);
                  setPixExp(true);
                  handleNotiStack('Erro ao gerar Pix', 'error');
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingPagePayment(false);
              handleNotiStack('503 - Serviço indisponível', 'error');
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingPagePayment(false);
              handleNotiStack('404 - Serviço não encontrado', 'error');
            } else if (response.status === 401) {
              setLoadingPagePayment(false);
              handleNotiStack('401 - Erro de credenciamento', 'error');
            } else if (response.status === 400) {
              setLoadingPagePayment(false);
              handleNotiStack('400 - Verifique seu CNPJ', 'error');
            } else if (response.status === 500) {
              setLoadingPagePayment(false);
              handleNotiStack('500 - Erro com servidor', 'error');
            }
          })
          .catch(error => {
            setLoadingPagePayment(false);
            handleNotiStack('500 - Erro com servidor', 'error');
            console.error(error);
          });
      });
    }, 300);
  }

  function getPix() {
    setLoadingPagePayment(true);
    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/pix/checkpayment?type=fatstores&txid=' +
            fatSelected.txidpix,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  if (data.status == 'VENCIDO') {
                    setPixDone(false);
                    setPixExp(true);
                    setCurrentPix(data);
                    setLoadingPagePayment(false);
                  } else if (data.status == 'ATIVA') {
                    setPixDone(false);
                    setPixExp(false);
                    setCurrentPix(data);
                    setLoadingPagePayment(false);
                  } else {
                    setPixDone(true);
                    setPixExp(false);
                    setCurrentPix(data);
                    setLoadingPagePayment(false);
                  }
                })
                .catch(error => {
                  setLoadingPagePayment(false);
                  setPixExp(true);
                  handleNotiStack('Erro ao gerar Pix', 'error');
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingPagePayment(false);
              handleNotiStack('503 - Serviço indisponível', 'error');
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingPagePayment(false);
              handleNotiStack('404 - Serviço não encontrado', 'error');
            } else if (response.status === 401) {
              setLoadingPagePayment(false);
              handleNotiStack('401 - Erro de credenciamento', 'error');
            } else if (response.status === 400) {
              setLoadingPagePayment(false);
              handleNotiStack('400 - Verifique seu CNPJ', 'error');
            } else if (response.status === 500) {
              setLoadingPagePayment(false);
              handleNotiStack('500 - Erro com servidor', 'error');
            }
          })
          .catch(error => {
            setLoadingPagePayment(false);
            handleNotiStack('500 - Erro com servidor', 'error');
            console.error(error);
          });
      });
    }, 300);
  }

  function generateBoleto(exp) {
    setLoadingPagePayment(true);
    let vlrblt = (fatSelected.total * -1 + fatSelected.txblt).toFixed(2) * 100;

    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/stripe/boletos/create?userid=' +
            idstore +
            '&email=' +
            auth.currentUser.email +
            '&vlr=' +
            vlrblt +
            '&store=' +
            store.nome +
            '&address=' +
            store.endereco +
            ', ' +
            store.numero +
            '&bairro=' +
            store.bairro +
            '&city=' +
            store.cidade +
            '&est=' +
            store.estado +
            '&cep=' +
            '' +
            '&pais=' +
            'BR' +
            '&cnpj=' +
            store.cnpj +
            '&svc=' +
            fatSelected._id +
            '&exp=' +
            exp +
            '&type=fatstores',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  var currentDate = new Date();
                  var expDate = new Date(data.expday);

                  if (currentDate < expDate) {
                    setLoadingPagePayment(false);
                    setCurrentBoleto(data);
                  } else {
                    setLoadingPagePayment(false);
                    setBoletoExp(true);
                  }
                })
                .catch(error => {
                  setLoadingPagePayment(false);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setLoadingPagePayment(false);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }, 300);
  }

  function getBoleto() {
    setLoadingPagePayment(true);
    setTimeout(() => {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/stripe/boletos/get?svcid=' +
            fatSelected._id,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  if (data.length > 0) {
                    setCurrentBoleto(data[0]);
                  }
                  setLoadingPagePayment(false);
                })
                .catch(error => {
                  setLoadingPagePayment(false);
                  console.error(error);
                });
            } else if (response.status === 503) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setLoadingPagePayment(false);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setLoadingPagePayment(false);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }, 300);
  }

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [isLoading]);

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <CircularProgress style={{marginTop: 20}} />
          </div>
        ) : (
          <div>
            <div
              style={{
                backgroundColor: '#FFffff',
                width: isVerySmall ? 'auto' : 300,
                height: 'auto',
                paddingLeft: 10,
                paddingRight: 10,
              }}
            ></div>
            <div
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 10,
                marginRight: 10,
                maxWidth: 300,
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  color: '#595959',
                  marginLeft: 10,
                  marginRight: 10,
                  fontSize: 20,
                }}
              >
                Detalhes da fatura:
              </p>
              <div>
                <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
                  <p>Periodo: </p>
                  <p
                    style={{
                      marginLeft: 3,
                      fontWeight: 'bold',
                      color: '#393939',
                    }}
                  >
                    {rowSelected.Período}
                  </p>
                </div>
                <Divider variant="middle" />
              </div>
              {rowSelected.type == 'Receber' ? (
                <div>
                  <div>
                    <div
                      style={{display: 'flex', marginLeft: 10, marginRight: 10}}
                    >
                      <p>Tipo: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        {rowSelected.type}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p>Recebido pelo app: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        + R$ {format(rowSelected.rcbii)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p>Parte entregadores: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        - R$ {format(rowSelected.tfreteii)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p>Parte Irê it: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        - R$ {format(rowSelected.pii)}
                      </p>
                    </div>
                  </div>
                  <div style={{marginTop: 0}}>
                    <div>
                      {rowSelected.Status.status === 'pago' ? (
                        <div>
                          <div
                            style={{
                              backgroundColor: '#eeffee',
                              alignItems: 'center',
                              paddingLeft: 10,
                              paddingTop: 5,
                              paddingBottom: 0,
                              borderRadius: 5,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 14,
                                  marginTop: 0,
                                  marginRight: 3,
                                  color: 'rgba(57, 180, 13, 1)',
                                }}
                              >
                                R$
                              </p>
                              <p
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  marginTop: -1,
                                  color: 'rgba(57, 180, 13, 1)',
                                }}
                              >
                                {format(rowSelected.Status.vlr)}
                              </p>
                            </div>
                            <p
                              style={{
                                marginTop: -20,
                                fontSize: 12,
                                color: 'rgba(57, 180, 13, 1)',
                              }}
                            >
                              Pago em {rowSelected.Status.data}
                            </p>
                          </div>
                        </div>
                      ) : rowSelected.Status.status === 'agendado' ? (
                        <div
                          style={{
                            backgroundColor: '#f4f4f4',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 0,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginTop: 0,
                                marginRight: 3,
                              }}
                            >
                              R$
                            </p>
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginTop: -1,
                              }}
                            >
                              {format(rowSelected.Status.vlr)}
                            </p>
                          </div>
                          <p
                            style={{
                              marginTop: -20,
                              fontSize: 12,
                              color: '#999999',
                            }}
                          >
                            Repasse previsto até {rowSelected.Status.data}
                          </p>
                        </div>
                      ) : rowSelected.Status.status === 'aberto' ? (
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 0,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginTop: 0,
                                marginRight: 3,
                              }}
                            >
                              R$
                            </p>
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginTop: -1,
                              }}
                            >
                              {format(rowSelected.Status.vlr)}
                            </p>
                          </div>
                          <p
                            style={{
                              marginTop: -20,
                              fontSize: 12,
                              color: '#999999',
                            }}
                          >
                            Em aberto. Pagar até: {rowSelected.Status.data}
                          </p>
                        </div>
                      ) : rowSelected.Status.status === 'atrasado' ? (
                        <div
                          style={{
                            backgroundColor: '#fff4f4',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 0,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginTop: 0,
                                marginRight: 3,
                                color: '#ff3333',
                              }}
                            >
                              R$
                            </p>
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginTop: -1,
                                color: '#ff3333',
                              }}
                            >
                              {format(rowSelected.Status.vlr)}
                            </p>
                          </div>
                          <p
                            style={{
                              marginTop: -20,
                              fontSize: 12,
                              color: '#ff9999',
                            }}
                          >
                            Em atraso. Expirou em:
                            {rowSelected.Status.data}
                          </p>
                        </div>
                      ) : rowSelected.Status.status === 'aguardando' ? (
                        <div>
                          {rowSelected.Status.vlr < 0 ? (
                            <div
                              style={{
                                backgroundColor: '#ffffcc',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingTop: 5,
                                paddingBottom: 0,
                                borderRadius: 5,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 14,
                                    marginTop: 0,
                                    marginRight: 3,
                                    color: '#ff6600',
                                  }}
                                >
                                  R$
                                </p>
                                <p
                                  style={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    marginTop: -1,
                                    color: '#ff6600',
                                  }}
                                >
                                  {format(rowSelected.Status.vlr)}
                                </p>
                              </div>
                              <p
                                style={{
                                  marginTop: -20,
                                  fontSize: 12,
                                  color: '#ff6600',
                                }}
                              >
                                Saldo devedor
                              </p>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor: '#e8ffff',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingTop: 5,
                                paddingBottom: 0,
                                borderRadius: 5,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 14,
                                    marginTop: 0,
                                    marginRight: 3,
                                    color: '#06448e',
                                  }}
                                >
                                  R$
                                </p>
                                <p
                                  style={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    marginTop: -1,
                                    color: '#06448e',
                                  }}
                                >
                                  {format(rowSelected.Status.vlr)}
                                </p>
                              </div>
                              <p
                                style={{
                                  marginTop: -20,
                                  fontSize: 12,
                                  color: '#06448e',
                                }}
                              >
                                Aguardando agendamento. Previsão: até{' '}
                                {rowSelected.Status.data}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{width: 180}}>
                        Recebido pelo estabelecimento:{' '}
                      </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        + R$ {format(rowSelected.rcbStore)}
                      </p>
                    </div>
                    <Divider
                      style={{backgroundColor: '#000000'}}
                      variant="fullWidth"
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{width: 180}}>Parte do estabelecimento: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#000000',
                        }}
                      >
                        R$ {format(rowSelected.ptm)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <div
                      style={{display: 'flex', marginLeft: 10, marginRight: 10}}
                    >
                      <p>Tipo: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        {rowSelected.type}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p>Parte Irê it: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        - R$ {format(rowSelected.pii)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p>Parte entregadores: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        - R$ {format(rowSelected.tfreteii)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p>Recebido pela Irê it: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        + R$ {format(rowSelected.rcbii)}
                      </p>
                    </div>
                  </div>
                  <div style={{marginTop: 0}}>
                    <div>
                      {rowSelected.Status.status === 'pago' ? (
                        <div>
                          <div
                            style={{
                              backgroundColor: '#eeffee',
                              alignItems: 'center',
                              paddingLeft: 10,
                              paddingTop: 5,
                              paddingBottom: 0,
                              borderRadius: 5,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 14,
                                  marginTop: 0,
                                  marginRight: 3,
                                  color: 'rgba(57, 180, 13, 1)',
                                }}
                              >
                                R$
                              </p>
                              <p
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  marginTop: -1,
                                  color: 'rgba(57, 180, 13, 1)',
                                }}
                              >
                                {format(rowSelected.Status.vlr)}
                              </p>
                            </div>
                            <p
                              style={{
                                marginTop: -20,
                                fontSize: 12,
                                color: 'rgba(57, 180, 13, 1)',
                              }}
                            >
                              Pago em {rowSelected.Status.data}
                            </p>
                          </div>
                        </div>
                      ) : rowSelected.Status.status === 'agendado' ? (
                        <div
                          style={{
                            backgroundColor: '#f4f4f4',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 0,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginTop: 0,
                                marginRight: 3,
                              }}
                            >
                              R$
                            </p>
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginTop: -1,
                              }}
                            >
                              {format(rowSelected.Status.vlr)}
                            </p>
                          </div>
                          <p
                            style={{
                              marginTop: -20,
                              fontSize: 12,
                              color: '#999999',
                            }}
                          >
                            Repasse previsto até {rowSelected.Status.data}
                          </p>
                        </div>
                      ) : rowSelected.Status.status === 'aberto' ? (
                        <div
                          style={{
                            backgroundColor: '#ffffff',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 0,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginTop: 0,
                                marginRight: 3,
                              }}
                            >
                              R$
                            </p>
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginTop: -1,
                              }}
                            >
                              {format(rowSelected.Status.vlr)}
                            </p>
                          </div>
                          <p
                            style={{
                              marginTop: -20,
                              fontSize: 12,
                              color: '#999999',
                            }}
                          >
                            Em aberto. Pagar até: {rowSelected.Status.data}
                          </p>
                        </div>
                      ) : rowSelected.Status.status === 'atrasado' ? (
                        <div
                          style={{
                            backgroundColor: '#fff4f4',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 0,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginTop: 0,
                                marginRight: 3,
                                color: '#ff3333',
                              }}
                            >
                              R$
                            </p>
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginTop: -1,
                                color: '#ff3333',
                              }}
                            >
                              {format(rowSelected.Status.vlr)}
                            </p>
                          </div>
                          <p
                            style={{
                              marginTop: -20,
                              fontSize: 12,
                              color: '#ff9999',
                            }}
                          >
                            Em atraso. Expirou em:
                            {rowSelected.Status.data}
                          </p>
                        </div>
                      ) : rowSelected.Status.status === 'aguardando' ? (
                        <div>
                          {rowSelected.Status.vlr < 0 ? (
                            <div
                              style={{
                                backgroundColor: '#ffffcc',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingTop: 5,
                                paddingBottom: 0,
                                borderRadius: 5,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 14,
                                    marginTop: 0,
                                    marginRight: 3,
                                    color: '#ff6600',
                                  }}
                                >
                                  R$
                                </p>
                                <p
                                  style={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    marginTop: -1,
                                    color: '#ff6600',
                                  }}
                                >
                                  {format(rowSelected.Status.vlr)}
                                </p>
                              </div>
                              <p
                                style={{
                                  marginTop: -20,
                                  fontSize: 12,
                                  color: '#ff6600',
                                }}
                              >
                                Saldo devedor
                              </p>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor: '#e8ffff',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingTop: 5,
                                paddingBottom: 0,
                                borderRadius: 5,
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 14,
                                    marginTop: 0,
                                    marginRight: 3,
                                    color: '#06448e',
                                  }}
                                >
                                  R$
                                </p>
                                <p
                                  style={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    marginTop: -1,
                                    color: '#06448e',
                                  }}
                                >
                                  {format(rowSelected.Status.vlr)}
                                </p>
                              </div>
                              <p
                                style={{
                                  marginTop: -20,
                                  fontSize: 12,
                                  color: '#06448e',
                                }}
                              >
                                Aguardando agendamento. Previsão: até{' '}
                                {rowSelected.Status.data}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <Divider variant="middle" />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{width: 180}}>
                        Recebido pelo estabelecimento:{' '}
                      </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#393939',
                        }}
                      >
                        + R$ {format(rowSelected.rcbStore)}
                      </p>
                    </div>
                    <Divider
                      style={{backgroundColor: '#000000'}}
                      variant="fullWidth"
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 10,
                        marginRight: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{width: 180}}>Parte do estabelecimento: </p>
                      <p
                        style={{
                          marginLeft: 3,
                          fontWeight: 'bold',
                          color: '#000000',
                        }}
                      >
                        R$ {format(rowSelected.ptm)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                  </div>
                </div>
              )}
            </div>
            <DetailsFat
              fat={fatSelected}
              rowSelected={rowSelected}
              nome={nome}
              bopen={openDialog}
              handleClose={() => setOpenDialog(false)}
              handleNotiStack={handleNotiStack}
              style={{
                top: 0,
                position: 'absolute',
                zIndex: 3,
              }}
            />
          </div>
        )}

        <DialogActions
          style={{
            position: 'relative',
            bottom: 0,
            justifyContent: 'center',
          }}
        >
          {isVerySmall && fatSelected.total < 0 ? (
            <div>
              <Button
                color={'primary'}
                size={'small'}
                onClick={() => openTabsPayment()}
              >
                Pagar
              </Button>
              <Button
                size={'small'}
                onClick={() => openDialogFat('Detalhes da fatura')}
              >
                Detalhes
              </Button>
              <Button color="secondary" size={'small'} onClick={() => close()}>
                Fechar
              </Button>
            </div>
          ) : (
            <div>
              {fatSelected.total < 0 ? (
                <Button color={'primary'} onClick={() => openTabsPayment()}>
                  Pagar
                </Button>
              ) : (
                <Button color={'primary'} onClick={() => openTabsRepasse()}>
                  Solicitar repasse
                </Button>
              )}
              <Button onClick={() => openDialogFat('Detalhes da fatura')}>
                Detalhes
              </Button>
              <Button color="secondary" onClick={() => close()}>
                Fechar
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => closeTabsPayment()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={paymentOpen}
      >
        <PaymentTabs
          value={indexTab}
          handleChange={handleChangeIndexTab}
          handleChangeIndex={handleChangeIndex}
          frm={frm}
          paymentDone={paymentDone}
          setPaymentDone={setPaymentDone}
          handleChangeFrm={handleChangeFrm}
          loadingFrmFat={loadingFrmFat}
          bBoleto={bBoleto}
          bPix={bPix}
          bCard={bCard}
          cdg={cdg}
          setCdg={setCdg}
          exppix={exppix}
          setExppix={setExppix}
          expblt={expblt}
          setExpblt={setExpblt}
          urlblt={urlblt}
          setUrlblt={setUrlblt}
          downloadBlt={downloadBlt}
          setDownloadBlt={setDownloadBlt}
          fatSelected={fatSelected}
          checkIfPaymentIsDone={checkIfPaymentIsDone}
          currentBoleto={currentBoleto}
          currentPix={currentPix}
          pixDone={pixDone}
          pixExp={pixExp}
          boletoExp={boletoExp}
          generateBoleto={generateBoleto}
          generatePix={generatePix}
          getBoleto={getBoleto}
          getPix={getPix}
          isLoadingPagePayment={isLoadingPagePayment}
          setLoadingPagePayment={setLoadingPagePayment}
          handleNotiStack={handleNotiStack}
        />
        <Divider variant="middle" />
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <Button size={'small'} onClick={() => closeTabsPayment()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => closeTabsPayment()}>Cancelar</Button>
          )}
          {isSmall ? (
            indexTab === 2 ? (
              <Button
                size={'small'}
                onClick={() => closeTabsPayment()}
                color="primary"
              >
                Finalizar
              </Button>
            ) : indexTab == 1 && frm == '' ? (
              <Button
                size={'small'}
                onClick={() => {
                  handleNotiStack('Escolha a forma de pagamento', 'error');
                }}
                color="primary"
              >
                Próximo
              </Button>
            ) : (
              <Button
                size={'small'}
                onClick={() => handleChangeIndex(indexTab + 1)}
                color="primary"
              >
                Próximo
              </Button>
            )
          ) : indexTab === 2 ? (
            <Button onClick={() => closeTabsPayment()} color="primary">
              Finalizar
            </Button>
          ) : indexTab == 1 && frm == '' ? (
            <Button
              onClick={() => {
                handleNotiStack('Escolha a forma de pagamento', 'error');
              }}
              color="primary"
            >
              Próximo
            </Button>
          ) : (
            <Button
              onClick={() => handleChangeIndex(indexTab + 1)}
              color="primary"
            >
              Próximo
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setRepasseOpen(false)}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={isRepasseOpen}
      >
        <RepasseTabs
          value={indexRepasseTab}
          handleChange={handleChangeIndexRepasseTab}
          handleChangeIndex={handleChangeRepasseIndex}
          loadingCheckRepasse={loadingCheckRepasse}
          setCheckRepasse={setCheckedRepasse}
          haveRepasse={haveRepasse}
          currentRepasse={currentRepasse}
          setCurrentRepasse={setCurrentRepasse}
          isCheckedRepasse={isCheckedRepasse}
          setCheckedRepasse={setCheckedRepasse}
          handleNotiStack={handleNotiStack}
          fatSelected={fatSelected}
          isCheckedDB={isCheckedDB}
          setCheckedDb={setCheckedDb}
          txantcp={txantcp}
          handleChangeTxantcp={handleChangeTxantcp}
          loadingPageConfirm={loadingPageConfirm}
          setLoadingPageConfirm={setLoadingPageConfirm}
          repasseIsSent={repasseIsSent}
          setRepasseIsSent={setRepasseIsSent}
          requestRepasse={requestRepasse}
          loadingRequestRepasse={loadingRequestRepasse}
          checkIfRequestRepasseIsDone={checkIfRequestRepasseIsDone}
          contestRepasse={contestRepasse}
          setLoadingRequestRepasse={setLoadingRequestRepasse}
        />
        <Divider variant="middle" />
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <Button size={'small'} onClick={() => setRepasseOpen(false)}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => setRepasseOpen(false)}>Cancelar</Button>
          )}
          {isSmall ? (
            indexRepasseTab === 2 || haveRepasse ? (
              <Button
                size={'small'}
                onClick={() => setRepasseOpen(false)}
                color="primary"
                disabled={!repasseIsSent}
              >
                Finalizar
              </Button>
            ) : (
              <Button
                size={'small'}
                onClick={() => handleChangeRepasseIndex(indexRepasseTab + 1)}
                color="primary"
              >
                Próximo
              </Button>
            )
          ) : indexRepasseTab === 2 || haveRepasse ? (
            <Button
              onClick={() => setRepasseOpen(false)}
              color="primary"
              disabled={!repasseIsSent}
            >
              Finalizar
            </Button>
          ) : (
            <Button
              onClick={() => handleChangeRepasseIndex(indexRepasseTab + 1)}
              color="primary"
            >
              Próximo
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogFat;
