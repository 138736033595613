/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogPd from './dialogPd';
import MuiPhoneInput from 'material-ui-phone-number';
import TextField from '@material-ui/core/TextField';
import {DataGrid, ptBR} from '@material-ui/data-grid';
import {realFormat} from '../../configuration/realFormat';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {ptBR as corePtBR} from '@material-ui/core/locale';
import {useSelector} from 'react-redux';
import {getAuth} from 'firebase/auth';
import DialogGenerateOcorrenicia from '../../components/DialogGenerateOcorrenicia';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

const theme = createTheme(
  {
    palette: {
      primary: {main: '#06448e'},
    },
  },
  ptBR,
  corePtBR,
);

// eslint-disable-next-line react/prop-types
const Pedidos = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const classes = useStyles();
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const [numero, setNumero] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cStatus, setCstatus] = useState('');
  const [currentItem, setCurrentItem] = useState({});
  const [comments, setCommens] = useState([
    {
      option: '',
    },
    {
      option: 'Pago pelo app',
    },
    {
      option: 'Pix',
    },
    {
      option: 'Dinheiro',
    },
    {
      option: 'Cartão de débito',
    },
    {
      option: 'Cartão de crédito',
    },
    {
      option: 'Vale refeição',
    },
  ]);

  const [status, setStatus] = useState([
    {
      option: '',
    },
    {
      option: 'Esperando aprovação',
    },
    {
      option: 'Em produção',
    },
    {
      option: 'Pedido pronto',
    },
    {
      option: 'Em entrega',
    },
    {
      option: 'Finalizado',
    },
    {
      option: 'Cancelado',
    },
  ]);

  const [comment, setComment] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogOc, setOpenDialogOc] = useState(false);

  const handleChange = event => {
    setCstatus(event.target.value);
  };

  const handleChange2 = event => {
    setComment(event.target.value);
  };

  const [selectedInitialDate, setSelectedInitialDate] = React.useState(
    new Date(),
  );
  const handleInitialDateChange = date => {
    setSelectedInitialDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      scrollToTop();
    }, 1000);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  function createData(npd, data, frm, total, status, frete, dsct, id) {
    return {npd, data, frm, total, frete, status, dsct, id};
  }

  const [rows, setRows] = useState([]);
  const columns = [
    {field: 'npd', headerName: 'Pedido', width: 150, editable: false},
    {
      field: 'fday',
      headerName: 'Data',
      width: 150,
      editable: false,
    },
    {
      field: 'frm',
      headerName: 'Pagamento',
      width: 150,
      editable: false,
    },
    {
      field: 'total',
      headerName: 'Valor',
      type: 'number',
      width: 150,
      editable: false,
      valueFormatter: params => {
        if (params.value == null) {
          return '';
        }
        return `${realFormat(params.value)}`;
      },
    },
    {
      field: 'frete',
      headerName: 'Entrega',
      type: 'number',
      width: 150,
      editable: false,
      valueFormatter: params => {
        if (params.value == null) {
          return '';
        }
        return `${realFormat(params.value)}`;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'number',
      width: 150,
      editable: false,
      valueFormatter: params => {
        if (params.value == 0) {
          return 'Esperando aprovação';
        } else if (params.value == 1) {
          return 'Em produção';
        } else if (params.value == 2) {
          return 'Pedido pronto';
        } else if (params.value == 3) {
          return 'Em entrega';
        } else if (params.value == 4) {
          return 'Finalizado';
        } else {
          return 'Cancelado';
        }
      },
    },
    {
      field: 'dsc',
      headerName: 'Desconto',
      type: 'number',
      width: 150,
      editable: false,
      valueFormatter: params => {
        if (params.value == null) {
          return '';
        }
        return `${realFormat(params.value)}`;
      },
    },
  ];
  const [resultsSize, setResultsize] = useState(0);
  const [results, setResults] = useState([]);

  useEffect(() => {
    let map = results.map((item, index) => {
      return createData(
        item.npd,
        item.data,
        item.frm,
        item.total,
        item.frete,
        item.status,
        item.dsct,
        item.id,
      );
    });
    setRows(results);
    setResultsize(results.length);
  }, [results]);

  const [docId, setDocId] = useState('');

  function convertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }

  function onSearching() {
    let frm = '';

    if (comment == undefined) {
      frm = '';
    } else if (comment == 'Pago pelo app') {
      frm = 'pagopeloapp';
    } else {
      frm = comment;
    }

    let stt = 0;
    if (cStatus == 'Esperando aprovação') {
      stt = 0;
    } else if (cStatus == 'Em produção') {
      stt = 1;
    } else if (cStatus == 'Pedido pronto') {
      stt = 2;
    } else if (cStatus == 'Em entrega') {
      stt = 3;
    } else if (cStatus == 'Finalizado') {
      stt = 4;
    } else if (cStatus == 'Cancelado') {
      stt = 5;
    } else {
      stt = -1;
    }

    let numb = 0;
    if (numero.toString() == '' || numero == undefined) {
      numb = -1;
    } else {
      numb = parseInt(numero);
    }

    setIsSearching(true);
    auth.currentUser.getIdToken().then(token => {
      const pedidosrequest = new Request(
        'https://kops.apiireit.com/gateway/pedidos/get/filter?estid=' +
          idstore +
          '&npd=' +
          numb +
          '&startdate=' +
          convertDate(selectedInitialDate) +
          '&enddate=' +
          convertDate(selectedEndDate) +
          '&nome=' +
          nome +
          '&frm=' +
          frm +
          '&ctt=' +
          telefone.replace(/[+ - )(]/g, '') +
          '&stt=' +
          stt,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(pedidosrequest)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setResults(data);
                setTimeout(() => {
                  setIsSearching(false);
                  if (data.length == 0) {
                    handleNotiStack('Nenhum resultado encontrado');
                  }
                }, 1000);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  const handleOnCellClick = params => {
    let currentitem = {};
    currentitem = results
      .filter(orders => orders._id == params.id)
      .map(item => {
        return item;
      });
    setCurrentItem(currentitem[0]);
    setOpenDialog(true);
  };

  function resetFilter() {
    setNumero('');
    setNome('');
    setTelefone('');
    setCstatus('');
    setSelectedInitialDate(new Date());
    setSelectedEndDate(new Date());
    setResults([]);
    setComment('');
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          {isVerySmall ? (
            <div>
              <h1 style={{marginLeft: 10, marginBottom: 5, color: '#393939'}}>
                Pedidos{' '}
              </h1>
              <p
                style={{
                  color: '#393939',
                  fontSize: 14,
                  marginLeft: 10,
                }}
              >
                Aqui você poderá ver o historico de pedidos da sua loja
              </p>
              <div style={{marginTop: 15, marginLeft: 10}}>
                <p1
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#393939',
                    alignItems: 'center',
                  }}
                >
                  Buscar pedidos
                </p1>
              </div>
              <div style={{marginTop: 0, marginLeft: 10}}>
                <div style={{marginTop: 5, width: '90%'}}>
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-dialog1"
                        label="Buscar pedidos de"
                        value={selectedInitialDate}
                        onChange={handleInitialDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{marginLeft: 0, marginTop: 0}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        style={{marginLeft: 0}}
                        margin="normal"
                        id="date-picker-dialog2"
                        label="até"
                        format="dd/MM/yyyy"
                        minDate={selectedInitialDate}
                        minDateMessage={'Deve ser depois da data inicial'}
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div>
                  <TextField
                    style={{
                      marginTop: 0,
                      width: '90%',
                    }}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 14, marginTop: 0, color: '#797979'},
                    }}
                    id="numero"
                    variant="standard"
                    type={'number'}
                    label="Número"
                    defaultValue={numero}
                    value={numero}
                    onChange={event => {
                      setNumero(event.target.value);
                    }}
                  />
                </div>
                <div>
                  <TextField
                    style={{
                      marginTop: 0,
                      width: '90%',
                    }}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 14, marginTop: 0, color: '#797979'},
                    }}
                    id="nome"
                    variant="standard"
                    label="Nome"
                    defaultValue={nome}
                    value={nome}
                    onChange={event => {
                      setNome(event.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    marginTop: 5,
                  }}
                >
                  <FormControl>
                    <InputLabel
                      style={{fontSize: 14}}
                      id="demo-simple-select-label"
                    >
                      Forma de pagamento
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={comment}
                      onChange={handleChange2}
                      style={{marginTop: 14}}
                    >
                      {(Array.isArray(comments)
                        ? comments.map(({option}) => [option])
                        : Object.entries(comments)
                      ).map(([title]) => {
                        return (
                          <MenuItem
                            style={{fontSize: 14}}
                            key={title}
                            value={title}
                          >
                            {title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <MuiPhoneInput
                    label="Contato"
                    variant="standard"
                    value={telefone}
                    style={{
                      marginLeft: 0,
                      marginTop: 10,
                      marginBottom: 10,
                      width: isVerySmall ? '90%' : 200,
                    }}
                    defaultCountry="br"
                    regions={['america']}
                    onChange={value => setTelefone(value)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                  }}
                >
                  <FormControl>
                    <InputLabel
                      style={{fontSize: 14}}
                      id="demo-simple-select-label"
                    >
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cStatus}
                      onChange={handleChange}
                      style={{marginTop: 14}}
                    >
                      {(Array.isArray(status)
                        ? status.map(({option}) => [option])
                        : Object.entries(status)
                      ).map(([title]) => {
                        return (
                          <MenuItem
                            style={{fontSize: 14}}
                            key={title}
                            value={title}
                          >
                            {title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 10,
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    style={{marginLeft: 0, marginTop: 15}}
                    variant="contained"
                    size={'medium'}
                    color="primary"
                    onClick={() => onSearching()}
                  >
                    Buscar
                  </Button>
                  <Button
                    style={{marginLeft: 10, marginTop: 15}}
                    variant="contained"
                    size={'medium'}
                    color="secondary"
                    onClick={() => resetFilter()}
                  >
                    Resetar
                  </Button>
                </div>
                {isSearching ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: height / 2,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div style={{marginLeft: 0, marginRight: 20, marginTop: 30}}>
                    {resultsSize === 0 ? (
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#393939',
                          fontSize: 18,
                        }}
                      >
                        Nenhum pedido encontrado.
                      </p>
                    ) : (
                      <div>
                        {resultsSize === 1 ? (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} pedido encontrado
                          </p>
                        ) : (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} pedidos encontrados
                          </p>
                        )}
                        <div style={{height: 400, width: '100%'}}>
                          <ThemeProvider theme={theme}>
                            <DataGrid
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: 'primary.light',
                                '& .MuiDataGrid-cell:hover': {
                                  color: 'primary',
                                },
                              }}
                              onCellClick={handleOnCellClick}
                              rows={rows}
                              columns={columns}
                              pageSize={20}
                              disableSelectionOnClick
                              getRowId={row => row._id}
                            />
                          </ThemeProvider>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : isSmall ? (
            <div>
              <h1 style={{marginLeft: 10, marginBottom: 5, color: '#393939'}}>
                Pedidos{' '}
              </h1>
              <p
                style={{
                  color: '#393939',
                  fontSize: 14,
                  marginLeft: 10,
                }}
              >
                Aqui você poderá ver o historico de pedidos da sua loja
              </p>
              <div style={{marginTop: 15, marginLeft: 10}}>
                <p1
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#393939',
                    alignItems: 'center',
                  }}
                >
                  Buscar pedidos
                </p1>
              </div>
              <div style={{marginTop: 0, marginLeft: 10}}>
                <div style={{marginTop: 5, width: '90%'}}>
                  <div style={{marginLeft: 0}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-dialog1"
                        label="Buscar pedidos de"
                        value={selectedInitialDate}
                        onChange={handleInitialDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{marginLeft: 0, marginTop: 0}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        style={{marginLeft: 0}}
                        margin="normal"
                        id="date-picker-dialog2"
                        label="até"
                        format="dd/MM/yyyy"
                        minDate={selectedInitialDate}
                        minDateMessage={'Deve ser depois da data inicial'}
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div>
                  <TextField
                    style={{
                      marginTop: 0,
                      width: 200,
                    }}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 14, marginTop: 0, color: '#797979'},
                    }}
                    id="numero"
                    variant="standard"
                    label="Número"
                    type={'number'}
                    defaultValue={numero}
                    value={numero}
                    onChange={event => {
                      setNumero(event.target.value);
                    }}
                  />
                </div>
                <div>
                  <TextField
                    style={{
                      marginTop: 0,
                      width: 200,
                    }}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#393939'},
                      maxLength: 100,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 14, marginTop: 0, color: '#797979'},
                    }}
                    id="nome"
                    variant="standard"
                    label="Nome"
                    defaultValue={nome}
                    value={nome}
                    onChange={event => {
                      setNome(event.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 200,
                  }}
                >
                  <FormControl>
                    <InputLabel
                      style={{fontSize: 14}}
                      id="demo-simple-select-label"
                    >
                      Forma de pagamento
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={comment}
                      onChange={handleChange2}
                      style={{marginTop: 14}}
                    >
                      {(Array.isArray(comments)
                        ? comments.map(({option}) => [option])
                        : Object.entries(comments)
                      ).map(([title]) => {
                        return (
                          <MenuItem
                            style={{fontSize: 14}}
                            key={title}
                            value={title}
                          >
                            {title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <MuiPhoneInput
                    label="Contato"
                    variant="standard"
                    value={telefone}
                    style={{
                      marginLeft: 0,
                      marginTop: 10,
                      marginBottom: 10,
                      width: isVerySmall ? '90%' : 200,
                    }}
                    defaultCountry="br"
                    regions={['america']}
                    onChange={value => setTelefone(value)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 200,
                  }}
                >
                  <FormControl>
                    <InputLabel
                      style={{fontSize: 14}}
                      id="demo-simple-select-label"
                    >
                      Forma de pagamento
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cStatus}
                      onChange={handleChange}
                      style={{marginTop: 14}}
                    >
                      {(Array.isArray(status)
                        ? status.map(({option}) => [option])
                        : Object.entries(status)
                      ).map(([title]) => {
                        return (
                          <MenuItem
                            style={{fontSize: 14}}
                            key={title}
                            value={title}
                          >
                            {title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginRight: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    style={{marginLeft: 0, marginTop: 15}}
                    variant="contained"
                    size={'medium'}
                    color="primary"
                    onClick={() => onSearching()}
                  >
                    Buscar
                  </Button>
                  <Button
                    style={{marginLeft: 10, marginTop: 15}}
                    variant="contained"
                    size={'medium'}
                    color="secondary"
                    onClick={() => resetFilter()}
                  >
                    Resetar
                  </Button>
                </div>
                {isSearching ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: height / 2,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div style={{marginLeft: 0, marginRight: 20, marginTop: 30}}>
                    {resultsSize === 0 ? (
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#393939',
                          fontSize: 18,
                        }}
                      >
                        Nenhum pedido encontrado
                      </p>
                    ) : (
                      <div>
                        {resultsSize === 1 ? (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} pedido encontrado
                          </p>
                        ) : (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} pedidos encontrados
                          </p>
                        )}
                        <div style={{height: 400, width: '100%'}}>
                          <ThemeProvider theme={theme}>
                            <DataGrid
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: 'primary.light',
                                '& .MuiDataGrid-cell:hover': {
                                  color: 'primary',
                                },
                              }}
                              onCellClick={handleOnCellClick}
                              rows={rows}
                              columns={columns}
                              pageSize={20}
                              disableSelectionOnClick
                              getRowId={row => row._id}
                            />
                          </ThemeProvider>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <h1 style={{marginLeft: 20, marginBottom: 5, color: '#393939'}}>
                Pedidos{' '}
              </h1>
              <p
                style={{
                  color: '#393939',
                  fontSize: 14,
                  marginLeft: 20,
                }}
              >
                Aqui você poderá ver o historico de pedidos da sua loja
              </p>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <div style={{width: '100%'}}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 15,
                      marginBottom: 10,
                    }}
                  >
                    <p1
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#393939',
                        alignItems: 'center',
                      }}
                    >
                      Buscar pedidos
                    </p1>
                  </div>
                  <div
                    style={{
                      marginLeft: 20,
                      marginRight: 20,
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div style={{display: 'flex'}}>
                      <div style={{marginLeft: 0, marginTop: -19}}>
                        <MuiPickersUtilsProvider
                          locale={deLocale}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-dialog1"
                            label="Buscar pedidos de"
                            value={selectedInitialDate}
                            onChange={handleInitialDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div
                        style={{
                          marginLeft: -10,
                          marginTop: -19,
                          marginRight: 10,
                        }}
                      >
                        <MuiPickersUtilsProvider
                          locale={deLocale}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            style={{marginLeft: 20}}
                            margin="normal"
                            id="date-picker-dialog2"
                            label="até"
                            format="dd/MM/yyyy"
                            minDate={selectedInitialDate}
                            minDateMessage={'Deve ser depois da data inicial'}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div>
                      <TextField
                        style={{
                          marginTop: 0,
                          width: 200,
                          marginRight: 10,
                        }}
                        inputProps={{
                          style: {fontSize: 14, padding: 6, color: '#393939'},
                          maxLength: 100,
                        }}
                        InputLabelProps={{
                          style: {fontSize: 14, marginTop: 0, color: '#797979'},
                        }}
                        id="numero"
                        variant="standard"
                        type={'number'}
                        label="Número"
                        defaultValue={numero}
                        value={numero}
                        onChange={event => {
                          setNumero(event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        style={{
                          marginTop: 0,
                          width: 200,
                          marginRight: 10,
                        }}
                        inputProps={{
                          style: {fontSize: 14, padding: 6, color: '#393939'},
                          maxLength: 100,
                        }}
                        InputLabelProps={{
                          style: {fontSize: 14, marginTop: 0, color: '#797979'},
                        }}
                        id="nome"
                        variant="standard"
                        label="Nome"
                        defaultValue={nome}
                        value={nome}
                        onChange={event => {
                          setNome(event.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: 10,
                        marginRight: 10,
                        marginLeft: -10,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 170,
                        }}
                      >
                        <FormControl className={classes.formControl}>
                          <InputLabel
                            style={{fontSize: 14}}
                            id="demo-simple-select-label"
                          >
                            Forma de pagamento
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={comment}
                            onChange={handleChange2}
                            style={{marginTop: 14}}
                          >
                            {(Array.isArray(comments)
                              ? comments.map(({option}) => [option])
                              : Object.entries(comments)
                            ).map(([title]) => {
                              return (
                                <MenuItem
                                  style={{fontSize: 14}}
                                  key={title}
                                  value={title}
                                >
                                  {title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div>
                      <MuiPhoneInput
                        label="Contato"
                        variant="standard"
                        value={telefone}
                        style={{
                          marginLeft: 0,
                          marginTop: -2,
                          marginBottom: 10,
                          width: isVerySmall ? '90%' : 200,
                        }}
                        defaultCountry="br"
                        regions={['america']}
                        onChange={value => setTelefone(value)}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        marginBottom: 10,
                        marginRight: 10,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 170,
                        }}
                      >
                        <FormControl className={classes.formControl}>
                          <InputLabel
                            style={{fontSize: 14}}
                            id="demo-simple-select-label"
                          >
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cStatus}
                            onChange={handleChange}
                            style={{marginTop: 14}}
                          >
                            {(Array.isArray(status)
                              ? status.map(({option}) => [option])
                              : Object.entries(status)
                            ).map(([title]) => {
                              return (
                                <MenuItem
                                  style={{fontSize: 14}}
                                  key={title}
                                  value={title}
                                >
                                  {title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: 10,
                      alignItems: 'cemter ',
                    }}
                  >
                    <Button
                      style={{marginLeft: 15, marginTop: 15}}
                      variant="contained"
                      size={'medium'}
                      color="primary"
                      onClick={() => onSearching()}
                    >
                      Buscar
                    </Button>
                    <Button
                      style={{marginLeft: 10, marginTop: 15}}
                      variant="contained"
                      size={'medium'}
                      color="secondary"
                      onClick={() => resetFilter()}
                    >
                      Resetar
                    </Button>
                  </div>
                  {isSearching ? (
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: height / 2,
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <div
                      style={{marginLeft: 20, marginRight: 20, marginTop: 30}}
                    >
                      {resultsSize === 0 ? (
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            fontSize: 18,
                          }}
                        >
                          Nenhum pedido encontrado
                        </p>
                      ) : (
                        <div>
                          {resultsSize === 1 ? (
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#393939',
                                fontSize: 18,
                              }}
                            >
                              {resultsSize} pedido encontrado
                            </p>
                          ) : (
                            <p
                              pageSize={20}
                              style={{
                                fontWeight: 'bold',
                                color: '#393939',
                                fontSize: 18,
                              }}
                            >
                              {resultsSize} pedidos encontrados
                            </p>
                          )}
                          <div style={{height: 400, width: '100%'}}>
                            <ThemeProvider theme={theme}>
                              <DataGrid
                                onCellClick={handleOnCellClick}
                                rows={rows}
                                columns={columns}
                                pageSize={20}
                                disableSelectionOnClick
                                getRowId={row => row._id}
                              />
                            </ThemeProvider>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <DialogPd
            docId={docId}
            bopen={openDialog}
            handleClose={() => setOpenDialog(false)}
            currentitem={currentItem}
            handleNotiStack={handleNotiStack}
            setOpenDialogOc={setOpenDialogOc}
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 3,
            }}
          />
        </div>
      )}
      <DialogGenerateOcorrenicia
        bopen={openDialogOc}
        handleClose={() => setOpenDialogOc(false)}
        handleNotiStack={handleNotiStack}
        DATA={currentItem}
        setDATA={setCurrentItem}
        updateList={onSearching}
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Pedidos;
