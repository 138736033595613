/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useLayoutEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {useDropzone} from 'react-dropzone';
import ImageIcon from '@material-ui/icons/Image';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import MuiPhoneInput from 'material-ui-phone-number';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from 'react-redux';
import AlertSending from '../../components/AlertSeding';
import * as ActionStore from '../../store/actions/setRestaurante';
import {useDispatch} from 'react-redux';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 0,
};

const thumb = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 90,
  height: 90,
  padding: 0,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: 90,
};

// eslint-disable-next-line react/prop-types
const Informacoes = ({
  handleToggleSidebar,
  handleNotiStack,
  handleBackDrop,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [width, height] = useWindowSize();
  const [cnome, setCnome] = useState('');
  const [cdes, setCdes] = useState('');
  const [cContato, setCcontato] = useState('');
  const [cpmin, setCcpmin] = useState(0);

  const nome = useSelector(state => state.setrestaurante.nome);
  const imge = useSelector(state => state.setrestaurante.img);
  const nimge = useSelector(state => state.setrestaurante.nimg);
  const descricao = useSelector(state => state.setrestaurante.descricao);
  const pedidominimo = useSelector(state => state.setrestaurante.pedidominimo);
  const cate = useSelector(state => state.setrestaurante.cat);
  const contato = useSelector(state => state.setrestaurante.contato);
  const estado = useSelector(state => state.setrestaurante);
  const docid = useSelector(state => state.setrestaurante.id);
  const email = useSelector(state => state.setuser.email);
  const idstore = useSelector(state => state.setrestaurante.idstore);

  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {files.length > 0 ? (
          <img src={file.preview} style={img} />
        ) : (
          <img src={file.preview + '?t=' + new Date().getTime()} style={img} />
        )}
      </div>
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );
  const [cat, setCat] = React.useState('');
  const [categorias, setCategorias] = useState([
    {
      title: 'Mercado',
    },
    {
      title: 'Lanches',
    },
    {
      title: 'Pizza',
    },
    {
      title: 'Gelados',
    },
    {
      title: 'Doces',
    },
    {
      title: 'Salgados',
    },
    {
      title: 'Açaí',
    },
    {
      title: 'Refeição',
    },
    {
      title: 'Oriental',
    },
    {
      title: 'Natural',
    },
    {
      title: 'Hortifruti',
    },
    {
      title: 'Açougue',
    },
    {
      title: 'Cafeteria',
    },
    {
      title: 'Frios',
    },
    {
      title: 'Padaria',
    },
    {
      title: 'Bebidas',
    },
    {
      title: 'Gás e Água',
    },
    {
      title: 'Farmácia',
    },
    {
      title: 'Roupa',
    },
    {
      title: 'Cosmédico',
    },
    {
      title: 'Eletrônicos',
    },
    {
      title: 'Livraria',
    },
    {
      title: 'Pet',
    },
    {
      title: 'Variedades',
    },
    {
      title: 'Mercearia',
    },
  ]);
  const handleChange = event => {
    setCat(event.target.value);
  };

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    let newimg = '';
    if (files.length !== 0) {
      newimg = 'https://ireifiles.s3.amazonaws.com/' + idstore + '/logo/';
    }

    setIsSending(true);
    if (cnome === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Preencha o campo nome', 'error');
      }, 1000);
    } else if (cContato === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Preencha o campo contato', 'error');
      }, 1000);
    } else if (cat === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Selecione uma categoria', 'error');
      }, 1000);
    } else if (cdes === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Preencha o campo descrição', 'error');
      }, 1000);
    } else if (value === 0 || value === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Adicione um preço mínimo', 'error');
      }, 1000);
    } else if (files.length === 0) {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Adicione uma foto', 'error');
      }, 1000);
    } else {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/store/update/profile/' +
            docid +
            '?name=' +
            cnome +
            '&phone=' +
            cContato +
            '&description=' +
            cdes +
            '&cat=' +
            cat +
            '&img=' +
            newimg +
            '&email=' +
            email +
            '&MinPrice=' +
            value,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );
        saveImage();
        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setIsSending(false);
                  setFiles([]);
                  setTimeout(() => {
                    handleNotiStack(
                      'Informações atualizadas com sucesso',
                      'success',
                    );
                  }, 1000);
                  dispatch(
                    ActionStore.setrestaurante(
                      data._id,
                      data.nome,
                      data.dsc,
                      data.nota,
                      data.nNotas,
                      data.cat,
                      data.entrg,
                      data.rtrda,
                      data.agdnt,
                      data.espra,
                      data.dsct,
                      data.frete,
                      data.nAv,
                      data.pdmin,
                      data.entgrts,
                      data.location,
                      data.img,
                      data.stt,
                      data.catprice,
                      data.iient,
                      data.chck,
                      data.merc,
                      data.cpm,
                      data.promoent,
                      data.bapp,
                      data.bpix,
                      data.bame,
                      data.bpicpay,
                      data.bmp,
                      data.bpaypal,
                      data.bpre,
                      data.bvir,
                      data.bvref,
                      data.bd,
                      data.bvd,
                      data.bmcd,
                      data.belod,
                      data.bhipd,
                      data.bdind,
                      data.baled,
                      data.bsood,
                      data.bvrd,
                      data.bbend,
                      data.bdisd,
                      data.btrid,
                      data.bbnsd,
                      data.bbrsd,
                      data.bbrd,
                      data.bvc,
                      data.bmcc,
                      data.beloc,
                      data.bhipc,
                      data.bdinc,
                      data.balec,
                      data.bsodc,
                      data.bvrc,
                      data.bbenc,
                      data.bdisc,
                      data.btric,
                      data.bbnsc,
                      data.bbrsc,
                      data.bbrc,
                      data.end,
                      data.num,
                      data.bairro,
                      data.cid,
                      data.est,
                      data.cnpj,
                      data.bodom,
                      data.hdom,
                      data.boseg,
                      data.hseg,
                      data.boter,
                      data.hter,
                      data.boqua,
                      data.hqua,
                      data.boqui,
                      data.hqui,
                      data.bosex,
                      data.hsex,
                      data.bosab,
                      data.hsab,
                      data.aratdm,
                      data.raio,
                      data.bdstc,
                      data.essm,
                      data.esfds,
                      data.inalta,
                      data.npd,
                      data.znid,
                      data.zntx,
                      data.iitx,
                      data.iietx,
                      data.freetx,
                      data.freetill,
                      data.lastSeenAt,
                      data.presence,
                      data.openchat,
                      data.agd,
                      data.fagdays,
                      data.dtsm,
                      data.dtfds,
                      data.ctto,
                      data.aratdmii,
                      data.mxprmetrg,
                      data.bbank,
                      data.btcnta,
                      data.bagcia,
                      data.bcnta,
                      data.bdig,
                      data.cpix,
                      data.npix,
                      data.nimg,
                      data.comp,
                      data.cep,
                      data.ctrt,
                      data.hrCtrt,
                    ),
                  );
                })
                .catch(error => {
                  console.error(error);
                });
            } else if (response.status === 503) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }
  }

  const [value, setValue] = useState();
  function handlyvalue(value) {
    setValue(value);
  }

  function handlycnome(value) {
    setCnome(value);
  }

  useEffect(() => {
    setTimeout(() => {
      if (imge !== '') {
        setFiles([
          {
            preview: imge + idstore + '?newdate=' + new Date(),
          },
        ]);
      } else {
        setFiles([]);
      }

      setCnome(nome);
      setCdes(descricao);
      setCat(cate);
      setValue(pedidominimo);
      setCcontato(contato);
      setLoading(false);
    }, 300);
  }, [estado]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function saveImage() {
    if (files.length !== 0) {
      if (files[0].path !== undefined) {
        var metadata = new FormData();
        metadata.append('file', files[0]);
        auth.currentUser.getIdToken().then(token => {
          const imgrequest = new Request(
            'https://kops.apiireit.com/gateway/s3/upload' +
              '?docid=' +
              idstore +
              '&userid=' +
              idstore +
              '&svc=logo&index=' +
              nimge,
            {
              method: 'POST',
              headers: {
                'My-Custom-Header': 'Custom-Header-Value',
                Authorization: `Bearer ${token}`,
              },
              mode: 'cors',
              body: metadata,
            },
          );

          fetch(imgrequest)
            .then(response => {
              if (response.status === 200) {
                handleBackDrop('', '', 200, false);
              } else if (response.status === 503) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Serviço indisponível no momento',
                  'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                  503,
                  true,
                );
                throw new Error('Something went wrong on API server!');
              } else if (response.status === 404) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Serviço não encontrado.',
                  'Por favor, entre em contato com o nosso suporte e relate o problema.',
                  404,
                  true,
                );
              } else if (response.status === 401) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Você não possui autorização ou suas credenciais expirarão.',
                  'Por favor, atualize a baixa ou refaça o processo dee login.',
                  401,
                  true,
                );
              } else if (response.status === 400) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Requisição inválida.',
                  'Confira se todos os campos estão preenchidos corretamente.',
                  400,
                  true,
                );
              } else if (response.status === 500) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack('Não foi possível salvar a imagem', 'error');
                }, 1000);
                handleBackDrop(
                  'Nosso servidor está passando por irregularidades.',
                  'Estamos trabalhado para resolver o mais rápido possível.',
                  500,
                  true,
                );
              }
            })
            .catch(error => {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack('Não foi possível salvar a imagem', 'error');
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              console.error(error);
              console.error(error);
            });
        });
      }
    }
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{display: 'flex', flexDirection: 'column', marginBottom: 30}}
        >
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          <h1
            style={{
              fontSize: isVerySmall ? 20 : 30,
              marginLeft: 10,
              marginBottom: 5,
              color: '#393939',
            }}
          >
            Estabelecimento
          </h1>
          <div>
            <p style={{fontSize: 16, color: '#393939', marginLeft: 10}}>
              Imagem:{' '}
            </p>
            <div
              style={{
                width: 90,
                height: 90,
                backgroundColor: '#F4F4F4',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                borderWidth: files.length === 0 ? 2 : 0,
                borderStyle: 'dashed',
                borderColor: '#d4d4d4',
                marginLeft: 10,
                marginTop: 20,
              }}
            >
              <section
                style={{
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'inline-block',
                  position: 'relative',
                }}
              >
                <div>
                  <IconButton
                    onClick={() => setFiles([])}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                    style={{
                      position: 'absolute',
                      top: -15,
                      right: -15,
                      zIndex: 4,
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  {files.length === 0 ? (
                    <div
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <ImageIcon
                        style={{color: '#c4c4c4', paddingTop: 13}}
                        size={'small'}
                      />
                      <p
                        style={{
                          fontSize: 10,
                          textAlign: 'center',
                          padding: 5,
                          paddingTop: 0,
                          paddingBottom: 0,
                          color: '#c4c4c4',
                        }}
                      >
                        Arraste uma imagem aqui
                      </p>
                    </div>
                  ) : (
                    <aside style={thumbsContainer}>{thumbs}</aside>
                  )}
                </div>
              </section>
            </div>
            <TextField
              style={{
                marginTop: 30,
                width: '90%',
                marginBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              inputProps={{
                style: {fontSize: 14, padding: 6, color: '#393939'},
                maxLength: 100,
              }}
              InputLabelProps={{
                style: {fontSize: 14, marginTop: -8, color: '#797979'},
              }}
              id="nome"
              variant="outlined"
              label="Nome do estabelecimento"
              defaultValue={cnome}
              value={cnome}
              onChange={event => {
                setCnome(event.target.value);
              }}
            />
            <MuiPhoneInput
              label="Contato"
              variant="outlined"
              value={cContato}
              style={{
                marginLeft: 10,
                marginTop: 10,
                marginBottom: 10,
                width: isVerySmall ? '90%' : 230,
              }}
              defaultCountry="br"
              regions={['america']}
              onChange={value => setCcontato(value)}
            />
            <TextField
              multiline={true}
              style={{
                marginTop: 20,
                width: '90%',
                marginBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              inputProps={{
                style: {fontSize: 14, padding: 6, color: '#393939'},
                maxLength: 400,
              }}
              InputLabelProps={{
                style: {fontSize: 14, marginTop: -8, color: '#797979'},
              }}
              id="nome"
              variant="outlined"
              label="Descrição"
              defaultValue={cdes}
              value={cdes}
              onChange={event => {
                setCdes(event.target.value);
              }}
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Categoria
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={cat}
                onChange={handleChange}
                label="Categoria"
              >
                {(Array.isArray(categorias)
                  ? categorias.map(({title}) => [title])
                  : Object.entries(categorias)
                ).map(([title]) => {
                  return (
                    <MenuItem style={{fontSize: 14}} key={title} value={title}>
                      {title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div style={{marginTop: 10}}>
              <CurrencyTextField
                style={{
                  marginRight: 10,
                  width: isVerySmall ? '90%' : 200,
                  marginTop: 10,
                  marginLeft: 10,
                  marginBottom: 10,
                }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    padding: 6,
                    color: '#393939',
                    paddingLeft: 0,
                  },
                  maxLength: 10,
                }}
                InputLabelProps={{
                  style: {fontSize: 14, marginTop: -8, color: '#797979'},
                }}
                label="Pedido mínimo"
                variant="outlined"
                value={value}
                currencySymbol="R$"
                minimumValue="0"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                onChange={(event, value) => handlyvalue(value)}
              />
            </div>
            <Button
              onClick={() => onSave()}
              style={{marginLeft: 10, marginTop: 10}}
              variant="contained"
              size={'medium'}
              color="primary"
            >
              Salvar
            </Button>
          </div>
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Informacoes;
