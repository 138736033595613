const INITIAL_STATE = {
  uid: '',
  email: '',
  agreement: false,
};

export default function setuser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        uid: action.uid,
        email: action.email,
      };
    case 'SET_AGREEMENT':
      return {
        ...state,
        agreement: action.agreement,
      };
    default:
      return state;
  }
}
