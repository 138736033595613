import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box} from '@material-ui/core';
import {Doughnut} from 'react-chartjs-2';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import {Bar} from 'react-chartjs-2';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GroupIcon from '@material-ui/icons/Group';
import Divider from '@material-ui/core/Divider';
import {getAuth} from 'firebase/auth';

// eslint-disable-next-line react/prop-types
const Clientes = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [isSearching, setSearching] = useState(true);
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const [selectedInitialDate, setSelectedInitialDate] = React.useState(
    new Date(),
  );
  const handleInitialDateChange = date => {
    setSelectedInitialDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };

  const [DATA, setDATA] = useState({});

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: '# of Red Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: '# of Blue Votes',
        data: [2, 3, 20, 5, 1, 4],
        backgroundColor: 'rgb(54, 162, 235)',
      },
      {
        label: '# of Green Votes',
        data: [3, 10, 13, 15, 22, 30],
        backgroundColor: 'rgb(75, 192, 192)',
      },
    ],
  });

  const [dataCircle, setDataCircle] = useState({
    labels: ['1 vez', '2 vezes', '3 Vezes', '4 vezes', '5 vezes', '6 vezes +'],
    datasets: [
      {
        label: '# of Votes',
        data: DATA.rtc,
        backgroundColor: [
          'rgba(255, 0, 105, 1)',
          '#06448E',
          'rgba(255, 255, 0, 1)',
          '#39B40D',
          '#800080',
          '#FF6600',
        ],
        borderColor: [
          'rgba(255, 0, 105, 1)',
          '#06448E',
          'rgba(255, 255, 0, 1)',
          '#39B40D',
          '#800080',
          '#FF6600',
        ],
        borderWidth: 1,
      },
    ],
  });

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setTimeout(() => {
      setSearching(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setData({
      labels: [
        '0 - 10 anos',
        '11 - 20 anos',
        '21 - 30 anos',
        '31 - 40 anos',
        '41 - 50 anos',
        '51 - 60 anos',
        '61 - 70 anos',
        '71 - 10 anos',
        '81 - 10 anos',
        '91 - 100 anos',
      ],
      datasets: [
        {
          label: 'Mulheres',
          data: DATA.mlh,
          backgroundColor: 'rgb(255, 0, 150)',
        },
        {
          label: 'Homens',
          data: DATA.hms,
          backgroundColor: 'rgb(8, 62, 142)',
        },
        {
          label: 'Outros',
          data: DATA.otrs,
          backgroundColor: 'rgb(100, 100, 100)',
        },
      ],
    });
    setDataCircle({
      labels: [
        '1 vez',
        '2 vezes',
        '3 Vezes',
        '4 vezes',
        '5 vezes',
        '6 vezes +',
      ],
      datasets: [
        {
          label: '# of Votes',
          data: DATA.rtc,
          backgroundColor: [
            'rgba(255, 0, 105, 1)',
            '#06448E',
            'rgba(255, 255, 0, 1)',
            '#39B40D',
            '#800080',
            '#FF6600',
          ],
          borderColor: [
            'rgba(255, 0, 105, 1)',
            '#06448E',
            'rgba(255, 255, 0, 1)',
            '#39B40D',
            '#800080',
            '#FF6600',
          ],
          borderWidth: 1,
        },
      ],
    });
  }, [DATA]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  function convertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }

  function onSearching() {
    if (selectedInitialDate <= selectedEndDate) {
      setSearching(true);
      auth.currentUser.getIdToken().then(token => {
        const pedidosrequest = new Request(
          'https://kops.apiireit.com/gateway/pedidos/resume/client/date?estid=' +
            idstore +
            '&startdate=' +
            convertDate(selectedInitialDate) +
            '&enddate=' +
            convertDate(selectedEndDate),
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(pedidosrequest)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setTimeout(() => {
                    setSearching(false);
                    setDATA(data);
                    setTimeout(() => {
                      setSearching(false);
                      if (data.clt == 0) {
                        handleNotiStack('Nenhum cliente encontrado');
                      }
                    }, 1000);
                  }, 1000);
                })
                .catch(error => {
                  console.error(error);
                });
            } else if (response.status === 503) {
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              setSearching(false);
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
              setSearching(false);
            } else if (response.status === 400) {
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
              setSearching(false);
            } else if (response.status === 500) {
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              setSearching(false);
            }
          })
          .catch(error => {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            setSearching(false);
            console.error(error);
          });
      });
    } else {
      handleNotiStack(
        'A data inicial não pode ser maior que a final,',
        'warning',
      );
    }
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          <h1
            style={{
              fontSize: isVerySmall ? 20 : 30,
              marginLeft: 10,
              marginBottom: 5,
              color: '#393939',
            }}
          >
            Clientes
          </h1>
          {isVerySmall || isSmall ? (
            <div>
              <div
                style={{
                  marginTop: 5,
                  width: '90%',
                  marginLeft: 10,
                }}
              >
                <div style={{display: 'flex'}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-dialog1"
                      label="Buscar dados de"
                      value={selectedInitialDate}
                      onChange={handleInitialDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{marginLeft: 0, marginTop: 0}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      style={{marginLeft: 0}}
                      margin="normal"
                      id="date-picker-dialog2"
                      label="até"
                      format="dd/MM/yyyy"
                      minDate={selectedInitialDate}
                      minDateMessage={'Deve ser depois da data inicial'}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => onSearching()}
                  style={{marginLeft: 10, marginTop: 15, marginBottom: 10}}
                  variant="contained"
                  size={'medium'}
                  color="primary"
                >
                  Buscar
                </Button>
              </div>
              <div>
                {isSearching ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: height / 2,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : isEmpty(DATA) ? (
                  <div style={{marginLeft: 10, marginRight: 10}}>
                    <p style={{fontWeight: 'bold'}}>
                      Defina a data e clique em buscar.
                    </p>
                  </div>
                ) : (
                  <div styme={{paddingLeft: 10}}>
                    <h1
                      style={{
                        fontSize: 25,
                        marginLeft: 10,
                        marginBottom: 5,
                        color: '#393939',
                      }}
                    >
                      Resultados:
                    </h1>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        flexDirection: 'column',
                        display: 'flex',
                        borderRadius: 10,
                      }}
                    >
                      <Box
                        boxShadow={3}
                        style={{
                          borderRadius: 5,
                          width: '100%',
                          marginBottom: 10,
                          marginRight: 10,
                          backgroundColor: 'rgba(8, 68, 142, 1)',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              paddingRight: 10,
                            }}
                          >
                            <GroupIcon
                              style={{fontSize: 40, color: '#FFFFFF'}}
                            />
                          </div>
                          <div
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                paddingLeft: 10,
                                fontWeight: 'bold',
                                marginTop: 0,
                                marginBottom: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              {DATA.clt}
                            </p>
                            <p
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              Clientes
                            </p>
                          </div>
                        </div>
                      </Box>
                      <Box
                        boxShadow={3}
                        style={{
                          borderRadius: 5,
                          width: '100%',
                          marginBottom: 10,
                          marginRight: 10,
                          backgroundColor: 'rgba(255, 102, 0, 1)',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              paddingRight: 10,
                            }}
                          >
                            <VisibilityIcon
                              style={{fontSize: 40, color: '#FFFFFF'}}
                            />
                          </div>
                          <div
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                paddingLeft: 10,
                                fontWeight: 'bold',
                                color: '#FFFFFF',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.vst}
                            </p>
                            <p
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              Visitas
                            </p>
                          </div>
                        </div>
                      </Box>
                      <Box
                        boxShadow={3}
                        style={{
                          borderRadius: 5,
                          width: '100%',
                          marginBottom: 10,
                          marginRight: 10,
                          backgroundColor: 'rgba(57, 180, 13, 1)',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              paddingRight: 10,
                            }}
                          >
                            <GroupAddIcon
                              style={{fontSize: 40, color: '#FFFFFF'}}
                            />
                          </div>
                          <div
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                paddingLeft: 10,
                                fontWeight: 'bold',
                                color: '#FFFFFF',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.newClt}
                            </p>
                            <p
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              Clientes novos
                            </p>
                          </div>
                        </div>
                      </Box>
                    </div>
                    <div style={{marginTop: 20, marginLeft: 10}}>
                      <p1 style={{marginTop: 20, fontSize: 24}}>
                        Perfil dos pedidos
                      </p1>
                    </div>
                    <div>
                      <div
                        style={{
                          width: isVerySmall ? width : '100%',
                        }}
                      >
                        <Box
                          boxShadow={3}
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                            marginTop: 20,
                          }}
                        >
                          <Bar
                            height={isVerySmall ? 400 : 90}
                            style={{
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                            data={data}
                            options={options}
                          />
                        </Box>
                      </div>
                    </div>
                    <div style={{marginTop: 20, marginLeft: 10}}>
                      <p1 style={{marginTop: 20, fontSize: 24}}>Retenção</p1>
                    </div>
                    <div style={{marginBottom: 60}}>
                      <div
                        style={{
                          width: isVerySmall ? '100%' : '100%',
                          display: 'flex',
                          flexDirection: isVerySmall ? 'column' : 'row',
                        }}
                      >
                        <Box
                          boxShadow={3}
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                            marginTop: 20,
                          }}
                        >
                          <Doughnut
                            height={30}
                            style={{
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                            data={dataCircle}
                            options={options}
                          />
                        </Box>
                        <div
                          style={{
                            marginTop: 20,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        >
                          <p1
                            style={{
                              fontWeight: 'bold',
                              fontSize: 20,
                              marginTop: 10,
                            }}
                          >
                            Seus resultados:
                          </p1>
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[0] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[0] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 5}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              1 vez
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[1] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[1] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 5}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              2 vezes
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[2] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[2] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 5}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              3 vezes
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[3] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[3] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 5}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              4 vezes
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[4] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[4] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 5}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              5 vezes
                            </p>
                          </div>
                          <Divider variant="middle" />
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[5] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[5] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 5}}>
                              De seus clientes compraram mais de
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              6 vezes
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  marginTop: 5,
                  width: '90%',
                  marginLeft: 10,
                  display: 'flex',
                }}
              >
                <div style={{display: 'flex'}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-dialog1"
                      label="Buscar dados de"
                      value={selectedInitialDate}
                      onChange={handleInitialDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{marginLeft: 10, marginTop: 0}}>
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      style={{marginLeft: 0}}
                      margin="normal"
                      id="date-picker-dialog2"
                      label="até"
                      format="dd/MM/yyyy"
                      minDate={selectedInitialDate}
                      minDateMessage={'Deve ser depois da data inicial'}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          height: 44,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => onSearching()}
                  style={{marginLeft: 10, marginTop: 15, marginBottom: 10}}
                  variant="contained"
                  size={'medium'}
                  color="primary"
                >
                  Buscar
                </Button>
              </div>
              <div>
                {isSearching ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: height / 2,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : isEmpty(DATA) ? (
                  <div style={{marginLeft: 10, marginRight: 10}}>
                    <p style={{fontWeight: 'bold'}}>
                      Defina a data e clique em buscar.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h1
                      style={{
                        fontSize: 25,
                        marginLeft: 10,
                        marginBottom: 5,
                        color: '#393939',
                      }}
                    >
                      Resultados:
                    </h1>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        flexDirection: 'row',
                        display: 'flex',
                        borderRadius: 10,
                      }}
                    >
                      <Box
                        boxShadow={3}
                        style={{
                          borderRadius: 5,
                          width: '17%',
                          marginRight: 10,
                          backgroundColor: 'rgba(8, 68, 142, 1)',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              paddingRight: 10,
                            }}
                          >
                            <GroupIcon
                              style={{fontSize: 40, color: '#FFFFFF'}}
                            />
                          </div>
                          <div
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                paddingLeft: 10,
                                fontWeight: 'bold',
                                marginTop: 0,
                                marginBottom: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              {DATA.clt}
                            </p>
                            <p
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              Clientes
                            </p>
                          </div>
                        </div>
                      </Box>
                      <Box
                        boxShadow={3}
                        style={{
                          borderRadius: 5,
                          width: '17%',
                          marginRight: 10,
                          backgroundColor: 'rgba(255, 102, 0, 1)',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              paddingRight: 10,
                            }}
                          >
                            <VisibilityIcon
                              style={{fontSize: 40, color: '#FFFFFF'}}
                            />
                          </div>
                          <div
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                paddingLeft: 10,
                                fontWeight: 'bold',
                                color: '#FFFFFF',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.vst}
                            </p>
                            <p
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              Visitas
                            </p>
                          </div>
                        </div>
                      </Box>
                      <Box
                        boxShadow={3}
                        style={{
                          borderRadius: 5,
                          width: '17%',
                          marginRight: 10,
                          backgroundColor: 'rgba(57, 180, 13, 1)',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              paddingRight: 10,
                            }}
                          >
                            <GroupAddIcon
                              style={{fontSize: 40, color: '#FFFFFF'}}
                            />
                          </div>
                          <div
                            style={{
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                paddingLeft: 10,
                                fontWeight: 'bold',
                                color: '#FFFFFF',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {DATA.newClt}
                            </p>
                            <p
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 0,
                                marginTop: 0,
                                color: '#FFFFFF',
                              }}
                            >
                              Clientes novos
                            </p>
                          </div>
                        </div>
                      </Box>
                    </div>
                    <div style={{marginTop: 20, marginLeft: 10}}>
                      <p1 style={{marginTop: 20, fontSize: 24}}>
                        Perfil dos pedidos
                      </p1>
                    </div>
                    <div>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          boxShadow={3}
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                            marginTop: 20,
                          }}
                        >
                          <Bar
                            height={90}
                            style={{
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                            data={data}
                            options={options}
                          />
                        </Box>
                      </div>
                    </div>
                    <div style={{marginTop: 20, marginLeft: 10}}>
                      <p1 style={{marginTop: 20, fontSize: 24}}>Retenção</p1>
                    </div>
                    <div style={{marginBottom: 40}}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                        }}
                      >
                        <Box
                          boxShadow={3}
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderRadius: 10,
                            marginTop: 20,
                          }}
                        >
                          <Doughnut
                            width={400}
                            height={90}
                            style={{
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                            data={dataCircle}
                            options={options}
                          />
                        </Box>
                        <div style={{marginTop: 20, marginLeft: 10}}>
                          <p1>
                            Retenção de clientes é o nome dado à capacidade de
                            manter clientes fiéis, que continuam fazendo
                            negócios com uma empresa, ao longo do tempo.
                          </p1>
                          <br />
                          <p1
                            style={{
                              fontWeight: 'bold',
                              fontSize: 20,
                              marginTop: 10,
                            }}
                          >
                            Seus resultados:
                          </p1>
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[0] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[0] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 10}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              1 vez
                            </p>
                          </div>
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[1] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[1] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 10}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              2 vezes
                            </p>
                          </div>
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[2] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[2] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 10}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              3 vezes
                            </p>
                          </div>
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[3] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[3] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 10}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              4 vezes
                            </p>
                          </div>
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[4] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[4] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 10}}>
                              De seus clientes compraram
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              5 vezes
                            </p>
                          </div>
                          <div style={{display: 'flex'}}>
                            {DATA.rtc[5] == 0 ? (
                              <p style={{fontWeight: 'bold'}}>0%</p>
                            ) : (
                              <p style={{fontWeight: 'bold'}}>
                                {((DATA.rtc[5] / DATA.trtc) * 100).toFixed(2)}%
                              </p>
                            )}
                            <p style={{marginLeft: 10}}>
                              De seus clientes compraram mais de
                            </p>
                            <p style={{marginLeft: 5, fontWeight: 'bold'}}>
                              6 vezes
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Clientes;
