/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box, Button, Divider} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useSelector} from 'react-redux';
import AlertSending from '../../components/AlertSeding';
import {realFormat} from '../../configuration/realFormat';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import {getAuth} from 'firebase/auth';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#d4d4d4',
    '&$checked': {
      color: '#52d869',
    },
    '&$checked + $track': {
      backgroundColor: '#52d869',
    },
  },
  checked: {},
  track: {},
})(Switch);

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Promocoes = ({
  handleToggleSidebar,
  handleNotiStack,
  handleBackDrop,
  updateStore,
}) => {
  const auth = getAuth();
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [bpromoEntrega, setbpromoentrega] = useState(false);
  const [bCupom5Store, setbCupom5Store] = useState(false);
  const [bCupom10Store, setbCupom10Store] = useState(false);
  const [bFirstCupom, setbFirstCupom] = useState(false);
  const [bFidelidadeCupom, setbFidelidadeCupom] = useState(false);
  const [bRetiradaCupom, setBRetiradaCupom] = useState(false);
  const [distMax, setDistMax] = useState(0);
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const iddoc = useSelector(state => state.setrestaurante.id);
  const location = useSelector(state => state.setrestaurante.location);
  const cbpromo = useSelector(state => state.setrestaurante.bpromoEntrega);
  const cat = useSelector(state => state.setrestaurante.cat);
  const aratdm = useSelector(state => state.setrestaurante.aratdm);
  const entregagratis = useSelector(
    state => state.setrestaurante.entregagratis,
  );
  const mxprmetrg = useSelector(state => state.setrestaurante.mxprmetrg);
  const [openFinish, setOpenFinish] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [value, setValue] = useState(0.0);
  function handlyvalue(value) {
    setValue(value);
  }
  const clat = useSelector(
    state => state.setrestaurante.location.coordinates[1],
  );
  const clng = useSelector(
    state => state.setrestaurante.location.coordinates[0],
  );

  const [DATA, setDATA] = useState({
    iicupons: [],
    ownerscupons: [],
  });

  const [default5store, setDefault5] = useState({
    cpnh: 'Cupons de R$ 5,00 do estabelecimento',
    descricao: 'Cupons de R$ 5,00 do Estabelecimento para seus clientes.',
    dsct: 5.0,
    local: '',
    aratdm: aratdm,
    usado: false,
    imgurl: '',
    sugestao: false,
    quantidade: 20,
    qtdi: 20,
    promocao: true,
    dscii: false,
    frm: ['pix', 'pago pelo app'],
    cat: [],
    estid: idstore,
    location: {},
    users: [],
    vip: false,
    viplist: [],
    type: 'store5',
    days: [],
    nfd: -1,
    finish: false,
    stores: [],
    validade: '',
    vlrmin: 18.0,
    npd: -1,
    bopen: false,
    ndays: 14,
    av: false,
    dflt: true,
  });

  const [default10store, setDefault10] = useState({
    cpnh: 'Cupons de R$ 10,00 do estabelecimento',
    descricao: 'Cupons de R$ 10,00 do Estabelecimento para seus clientes.',
    dsct: 10.0,
    local: '',
    aratdm: aratdm,
    usado: false,
    imgurl: '',
    sugestao: false,
    quantidade: 20,
    qtdi: 20,
    promocao: true,
    dscii: false,
    frm: ['pix', 'pago pelo app'],
    cat: [],
    estid: idstore,
    location: {},
    users: [],
    vip: false,
    viplist: [],
    type: 'store10',
    days: [],
    nfd: 5,
    finish: false,
    stores: [],
    validade: '',
    vlrmin: 25.0,
    npd: -1,
    bopen: false,
    ndays: 14,
    av: false,
    dflt: true,
  });

  const [defaultRetirada, setDefaultRetirada] = useState({
    cpnh: 'Cupons de R$ 6,00 para retirada.',
    descricao:
      'Cupons de R$ 6,00 do estabelecimento para seus clientes retirarem em sua loja.',
    dsct: 6.0,
    local: '',
    aratdm: aratdm,
    usado: false,
    imgurl: '',
    sugestao: false,
    quantidade: 20,
    qtdi: 20,
    promocao: true,
    dscii: false,
    frm: ['pix', 'pago pelo app'],
    cat: [],
    estid: '',
    location: {},
    users: [],
    vip: false,
    viplist: [],
    type: 'retirada',
    days: [],
    nfd: -1,
    finish: false,
    stores: [],
    validade: '',
    vlrmin: 20.0,
    npd: -1,
    bopen: false,
    ndays: 14,
    av: false,
    dflt: true,
  });

  const [defaultFirst, setDefaultFirst] = useState({
    cpnh: 'Cupons de R$ 8,00 da loja para o primeiro pedido.',
    descricao:
      'Cupons de R$ 8,00 da loja para o primeiro pedido de seus clientes.',
    dsct: 8.0,
    local: '',
    aratdm: aratdm,
    usado: false,
    imgurl: '',
    sugestao: false,
    quantidade: 20,
    qtdi: 20,
    promocao: true,
    dscii: false,
    frm: ['pix', 'pago pelo app'],
    cat: [],
    estid: idstore,
    location: {},
    users: [],
    vip: false,
    viplist: [],
    type: 'first',
    days: [],
    nfd: -1,
    finish: false,
    stores: [],
    validade: '',
    vlrmin: 15.0,
    npd: 0,
    bopen: false,
    ndays: 14,
    av: false,
    dflt: true,
  });

  const [defaultFidelidade, setDefaultFidelidade] = useState({
    cpnh: 'Cupons de R$ 15,00 da loja para clientes que fizerem 5 compras nos próximos 14 dias',
    descricao:
      'Cupons de R$ 15,00 para clientes que fizerem 5 pedidos no periodo da promoção.',
    dsct: 15.0,
    local: '',
    aratdm: aratdm,
    usado: false,
    imgurl: '',
    sugestao: false,
    quantidade: 20,
    qtdi: 20,
    promocao: true,
    dscii: false,
    frm: ['pix', 'pago pelo app'],
    cat: [],
    estid: idstore,
    location: {},
    users: [],
    vip: false,
    viplist: [],
    type: 'fidelidade',
    days: [],
    nfd: 5,
    finish: false,
    stores: [],
    validade: '',
    vlrmin: 20.0,
    npd: -1,
    bopen: false,
    ndays: 14,
    av: false,
    dflt: true,
  });

  function formarterdate(days) {
    var today = new Date();
    today.setDate(today.getDate() + days);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;

    return formattedToday;
  }

  function updateCupons() {
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/cupons/findstore?lat=' +
          location.coordinates[1] +
          '&lng=' +
          location.coordinates[0] +
          '&estid=' +
          idstore +
          '&cat=' +
          cat +
          '&type=' +
          '',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setDATA(data);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function onCollapse(item, open) {
    let newitem = {...item, bopen: open};
    if (item.type === 'store5') {
      setDefault5(newitem);
    } else if (item.type === 'store10') {
      setDefault10(newitem);
    } else if (item.type === 'retirada') {
      setDefaultRetirada(newitem);
    } else if (item.type === 'first') {
      setDefaultFirst(newitem);
    } else if (item.type === 'fidelidade') {
      setDefaultFidelidade(newitem);
    }
  }

  function postCupom(item) {
    setIsSending(true);
    let newitem = {...item};
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/cupons/insert' +
          '?startdate=' +
          formarterdate(0) +
          '&enddate=' +
          formarterdate(item.ndays) +
          '&ndays=' +
          item.ndays +
          '&lat=' +
          clat +
          '&lng=' +
          clng,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newitem),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setIsSending(false);
                  handleNotiStack('Cupom criado com sucesso', 'success');
                }, 1000);
                updateCupons();
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack('Não foi possível criar a campanha', 'error');
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack('Não foi possível criar a campanha', 'error');
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack('Não foi possível criar a campanha', 'error');
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack('Não foi possível criar a campanha', 'error');
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack('Não foi possível criar a campanha', 'error');
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível criar a campanha', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  useEffect(() => {
    updateCupons();
    setbpromoentrega(cbpromo);
    setValue(entregagratis);
    setDistMax(mxprmetrg);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    let cupomii = false;

    DATA.ownerscupons.map(item => {
      if (item.type === 'store5') {
        if (item.av && !item.finish && !item.usado) {
          cupomii = true;
        }
        setbCupom5Store(true);
        setDefault5(item);
      } else if (item.type === 'store10') {
        if (item.av && !item.finish && !item.usado) {
          cupomii = true;
        }
        setbCupom10Store(true);
        setDefault10(item);
      } else if (item.type === 'retirada') {
        if (item.av && !item.finish && !item.usado) {
          cupomii = true;
        }
        setBRetiradaCupom(true);
        setDefaultRetirada(item);
      } else if (item.type === 'first') {
        if (item.av && !item.finish && !item.usado) {
          cupomii = true;
        }
        setbFirstCupom(true);
        setDefaultFirst(item);
      } else if (item.type === 'fidelidade') {
        if (item.av && !item.finish && !item.usado) {
          cupomii = true;
        }
        setbFidelidadeCupom(true);
        setDefaultFidelidade(item);
      }
    });

    DATA.iicupons.map(item => {
      if (
        item.stores.includes(idstore) &&
        !item.finish &&
        item.quantidade > 0 &&
        !item.usado
      ) {
        cupomii = true;
      }
    });

    setTimeout(() => {
      if (DATA.iicupons.length > 0 || DATA.ownerscupons.length > 0) {
        putPromoAv(cupomii);
      }
    }, 1000);
  }, [DATA]);

  function putPromoAv(cupomii) {
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/store/update/cpmav/' +
          iddoc +
          '?toggle=' +
          cupomii,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                console.debug(data);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            throw new Error('503: Something went wrong on API server!');
          } else if (response.status === 404) {
            throw new Error('404: Something went wrong on API server!');
          } else if (response.status === 401) {
            throw new Error('401: Something went wrong on API server!');
          } else if (response.status === 400) {
            throw new Error('400: Something went wrong on API server!');
          } else if (response.status === 500) {
            throw new Error('500: Something went wrong on API server!');
          }
        })
        .catch(error => {
          console.error(error);
        });
    });
  }

  const handleIICupom = event => {
    setIsSending(true);
    let docid = event.target.id;
    let newstate = event.target.checked;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/cupons/stores/' +
          docid +
          '?estid=' +
          idstore +
          '&put=' +
          newstate,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                updateCupons();
                if (newstate) {
                  setTimeout(() => {
                    handleNotiStack('Campanha habilidatada', 'success');
                  }, 1000);
                } else {
                  setTimeout(() => {
                    handleNotiStack('Campanha desabilitada', 'warning');
                  }, 1000);
                }
                setTimeout(() => {
                  setIsSending(false);
                }, 1000);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  const handleItemChange = event => {
    setIsSending(true);
    let docid = event.target.id;
    let newstate = event.target.checked;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/cupons/setAv/' +
          docid +
          '?av=' +
          newstate,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                updateCupons();
                if (data.type === 'store5') {
                  setDefault5(data);
                } else if (data.type === 'store10') {
                  setDefault10(data);
                } else if (data.type === 'retirada') {
                  setDefaultRetirada(data);
                } else if (data.type === 'first') {
                  setDefaultFirst(data);
                } else if (data.type === 'fidelidade') {
                  setDefaultFidelidade(data);
                }
                setTimeout(() => {
                  setIsSending(false);
                  if (newstate) {
                    handleNotiStack(
                      'Campanha disponível para os usuários',
                      'success',
                    );
                  } else {
                    handleNotiStack('Campanha desabilitada', 'warning');
                  }
                }, 1000);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  function finishcupom(toggle) {
    setIsSending(true);
    setOpenFinish(false);
    let docid = currentItem._id;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/cupons/setFinish/' +
          docid +
          '?finish=' +
          toggle,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                if (data.type === 'store5') {
                  setDefault5(data);
                } else if (data.type === 'store10') {
                  setDefault10(data);
                } else if (data.type === 'retirada') {
                  setDefaultRetirada(data);
                } else if (data.type === 'first') {
                  setDefaultFirst(data);
                } else if (data.type === 'fidelidade') {
                  setDefaultFidelidade(data);
                }
                setTimeout(() => {
                  setIsSending(false);
                  handleNotiStack('Campanha finalizada com sucesso', 'success');
                }, 1000);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function openCupomFinish(item) {
    setCurrentItem(item);
    setOpenFinish(true);
  }

  const handleChange = event => {
    setbpromoentrega(event.target.checked);
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const [width, height] = useWindowSize();

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    setIsSending(true);
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/store/update/promoentrega/' +
          iddoc +
          '?vlrmin=' +
          value +
          '&distmax=' +
          distMax +
          '&toggle=' +
          bpromoEntrega,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                updateStore(data);
                if (bpromoEntrega) {
                  setTimeout(() => {
                    handleNotiStack(
                      'Campanha Entrega grátis habilidatada',
                      'success',
                    );
                  }, 1000);
                } else {
                  setTimeout(() => {
                    handleNotiStack(
                      'Campanha salva, mas desabilitada',
                      'warning',
                    );
                  }, 1000);
                }
                setTimeout(() => {
                  setIsSending(false);
                }, 1000);
              })
              .catch(error => {
                console.error(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  const CupomCard = () => {
    if (DATA.iicupons.length > 0) {
      let list = DATA.iicupons.map((item, index) => {
        return (
          <Box
            key={{index}}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              marginBottom: 20,
            }}
            boxShadow={3}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {isVerySmall ? (
                <div>
                  <div>
                    <FormControlLabel
                      style={{}}
                      label={
                        item.stores.includes(idstore) ? 'Ativado' : 'Desativado'
                      }
                      control={
                        <PurpleSwitch
                          checked={item.stores.includes(idstore)}
                          onChange={handleIICupom}
                          name="checked"
                          id={item._id}
                        />
                      }
                    />
                  </div>
                  <div>
                    <p style={{fontWeight: 'bold', marginBottom: 0}}>
                      {item.cpnh}
                    </p>
                    {item.dscii ? (
                      <p
                        style={{
                          marginTop: 0,
                          fontSize: 12,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupons pagos pela Irê it são repassados no momento da
                        fatura.{' '}
                      </p>
                    ) : (
                      <p
                        style={{
                          marginTop: 0,
                          fontSize: 12,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom oferecido pela loja. O valor é acrescentado na
                        fatura.
                      </p>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p>Valor do desconto: </p>
                      <p style={{fontWeight: 'bold'}}>
                        {realFormat(item.dsct)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 10, marginTop: 3}}>
                        Para compras acima de:
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 10,
                          marginTop: 3,
                        }}
                      >
                        {realFormat(item.vlrmin)}
                      </p>
                    </div>
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 10}}>Quantidade inicial:</p>
                      <p style={{fontWeight: 'bold', marginBottom: 10}}>
                        {item.qtdi}
                      </p>
                    </div>
                    {item.quantidade === 0 ? (
                      <p
                        style={{
                          width: 'fit-content',
                          padding: 5,
                          background: '#e4e4e4',
                          color: '#333333',
                          fontWeight: 'bold',
                          marginTop: 0,
                          fontSize: 18,
                          marginBottom: 3,
                        }}
                      >
                        Nenhum cupom disponível
                      </p>
                    ) : item.quantidade === 1 ? (
                      <p
                        style={{
                          width: 'fit-content',
                          padding: 5,
                          background: '#e8ffff',
                          color: '#06448e',
                          fontWeight: 'bold',
                          marginTop: 0,
                          fontSize: 18,
                          marginBottom: 3,
                        }}
                      >
                        {item.quantidade} Cupom disponível
                      </p>
                    ) : item.quantidade > 1 ? (
                      <p
                        style={{
                          width: 'fit-content',
                          padding: 5,
                          background: '#e8ffff',
                          color: '#06448e',
                          fontWeight: 'bold',
                          marginTop: 0,
                          fontSize: 18,
                          marginBottom: 3,
                        }}
                      >
                        {item.quantidade} Cupons disponíveis
                      </p>
                    ) : null}
                    <p
                      style={{marginTop: 0, fontStyle: 'italic', fontSize: 12}}
                    >
                      Cada usúario pode usar o cupom apenas 1 vez.
                    </p>
                    <Divider />
                    <p style={{marginBottom: 3}}>Formas de pagamento: </p>
                    {(Array.isArray(item.frm)
                      ? item.frm.map(item => [item])
                      : Object.entries(item.frm)
                    ).map(item => {
                      return (
                        <p
                          key={item[0]._id}
                          value={item[0]}
                          style={{
                            marginTop: 0,
                            marginBottom: 3,
                            fontSize: 14,
                            fontWeight: 'bold',
                            marginLeft: 10,
                          }}
                        >
                          - {item}
                        </p>
                      );
                    })}
                  </div>
                  <Divider style={{marginTop: 20}} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>Validade:</p>
                    <p style={{fontWeight: 'bold'}}>{item.edate}</p>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <p style={{fontWeight: 'bold', marginBottom: 0}}>
                        {item.cpnh}
                      </p>

                      {item.dscii ? (
                        <p
                          style={{
                            marginTop: 0,
                            fontSize: 12,
                            fontStyle: 'italic',
                            fontWeight: 'bold',
                          }}
                        >
                          Cupons pagos pela Irê it são repassados no momento da
                          fatura.{' '}
                        </p>
                      ) : (
                        <p
                          style={{
                            marginTop: 0,
                            fontSize: 12,
                            fontStyle: 'italic',
                            fontWeight: 'bold',
                          }}
                        >
                          Cupom oferecido pela loja. O valor é acrescentado na
                          fatura.
                        </p>
                      )}
                    </div>
                    <FormControlLabel
                      style={{}}
                      label={
                        item.stores.includes(idstore) ? 'Ativado' : 'Desativado'
                      }
                      control={
                        <PurpleSwitch
                          checked={item.stores.includes(idstore)}
                          onChange={handleIICupom}
                          name="checked"
                          id={item._id}
                        />
                      }
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p>Valor do desconto: </p>
                      <p style={{fontWeight: 'bold'}}>
                        {realFormat(item.dsct)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 10, marginTop: 3}}>
                        Para compras acima de:
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 10,
                          marginTop: 3,
                        }}
                      >
                        {realFormat(item.vlrmin)}
                      </p>
                    </div>
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 10}}>Quantidade inicial:</p>
                      <p style={{fontWeight: 'bold', marginBottom: 10}}>
                        {item.qtdi}
                      </p>
                    </div>
                    {item.quantidade === 0 ? (
                      <p
                        style={{
                          width: 'fit-content',
                          padding: 5,
                          background: '#e4e4e4',
                          color: '#333333',
                          fontWeight: 'bold',
                          marginTop: 0,
                          fontSize: 18,
                          marginBottom: 3,
                        }}
                      >
                        Nenhum cupom disponível
                      </p>
                    ) : item.quantidade === 1 ? (
                      <p
                        style={{
                          width: 'fit-content',
                          padding: 5,
                          background: '#e8ffff',
                          color: '#06448e',
                          fontWeight: 'bold',
                          marginTop: 0,
                          fontSize: 18,
                          marginBottom: 3,
                        }}
                      >
                        {item.quantidade} Cupom disponível
                      </p>
                    ) : item.quantidade > 1 ? (
                      <p
                        style={{
                          width: 'fit-content',
                          padding: 5,
                          background: '#e8ffff',
                          color: '#06448e',
                          fontWeight: 'bold',
                          marginTop: 0,
                          fontSize: 18,
                          marginBottom: 3,
                        }}
                      >
                        {item.quantidade} Cupons disponíveis
                      </p>
                    ) : null}
                    <p
                      style={{marginTop: 0, fontStyle: 'italic', fontSize: 12}}
                    >
                      Cada usúario pode usar o cupom apenas 1 vez.
                    </p>
                    <Divider />
                    <div style={{}}>
                      <p style={{marginBottom: 3}}>Formas de pagamento:</p>
                      <div style={{marginTop: 5, marginLeft: 10}}>
                        {(Array.isArray(item.frm)
                          ? item.frm.map(item => [item])
                          : Object.entries(item.frm)
                        ).map(item => {
                          return (
                            <p
                              key={item[0]._id}
                              value={item[0]}
                              style={{
                                marginTop: 3,
                                marginBottom: 3,
                                fontSize: 14,
                                fontWeight: 'bold',
                              }}
                            >
                              - {item}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                    <Divider style={{marginTop: 15}} />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p>Validade:</p>
                      <p style={{fontWeight: 'bold'}}>{item.edate}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>
        );
      });
      return list;
    } else {
      return (
        <Box
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
            marginBottom: 20,
          }}
          boxShadow={3}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                fontSize: 20,
                marginBottom: 10,
                marginTop: 10,
                color: '#999999',
              }}
            >
              Cupons Irê it
            </p>
            <InfoIcon style={{color: '#c4c4c4'}} />
            <p
              style={{
                color: '#c4c4c4',
                maxWidth: 200,
                textAlign: 'center',
              }}
            >
              Não há cupons pagos pela Irê it no momento.
            </p>
          </div>
        </Box>
      );
    }
  };

  const CupomOwnerCard = ({item}) => {
    return (
      <Box
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 5,
          marginBottom: 20,
        }}
        boxShadow={3}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {isVerySmall ? (
            <div>
              <div>
                <FormControlLabel
                  style={{}}
                  label={item.av ? 'Ativado' : 'Desativado'}
                  control={
                    <PurpleSwitch
                      checked={item.av}
                      id={item._id}
                      name="checked"
                      onChange={handleItemChange}
                    />
                  }
                />
              </div>
              <div>
                <p style={{fontWeight: 'bold', marginBottom: 0}}>{item.cpnh}</p>
                {item.dscii ? (
                  <p
                    style={{
                      marginTop: 0,
                      fontSize: 12,
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                  >
                    Cupons pagos pela Irê it são repassados no momento da
                    fatura.{' '}
                  </p>
                ) : (
                  <p
                    style={{
                      marginTop: 0,
                      fontSize: 12,
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                  >
                    Cupom oferecido pela loja. O valor é acrescentado na fatura.
                  </p>
                )}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Valor do desconto: </p>
                  <p style={{fontWeight: 'bold'}}>{realFormat(item.dsct)}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{marginBottom: 10, marginTop: 3}}>
                    Para compras acima de:
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 10,
                      marginTop: 3,
                    }}
                  >
                    {realFormat(item.vlrmin)}
                  </p>
                </div>
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{marginBottom: 10}}>Quantidade inicial:</p>
                  <p style={{fontWeight: 'bold', marginBottom: 10}}>
                    {item.qtdi}
                  </p>
                </div>
                {item.quantidade === 0 ? (
                  <p
                    style={{
                      width: 'fit-content',
                      padding: 5,
                      background: '#e4e4e4',
                      color: '#333333',
                      fontWeight: 'bold',
                      marginTop: 0,
                      fontSize: 18,
                      marginBottom: 3,
                    }}
                  >
                    Nenhum cupom disponível
                  </p>
                ) : item.quantidade === 1 ? (
                  <p
                    style={{
                      width: 'fit-content',
                      padding: 5,
                      background: '#e8ffff',
                      color: '#06448e',
                      fontWeight: 'bold',
                      marginTop: 0,
                      fontSize: 18,
                      marginBottom: 3,
                    }}
                  >
                    {item.quantidade} Cupom disponível
                  </p>
                ) : item.quantidade > 1 ? (
                  <p
                    style={{
                      width: 'fit-content',
                      padding: 5,
                      background: '#e8ffff',
                      color: '#06448e',
                      fontWeight: 'bold',
                      marginTop: 0,
                      fontSize: 18,
                      marginBottom: 3,
                    }}
                  >
                    {item.quantidade} Cupons disponíveis
                  </p>
                ) : null}
                <p style={{marginTop: 0, fontStyle: 'italic', fontSize: 12}}>
                  Cada usúario pode usar o cupom apenas 1 vez.
                </p>
                <Divider />
                <p style={{marginBottom: 3}}>Formas de pagamento: </p>
                {(Array.isArray(item.frm)
                  ? item.frm.map(item => [item])
                  : Object.entries(item.frm)
                ).map(item => {
                  return (
                    <p
                      key={item[0]._id}
                      value={item[0]}
                      style={{
                        marginTop: 0,
                        marginBottom: 3,
                        fontSize: 14,
                        fontWeight: 'bold',
                        marginLeft: 10,
                      }}
                    >
                      - {item}
                    </p>
                  );
                })}
              </div>
              <Divider style={{marginTop: 20}} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p>Validade:</p>
                <p style={{fontWeight: 'bold'}}>{item.edate}</p>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <p style={{fontWeight: 'bold', marginBottom: 0}}>
                    {item.cpnh}
                  </p>

                  {item.dscii ? (
                    <p
                      style={{
                        marginTop: 0,
                        fontSize: 12,
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                      }}
                    >
                      Cupons pagos pela Irê it são repassados no momento da
                      fatura.{' '}
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 0,
                        fontSize: 12,
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                      }}
                    >
                      Cupom oferecido pela loja. O valor é acrescentado na
                      fatura.
                    </p>
                  )}
                </div>
                <FormControlLabel
                  style={{}}
                  label={item.av ? 'Ativado' : 'Desativado'}
                  control={
                    <PurpleSwitch
                      checked={item.av}
                      id={item._id}
                      name="checked"
                      onChange={handleItemChange}
                    />
                  }
                />
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Valor do desconto: </p>
                  <p style={{fontWeight: 'bold'}}>{realFormat(item.dsct)}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{marginBottom: 10, marginTop: 3}}>
                    Para compras acima de:
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 10,
                      marginTop: 3,
                    }}
                  >
                    {realFormat(item.vlrmin)}
                  </p>
                </div>
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{marginBottom: 10}}>Quantidade inicial:</p>
                  <p style={{fontWeight: 'bold', marginBottom: 10}}>
                    {item.qtdi}
                  </p>
                </div>
                {item.quantidade === 0 ? (
                  <p
                    style={{
                      width: 'fit-content',
                      padding: 5,
                      background: '#e4e4e4',
                      color: '#333333',
                      fontWeight: 'bold',
                      marginTop: 0,
                      fontSize: 18,
                      marginBottom: 3,
                    }}
                  >
                    Nenhum cupom disponível
                  </p>
                ) : item.quantidade === 1 ? (
                  <p
                    style={{
                      width: 'fit-content',
                      padding: 5,
                      background: '#e8ffff',
                      color: '#06448e',
                      fontWeight: 'bold',
                      marginTop: 0,
                      fontSize: 18,
                      marginBottom: 3,
                    }}
                  >
                    {item.quantidade} Cupom disponível
                  </p>
                ) : item.quantidade > 1 ? (
                  <p
                    style={{
                      width: 'fit-content',
                      padding: 5,
                      background: '#e8ffff',
                      color: '#06448e',
                      fontWeight: 'bold',
                      marginTop: 0,
                      fontSize: 18,
                      marginBottom: 3,
                    }}
                  >
                    {item.quantidade} Cupons disponíveis
                  </p>
                ) : null}
                <p style={{marginTop: 0, fontStyle: 'italic', fontSize: 12}}>
                  Cada usúario pode usar o cupom apenas 1 vez.
                </p>
                <Divider />
                <div style={{}}>
                  <p style={{marginBottom: 3}}>Formas de pagamento:</p>
                  <div style={{marginTop: 5, marginLeft: 10}}>
                    {(Array.isArray(item.frm)
                      ? item.frm.map(item => [item])
                      : Object.entries(item.frm)
                    ).map(item => {
                      return (
                        <p
                          key={item[0]._id}
                          value={item[0]}
                          style={{
                            marginTop: 3,
                            marginBottom: 3,
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          - {item}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <Divider style={{marginTop: 15}} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p>Validade:</p>
                  <p style={{fontWeight: 'bold'}}>{item.edate}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Box>
    );
  };

  const CardtoCreate = ({item}) => {
    return (
      <Box
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 5,
          marginBottom: 20,
        }}
        boxShadow={3}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {isVerySmall ? (
            <div>
              <div>
                {!item.dflt ? (
                  <div>
                    <FormControlLabel
                      style={{}}
                      label={item.av ? 'Ativado' : 'Desativado'}
                      control={
                        <PurpleSwitch
                          checked={item.av}
                          id={item._id}
                          onChange={handleItemChange}
                          name="checked"
                        />
                      }
                    />
                  </div>
                ) : null}
                <p style={{fontWeight: 'bold', marginBottom: 0}}>{item.cpnh}</p>
                <p
                  style={{
                    marginTop: 0,
                    fontSize: 12,
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                  }}
                >
                  Cupom oferecido pela loja. O valor é acrescentado na fatura
                  atual.
                </p>

                <Collapse in={item.bopen} unmountOnExit>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>Valor do desconto: </p>
                    <p style={{fontWeight: 'bold'}}>{realFormat(item.dsct)}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>Valor total da campanha: </p>
                    <p style={{fontWeight: 'bold'}}>
                      {realFormat(item.dsct * item.qtdi)}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p style={{marginBottom: 10, marginTop: 3}}>
                      Para compras acima de:
                    </p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 10,
                        marginTop: 3,
                      }}
                    >
                      {realFormat(item.vlrmin)}
                    </p>
                  </div>
                  {item.type === 'fidelidade' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom disponível para os usúarios que fizeram compras
                        nos últimos 20 dias da data do ínicio da promoção.
                      </p>
                    </div>
                  ) : item.type === 'retirada' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom disponível para pedidos com retirada na loja. Use
                        caso queira que as pessoas conheçam seu estabelecimento.
                      </p>
                    </div>
                  ) : item.type === 'first' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom disponível para clientes que irão fazer o primeiro
                        pedido em sua loja.
                      </p>
                    </div>
                  ) : item.type === 'store10' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom de R$ 10,00 para usúarios manterem as compras em
                        seu estabelecimento
                      </p>
                    </div>
                  ) : item.type === 'store5' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom de R$ 5,00 para usúarios manterem as compras em
                        seu estabelecimento
                      </p>
                    </div>
                  ) : null}
                  <Divider />
                  {item.finish ? (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{marginBottom: 10}}>Quantidade:</p>
                        <p style={{fontWeight: 'bold', marginBottom: 10}}>
                          {item.qtdi}
                        </p>
                      </div>
                      <p
                        style={{
                          marginTop: 0,
                          fontStyle: 'italic',
                          fontSize: 12,
                        }}
                      >
                        Cada usúario pode usar o cupom apenas 1 vez.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{marginBottom: 10}}>Quantidade inicial:</p>
                        <p style={{fontWeight: 'bold', marginBottom: 10}}>
                          {item.qtdi}
                        </p>
                      </div>
                      {item.quantidade === 0 ? (
                        <p
                          style={{
                            width: 'fit-content',
                            padding: 5,
                            background: '#e4e4e4',
                            color: '#333333',
                            fontWeight: 'bold',
                            marginTop: 0,
                            fontSize: 18,
                            marginBottom: 3,
                          }}
                        >
                          Nenhum cupom disponível
                        </p>
                      ) : item.quantidade === 1 ? (
                        <p
                          style={{
                            width: 'fit-content',
                            padding: 5,
                            background: '#e8ffff',
                            color: '#06448e',
                            fontWeight: 'bold',
                            marginTop: 0,
                            fontSize: 18,
                            marginBottom: 3,
                          }}
                        >
                          {item.quantidade} Cupom disponível
                        </p>
                      ) : item.quantidade > 1 ? (
                        <p
                          style={{
                            width: 'fit-content',
                            padding: 5,
                            background: '#e8ffff',
                            color: '#06448e',
                            fontWeight: 'bold',
                            marginTop: 0,
                            fontSize: 18,
                            marginBottom: 3,
                          }}
                        >
                          {item.quantidade} Cupons disponíveis
                        </p>
                      ) : null}
                    </div>
                  )}

                  <Divider />
                  <p style={{marginBottom: 3}}>Formas de pagamento: </p>
                  {(Array.isArray(item.frm)
                    ? item.frm.map(item => [item])
                    : Object.entries(item.frm)
                  ).map(item => {
                    return (
                      <p
                        key={item[0]._id}
                        value={item[0]}
                        style={{
                          marginTop: 0,
                          marginBottom: 3,
                          fontSize: 14,
                          fontWeight: 'bold',
                          marginLeft: 10,
                        }}
                      >
                        - {item}
                      </p>
                    );
                  })}

                  <Divider style={{marginTop: 20}} />
                  <div>
                    <p style={{marginBottom: 3}}>Periodo:</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 3}}>Início da campanha:</p>
                      <p style={{marginBottom: 3, fontWeight: 'bold'}}>
                        {formarterdate(0)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 3, marginTop: 0}}>
                        Final da campanha:
                      </p>
                      <p
                        style={{
                          marginBottom: 3,
                          fontWeight: 'bold',
                          marginTop: 0,
                        }}
                      >
                        {formarterdate(item.ndays)}
                      </p>
                    </div>
                  </div>
                </Collapse>
              </div>

              <Divider style={{marginTop: 20}} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {item.bopen ? (
                  <Button
                    onClick={() => onCollapse(item, false)}
                    style={{marginTop: 20, marginBottom: 20}}
                    variant="outlined"
                    size={'medium'}
                    color="primary"
                  >
                    Ocultar detalhes
                  </Button>
                ) : (
                  <Button
                    onClick={() => onCollapse(item, true)}
                    style={{marginTop: 20, marginBottom: 20}}
                    variant="outlined"
                    size={'medium'}
                    color="primary"
                  >
                    Mostrar detalhes
                  </Button>
                )}
                {!item.bopen ? null : item.usado ? (
                  <Button
                    onClick={() => openCupomFinish(item)}
                    style={{marginLeft: 10, marginTop: 20, marginBottom: 20}}
                    variant="contained"
                    size={'medium'}
                    color="secondary"
                  >
                    Finalizar campanha
                  </Button>
                ) : (
                  <Button
                    onClick={() => postCupom(item)}
                    style={{marginLeft: 10, marginTop: 20, marginBottom: 20}}
                    variant="contained"
                    size={'medium'}
                    color="primary"
                  >
                    Criar campanha
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <p style={{fontWeight: 'bold', marginBottom: 0}}>
                    {item.cpnh}
                  </p>

                  {!item.dscii ? (
                    <p
                      style={{
                        marginTop: 0,
                        fontSize: 12,
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                      }}
                    >
                      Cupom oferecido pela loja. O valor é acrescentado na
                      fatura.
                    </p>
                  ) : null}
                </div>
                {!item.dflt ? (
                  <div>
                    <FormControlLabel
                      style={{}}
                      label={item.av ? 'Ativado' : 'Desativado'}
                      control={
                        <PurpleSwitch
                          checked={item.av}
                          id={item._id}
                          onChange={handleItemChange}
                          name="checked"
                        />
                      }
                    />
                  </div>
                ) : null}
              </div>
              <Collapse in={item.bopen} unmountOnExit>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>Valor do desconto: </p>
                    <p style={{fontWeight: 'bold'}}>{realFormat(item.dsct)}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>Valor total da campanha: </p>
                    <p style={{fontWeight: 'bold'}}>
                      {realFormat(item.dsct * item.qtdi)}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p style={{marginBottom: 10, marginTop: 3}}>
                      Para compras acima de:
                    </p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 10,
                        marginTop: 3,
                      }}
                    >
                      {realFormat(item.vlrmin)}
                    </p>
                  </div>
                  {item.type === 'fidelidade' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom disponível para os usúarios que fizeram compras
                        nos últimos 20 dias da data do ínicio da promoção.
                      </p>
                    </div>
                  ) : item.type === 'retirada' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom disponível para pedidos com retirada na loja. Use
                        caso queira que as pessoas conheçam seu estabelecimento.
                      </p>
                    </div>
                  ) : item.type === 'first' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom disponível para clientes que irão fazer o primeiro
                        pedido em sua loja.
                      </p>
                    </div>
                  ) : item.type === 'store10' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom de R$ 10,00 para usúarios manterem as compras em
                        seu estabelecimento
                      </p>
                    </div>
                  ) : item.type === 'store5' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          marginBottom: 10,
                          marginTop: 3,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                        }}
                      >
                        Cupom de R$ 5,00 para usúarios manterem as compras em
                        seu estabelecimento
                      </p>
                    </div>
                  ) : null}
                  <Divider />
                  {item.finish ? (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{marginBottom: 10}}>Quantidade:</p>
                        <p style={{fontWeight: 'bold', marginBottom: 10}}>
                          {item.qtdi}
                        </p>
                      </div>
                      <p
                        style={{
                          marginTop: 0,
                          fontStyle: 'italic',
                          fontSize: 12,
                        }}
                      >
                        Cada usúario pode usar o cupom apenas 1 vez.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{marginBottom: 10}}>Quantidade inicial:</p>
                        <p style={{fontWeight: 'bold', marginBottom: 10}}>
                          {item.qtdi}
                        </p>
                      </div>
                      {item.quantidade === 0 ? (
                        <p
                          style={{
                            width: 'fit-content',
                            padding: 5,
                            background: '#e4e4e4',
                            color: '#333333',
                            fontWeight: 'bold',
                            marginTop: 0,
                            fontSize: 18,
                            marginBottom: 3,
                          }}
                        >
                          Nenhum cupom disponível
                        </p>
                      ) : item.quantidade === 1 ? (
                        <p
                          style={{
                            width: 'fit-content',
                            padding: 5,
                            background: '#e8ffff',
                            color: '#06448e',
                            fontWeight: 'bold',
                            marginTop: 0,
                            fontSize: 18,
                            marginBottom: 3,
                          }}
                        >
                          {item.quantidade} Cupom disponível
                        </p>
                      ) : item.quantidade > 1 ? (
                        <p
                          style={{
                            width: 'fit-content',
                            padding: 5,
                            background: '#e8ffff',
                            color: '#06448e',
                            fontWeight: 'bold',
                            marginTop: 0,
                            fontSize: 18,
                            marginBottom: 3,
                          }}
                        >
                          {item.quantidade} Cupons disponíveis
                        </p>
                      ) : null}
                      <p
                        style={{
                          marginTop: 0,
                          fontStyle: 'italic',
                          fontSize: 12,
                        }}
                      >
                        Cada usúario pode usar o cupom apenas 1 vez.
                      </p>
                    </div>
                  )}

                  <Divider />
                  <div style={{}}>
                    <p style={{marginBottom: 3}}>Formas de pagamento:</p>
                    <div style={{marginTop: 5, marginLeft: 10}}>
                      {(Array.isArray(item.frm)
                        ? item.frm.map(item => [item])
                        : Object.entries(item.frm)
                      ).map(item => {
                        return (
                          <p
                            key={item[0]._id}
                            value={item[0]}
                            style={{
                              marginTop: 3,
                              marginBottom: 3,
                              fontSize: 14,
                              fontWeight: 'bold',
                            }}
                          >
                            - {item}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <Divider style={{marginTop: 15}} />
                  <div>
                    <p style={{marginBottom: 3}}>Periodo:</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 3}}>Início da campanha:</p>
                      <p style={{marginBottom: 3, fontWeight: 'bold'}}>
                        {formarterdate(0)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p style={{marginBottom: 3, marginTop: 0}}>
                        Final da campanha:
                      </p>
                      <p
                        style={{
                          marginBottom: 3,
                          fontWeight: 'bold',
                          marginTop: 0,
                        }}
                      >
                        {formarterdate(item.ndays)}
                      </p>
                    </div>
                  </div>
                </div>
              </Collapse>

              <Divider style={{marginTop: 15}} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {item.bopen ? (
                  <Button
                    onClick={() => onCollapse(item, false)}
                    style={{marginLeft: 10, marginTop: 20, marginBottom: 20}}
                    variant="outlined"
                    size={'medium'}
                    color="primary"
                  >
                    Ocultar campanha
                  </Button>
                ) : (
                  <Button
                    onClick={() => onCollapse(item, true)}
                    style={{marginLeft: 10, marginTop: 20, marginBottom: 20}}
                    variant="outlined"
                    size={'medium'}
                    color="primary"
                  >
                    Detalhes da campanha
                  </Button>
                )}
                {!item.bopen ? null : item.usado ? (
                  <Button
                    onClick={() => openCupomFinish(item)}
                    style={{marginLeft: 10, marginTop: 20, marginBottom: 20}}
                    variant="contained"
                    size={'medium'}
                    color="secondary"
                  >
                    Finalizar campanha
                  </Button>
                ) : (
                  <Button
                    onClick={() => postCupom(item)}
                    style={{marginLeft: 10, marginTop: 20, marginBottom: 20}}
                    variant="contained"
                    size={'medium'}
                    color="primary"
                  >
                    Criar campanha
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Box>
    );
  };

  const CardColdDown = ({item}) => {
    return (
      <Box
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 5,
          marginBottom: 20,
        }}
        boxShadow={3}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {isVerySmall ? (
            <div>
              <div>
                {!item.finish ? (
                  <div
                    style={{
                      backgroundColor: '#e8ffff',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <RadioButtonCheckedIcon
                      style={{color: '#06448E', fontSize: 14, marginRight: 4}}
                    />
                    <p>Esperando encerramento</p>
                  </div>
                ) : null}
                <p style={{fontWeight: 'bold', marginBottom: 0}}>{item.cpnh}</p>
                <p
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                    fontStyle: 'italic',
                  }}
                >
                  Campanha esperando encerramento. Isso significa que é
                  necessário esperar 14 dias após o ínicio da última campanha,
                  até abrir novamente a mesma. Isso é feito para que todas as
                  lojas possam oferecer cupons de descontos de forma saudável e
                  competitiva.
                </p>
                <Divider />
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{marginBottom: 3}}>
                    Data para iniciar uma nova campanha:
                  </p>
                  <p style={{marginBottom: 3, fontWeight: 'bold'}}>
                    {item.edate}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <p style={{fontWeight: 'bold', marginBottom: 0}}>
                    {item.cpnh}
                  </p>

                  <p
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontStyle: 'italic',
                      width: '50%',
                    }}
                  >
                    Campanha esperando encerramento. Isso significa que é
                    necessário esperar 14 dias após o ínicio da última campanha,
                    até abrir novamente a mesma. Isso é feito para que todas as
                    lojas possam oferecer cupons de descontos de forma saudável
                    e competitiva.
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: '#d8ffff',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <RadioButtonCheckedIcon
                    style={{color: '#06448e', fontSize: 14, marginRight: 4}}
                  />
                  <p style={{textAlign: 'center'}}>Esperando encerramento</p>
                </div>
              </div>
              <div>
                <Divider style={{marginTop: 15}} />
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p style={{marginBottom: 3}}>
                      Data para iniciar uma nova campanha:
                    </p>
                    <p style={{marginBottom: 3, fontWeight: 'bold'}}>
                      {item.edate}
                    </p>
                  </div>
                </div>
                <Divider style={{marginTop: 15}} />
              </div>
            </div>
          )}
        </div>
      </Box>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          {isVerySmall ? (
            <div style={{marginLeft: 10, marginRight: 10, marginBottom: 40}}>
              <h1 style={{marginBottom: 5, color: '#393939'}}>Promoções </h1>
              <p style={{fontWeight: 'bold'}}>Entrega grátis a partir de:</p>
              <Box
                style={{paddingLeft: 10, paddingRight: 10, borderRadius: 5}}
                boxShadow={3}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <FormControlLabel
                      style={{}}
                      label={bpromoEntrega ? 'Ativado' : 'Desativado'}
                      control={
                        <PurpleSwitch
                          checked={bpromoEntrega}
                          onChange={handleChange}
                          name="checked"
                        />
                      }
                    />
                  </div>
                  <div>
                    <p>Frete grátis á partir de:</p>
                    <CurrencyTextField
                      style={{
                        marginRight: 10,
                        width: isVerySmall ? '90%' : '100%',
                        marginTop: 0,
                        marginLeft: 0,
                        marginBottom: 10,
                      }}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          padding: 6,
                          color: '#393939',
                          paddingLeft: 0,
                        },
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {fontSize: 14, marginTop: 0, color: '#797979'},
                      }}
                      label="Valor mínimo da compra"
                      variant="outlined"
                      value={value}
                      currencySymbol="R$"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      onChange={(event, value) => handlyvalue(value)}
                    />
                    <TextField
                      type={'number'}
                      style={{
                        marginRight: 10,
                        width: isVerySmall ? '90%' : '70%',
                        marginTop: 10,
                        marginLeft: 0,
                        marginBottom: 10,
                      }}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          padding: 6,
                          color: '#393939',
                          paddingLeft: 10,
                        },
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {fontSize: 14, marginTop: 0, color: '#797979'},
                      }}
                      id="distMax"
                      label="Distância máxima? (Km)"
                      defaultValue={distMax}
                      value={distMax}
                      variant="outlined"
                      onChange={event => {
                        setDistMax(event.target.value);
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        onClick={() => onSave()}
                        style={{marginTop: 10, marginBottom: 10}}
                        variant="contained"
                        size={'medium'}
                        color="primary"
                      >
                        Salvar
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
              <Divider style={{marginTop: 20}} />
              <div>
                <h1 style={{marginBottom: 5, color: '#393939'}}>Cupons </h1>
                <p style={{fontWeight: 'bold'}}>Pagos pela Irê it:</p>
                <CupomCard />
                <p style={{fontWeight: 'bold'}}>Pagos pela loja:</p>
                <div style={{marginBottom: 80}}>
                  {bCupom5Store && default5store.finish ? (
                    <CardColdDown item={default5store} />
                  ) : bCupom5Store && !default5store.usado ? (
                    <CupomOwnerCard item={default5store} />
                  ) : (
                    <CardtoCreate item={default5store} />
                  )}
                  {bCupom10Store && default10store.finish ? (
                    <CardColdDown item={default10store} />
                  ) : bCupom10Store && !default10store.usado ? (
                    <CupomOwnerCard item={default10store} />
                  ) : (
                    <CardtoCreate item={default10store} />
                  )}
                  {bRetiradaCupom && defaultRetirada.finish ? (
                    <CardColdDown item={defaultRetirada} />
                  ) : bRetiradaCupom && !defaultRetirada.usado ? (
                    <CupomOwnerCard item={defaultRetirada} />
                  ) : (
                    <CardtoCreate item={defaultRetirada} />
                  )}
                  {bFirstCupom && defaultFirst.finish ? (
                    <CardColdDown item={defaultFirst} />
                  ) : bFirstCupom && !defaultFirst.usado ? (
                    <CupomOwnerCard item={defaultFirst} />
                  ) : (
                    <CardtoCreate item={defaultFirst} />
                  )}
                  {bFidelidadeCupom && defaultFidelidade.finish ? (
                    <CardColdDown item={defaultFirst} />
                  ) : bFidelidadeCupom && !defaultFidelidade.usado ? (
                    <CupomOwnerCard item={defaultFidelidade} />
                  ) : (
                    <CardtoCreate item={defaultFidelidade} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{marginLeft: 10, marginRight: 10}}>
              <h1 style={{marginBottom: 5, color: '#393939'}}>Promoções </h1>
              <p style={{fontWeight: 'bold'}}>Entrega grátis a partir de:</p>
              <Box
                style={{paddingLeft: 10, paddingRight: 10, borderRadius: 5}}
                boxShadow={3}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <p>Frete grátis á partir de:</p>
                    <CurrencyTextField
                      style={{
                        marginRight: 10,
                        width: isVerySmall ? '90%' : '100%',
                        marginTop: 0,
                        marginLeft: 0,
                        marginBottom: 10,
                      }}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          padding: 6,
                          color: '#393939',
                          paddingLeft: 0,
                        },
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {fontSize: 14, marginTop: 0, color: '#797979'},
                      }}
                      label="Valor mínimo da compra"
                      variant="outlined"
                      value={value}
                      currencySymbol="R$"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      onChange={(event, value) => handlyvalue(value)}
                    />
                    <TextField
                      type={'number'}
                      style={{
                        marginRight: 10,
                        width: isVerySmall ? '90%' : '70%',
                        marginTop: 0,
                        marginLeft: 0,
                        marginBottom: 10,
                      }}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          padding: 6,
                          color: '#393939',
                          paddingLeft: 10,
                        },
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {fontSize: 14, marginTop: 0, color: '#797979'},
                      }}
                      id="distMax"
                      label="Para entregas até quantos Km de distancia?"
                      defaultValue={distMax}
                      value={distMax}
                      variant="outlined"
                      onChange={event => {
                        setDistMax(event.target.value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    <FormControlLabel
                      style={{}}
                      label={bpromoEntrega ? 'Ativado' : 'Desativado'}
                      control={
                        <PurpleSwitch
                          checked={bpromoEntrega}
                          onChange={handleChange}
                          name="checked"
                        />
                      }
                    />
                    <Button
                      onClick={() => onSave()}
                      style={{marginLeft: 10, marginTop: 20, width: 150}}
                      variant="contained"
                      size={'medium'}
                      color="primary"
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </Box>
              <Divider style={{marginTop: 20}} />
              <div>
                <h1 style={{marginBottom: 5, color: '#393939'}}>Cupons </h1>
                <p style={{fontWeight: 'bold'}}>Pagos pela Irê it:</p>
                <CupomCard />
                <p style={{fontWeight: 'bold'}}>Pagos pela loja:</p>
                <div style={{marginBottom: 80}}>
                  {bCupom5Store && default5store.finish ? (
                    <CardColdDown item={default5store} />
                  ) : bCupom5Store && !default5store.usado ? (
                    <CupomOwnerCard item={default5store} />
                  ) : (
                    <CardtoCreate item={default5store} />
                  )}
                  {bCupom10Store && default10store.finish ? (
                    <CardColdDown item={default10store} />
                  ) : bCupom10Store && !default10store.usado ? (
                    <CupomOwnerCard item={default10store} />
                  ) : (
                    <CardtoCreate item={default10store} />
                  )}
                  {bRetiradaCupom && defaultRetirada.finish ? (
                    <CardColdDown item={defaultRetirada} />
                  ) : bRetiradaCupom && !defaultRetirada.usado ? (
                    <CupomOwnerCard item={defaultRetirada} />
                  ) : (
                    <CardtoCreate item={defaultRetirada} />
                  )}
                  {bFirstCupom && defaultFirst.finish ? (
                    <CardColdDown item={defaultFirst} />
                  ) : bFirstCupom && !defaultFirst.usado ? (
                    <CupomOwnerCard item={defaultFirst} />
                  ) : (
                    <CardtoCreate item={defaultFirst} />
                  )}
                  {bFidelidadeCupom && defaultFidelidade.finish ? (
                    <CardColdDown item={defaultFirst} />
                  ) : bFidelidadeCupom && !defaultFidelidade.usado ? (
                    <CupomOwnerCard item={defaultFidelidade} />
                  ) : (
                    <CardtoCreate item={defaultFidelidade} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
      <Dialog
        open={openFinish}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Finalizar campanha?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{fontSize: 12}}
            id="alert-dialog-description"
          >
            Após finalizar a campanha. Você poderá recriar ela após 14 dias do
            ínicio da ultima.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFinish(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => finishcupom(true)} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Promocoes;
